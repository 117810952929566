import React from 'react';
import styled from '@emotion/styled';

const SVG = styled.svg`
    path {
        fill: ${props => props.theme.primaryDark};
    }
    rect {
        fill: ${props => props.theme.accent};
    }
`;

export default props => (
    <SVG viewBox="0 0 159.25 88.78" width={props.width || 76} id="tbr-logo">
        <path d="M34,50.4l.44,9.3H33.3a11.16,11.16,0,0,0-.88-3.51A5.85,5.85,0,0,0,30,53.69a8.43,8.43,0,0,0-4-.8H20.41V83.2c0,2.44.27,4,.8,4.56A4.39,4.39,0,0,0,24.64,89H26v1.09H9.13V89h1.41a4.05,4.05,0,0,0,3.59-1.52c.43-.62.64-2,.64-4.27V52.89H10a13.19,13.19,0,0,0-4,.41,5.4,5.4,0,0,0-2.61,2.13A8.53,8.53,0,0,0,2.12,59.7H1l.47-9.3Z" transform="translate(-1 -1.3)"/>
        <path d="M64.9,69.83a12.74,12.74,0,0,1,6.2,2.81A8.7,8.7,0,0,1,74,79.22a9.79,9.79,0,0,1-1.88,5.66,10.73,10.73,0,0,1-5.16,4q-3.27,1.24-10,1.25H38.12V89h1.5a4,4,0,0,0,3.58-1.58c.45-.68.67-2.13.67-4.36V57.42c0-2.46-.28-4-.85-4.65a4.31,4.31,0,0,0-3.4-1.29h-1.5V50.4H55.33a34,34,0,0,1,7.72.7,12.44,12.44,0,0,1,6.73,3.73A9.18,9.18,0,0,1,72.1,61a8.52,8.52,0,0,1-1.82,5.34A10.72,10.72,0,0,1,64.9,69.83ZM49.51,68.25a22.19,22.19,0,0,0,2.48.3c.93.07,2,.11,3.07.11a17.34,17.34,0,0,0,6.45-.92,6.9,6.9,0,0,0,3.3-2.83A7.84,7.84,0,0,0,66,60.76a7.58,7.58,0,0,0-2.85-5.94q-2.85-2.46-8.31-2.46a20,20,0,0,0-5.29.64Zm0,19a30.13,30.13,0,0,0,6.73.79q5.31,0,8.1-2.39a7.45,7.45,0,0,0,2.79-5.89,8.63,8.63,0,0,0-1.26-4.45,8.4,8.4,0,0,0-4.11-3.36,17.81,17.81,0,0,0-7-1.23c-1.21,0-2.25,0-3.11,0a15.67,15.67,0,0,0-2.09.21Z" transform="translate(-1 -1.3)"/>
        <path d="M114.75,90.08H104.12L90.64,71.52c-1,0-1.81.06-2.44.06h-.82l-.91,0V83.05c0,2.5.27,4,.82,4.66A4.23,4.23,0,0,0,90.64,89H92.2v1.09H75.13V89h1.5a4,4,0,0,0,3.61-1.64c.42-.6.62-2,.62-4.3V57.42c0-2.5-.27-4.05-.82-4.65a4.32,4.32,0,0,0-3.41-1.29h-1.5V50.4H89.64a34.89,34.89,0,0,1,9.36.92,10.73,10.73,0,0,1,5.1,3.39,8.83,8.83,0,0,1,2.1,5.9A9.21,9.21,0,0,1,103.81,67q-2.4,2.69-7.41,3.8l8.22,11.39a20.27,20.27,0,0,0,4.84,5.2A12.19,12.19,0,0,0,114.75,89ZM86.47,69.68l1,0h.67c3.8,0,6.67-.82,8.59-2.46A7.85,7.85,0,0,0,99.6,61a8.14,8.14,0,0,0-2.34-6,8.37,8.37,0,0,0-6.18-2.33,26.11,26.11,0,0,0-4.61.56Z" transform="translate(-1 -1.3)"/>
        <rect x="121.66" width="2.4" height="65.54"/>
        <rect x="118.06" y="14.51" width="2.4" height="19.56" transform="translate(-1.05 -1.05) rotate(-0.12)"/>
        <rect x="126.71" y="34.03" width="2.4" height="19.56" transform="translate(-1.09 -1.04) rotate(-0.12)"/>
        <rect x="113.41" y="22.53" width="2.4" height="11.49" transform="translate(-1.1 -0.9) rotate(-0.2)"/>
        <rect x="131.31" y="34.03" width="2.4" height="11.5" transform="translate(-1.14 -0.84) rotate(-0.2)"/>
        <rect x="108.8" y="24.29" width="2.4" height="9.78" transform="matrix(1, 0, 0, 1, -1.12, -0.85)"/>
        <rect x="104.76" y="28.28" width="2.4" height="5.75" transform="translate(-1.21 -0.56) rotate(-0.4)"/>
        <rect x="100.15" y="28.88" width="2.4" height="5.19" transform="translate(-1.24 -0.51) rotate(-0.44)"/>
        <rect x="96.1" y="30.59" width="2.4" height="3.43" transform="matrix(1, -0.01, 0.01, 1, -1.37, -0.16)"/>
        <rect x="91.5" y="31.75" width="2.4" height="2.31" transform="translate(-1.56 0.31) rotate(-0.99)"/>
        <rect x="86.89" y="32.91" width="2.4" height="1.16" transform="translate(-2.11 1.77) rotate(-1.98)"/>
        <rect x="135.36" y="34.03" width="2.4" height="9.78" transform="translate(-1.16 -0.74) rotate(-0.23)"/>
        <rect x="139.97" y="34.03" width="2.4" height="5.75" transform="translate(-1.25 -0.31) rotate(-0.4)"/>
        <rect x="144.57" y="34.03" width="2.4" height="5.19" transform="translate(-1.28 -0.14) rotate(-0.45)"/>
        <rect x="148.62" y="34.03" width="2.4" height="3.43" transform="matrix(1, -0.01, 0.01, 1, -1.41, 0.45)"/>
        <rect x="153.22" y="34.03" width="2.4" height="2.31" transform="translate(-1.59 1.38) rotate(-0.99)"/>
        <rect x="157.83" y="34.03" width="2.4" height="1.16" transform="translate(-2.1 4.22) rotate(-1.98)"/>
</SVG>);
