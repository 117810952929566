import React from 'react';
import PropTypes from 'prop-types';

const LineIcon = ({color='black', lineStyle="0", width}) => {
    let dashArray = null;
    let lineCap = null;
    let double = false;
    let doubleWidth = null;

    switch(lineStyle) {
        case 1:
            dashArray = '4 1';
            break;
        case 2:
            dashArray = '.4 8';
            lineCap='round';
            break;
        case 3:
            dashArray = '14 4';
            break;
        case 4:
            double=true;
            doubleWidth = 1;
            break;
        case 5:
            double = true;
            doubleWidth= 2;
            break;
        case 6:
            dashArray = '24 4 4 4';
            break;
        default: dashArray = null;
    }
    return (
        <svg width={width} height={4} viewBox="0 0 100 4" style={{pointerEvents: 'none'}}>
            {double
                ? <g>
                    <line x1="0" x2="100" y1="0" y2="0"
                          strokeWidth={doubleWidth}
                          stroke={color}
                          strokeDasharray={dashArray}
                          strokeLinecap={lineCap}
                    />
                    <line x1="0" x2="100" y1="4" y2="4"
                          strokeWidth={doubleWidth}
                          stroke={color}
                          strokeDasharray={dashArray}
                          strokeLinecap={lineCap}
                    /></g>
                : <line x1="0" x2="100" y1="2" y2="2"
                        strokeWidth="4"
                        stroke={color}
                        strokeDasharray={dashArray}
                        strokeLinecap={lineCap}
                />
            }
        </svg>
    )
};

LineIcon.defaultProps = {
    width: 100
};

LineIcon.propTypes = {
    color: PropTypes.string,
    lineStyle: PropTypes.number,
    width: PropTypes.number
};

export default LineIcon;
