import React from 'react';
import styled from '@emotion/styled';

const Container = styled.div`
    position: absolute;
    height: 4px;
    background: ${props => props.theme.primary};
    z-index: 1;
    pointer-events: none;
    left: ${props => props.p1}px;
    width: ${props => props.p2 - props.p1}px;
    top: ${props => props.top}px;
`;

const SelectedTrack = ({p1, p2, top}) => {
    return <Container p1={p1} p2={p2} top={top}/>
};

export default SelectedTrack;
