import React from 'react';
import TBRLogo from "../Icons/TBRLogo";
import styled from "@emotion/styled";
import buildingImage from "../Images/building.png";

const Left = styled.div`
    background: ${props => props.theme.gray50};
    background-image: url(${buildingImage});
    background-position: -190px top;
    position: relative;
`;

const LeftInner = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.55);
    padding-left: 100px;
    padding-top: 40px;
    box-sizing: border-box;
`;

const LoginLeft = () => {
    return (
        <Left>
            <LeftInner>
                <TBRLogo width={220}/>
            </LeftInner>
        </Left>
    )
};

export default LoginLeft;
