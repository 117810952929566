import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as actions from '../../store/actions/index';
import usePrevious from '../../hooks/usePrevious';
import TemplateForm from "./TemplateForm";
import useQsMaker from "../../hooks/useQsMaker";

const TemplateFormConnected = (props) => {
    const {onComplete, params, type, higher} = props;
    const dispatch = useDispatch();
    const templates = useSelector(state => state.template);
    const prevSaving = usePrevious(templates.saving);

    const selected = useSelector(state => state.selection.selected);
    const selectedBubble = useSelector(state => state.selection.selectedBubble);
    const fromComputed = type === 'bubble'
        ? selectedBubble : selected;

    console.log('fromComputed: ', fromComputed);

    const computedParams = useQsMaker(fromComputed, type);


    useEffect(() => {
        if (prevSaving && !templates.saving) {
            onComplete();
        }
    }, [templates.saving, onComplete, prevSaving]);

    return (
        <TemplateForm
            handleSubmit={(name, params) => dispatch(actions.saveTemplate(name, params))}
            onComplete={onComplete}
            params={computedParams || params}
            higher={higher}
        />
    )
};

export default TemplateFormConnected;
