import React, {useState} from 'react';
import styled from '@emotion/styled';
import qs from 'qs';
import LineIcon from "../TBRIcons/LineIcon";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Button from "../Button/Button";
import PropTypes from 'prop-types';
import BubbleIcon from "../TBRIcons/BubbleIcon";

const Container = styled.div`
    margin-bottom: 12px;
    margin-right: 12px;
    padding: 12px 10px;
    color: white;
    width: 360px;
    background: ${({confirm, theme, type}) => confirm ? theme.error : type === 'bubble' ? theme.primaryDark :theme.primary};
    border-left: 2px solid ${({confirm, theme}) => confirm ? theme.error : theme.primaryLight};
    border-radius: 4px;
    display: inline-block;
    position: relative;
    polyline, line {
        fill: none;
        stroke: white;
    }
    span {
        margin-left: 8px;
    }
        path {
        stroke: ${({confirm, theme, type}) => confirm ? theme.error : type === 'bubble' ? theme.primaryDark : theme.primary};
        stroke-width: 22.2pt;
    }
    &:hover {
        background: ${({confirm, theme, type}) => confirm ? theme.error : type === 'bubble' ? theme.primary900 : theme.primaryDark};
        border-left: 2px solid ${({confirm, theme}) => confirm ? theme.error : theme.primary};
        path {
            stroke: ${({confirm, theme}) => confirm ? theme.error : theme.primaryDark};
        }
    }
`;

const TitleContainer = styled.div`
    opacity: ${props => props.confirm ? .4 : 1};
    display: flex;
    align-items: center;
    margin-bottom: 2px;
    margin-left: 12px;
    margin-top: 2px;
    user-select: none;
    pointer-events: none;
    polyline, line {
        fill: none;
        stroke: white;
    }
    circle {
        fill: white;
    }
    path {
        stroke: none !important;
    }
`;

const ActionIcons = styled.div`
    opacity: ${props => props.confirm ? .4 : 1};
    display: flex;
    justify-content: flex-end;

    div {
        margin: 0 3px;
        border: 1px solid white;
        height: 20px;
        width: 20px;
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        cursor: pointer;
        background: rgba(255, 255, 255, 0.2);
        &:hover {
            border: 1px solid rgba(255, 255, 255, 0.0);
            background: rgba(255, 255, 255, 0.4);
            path {
                fill: ${props => props.theme.primaryDark};
            }
        }
    }
`;

const ConfirmRemove = styled.div`
    padding: 2px 24px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    margin-top: 4px;
    button {
        margin-top: 2px;
        margin-right: 6px;
    }
`;

const OverrideButton = styled.div`
    button {
        background: black;
    }
`;

const TemplateChip = (props) => {
    const [confirm, setConfirm] = useState(false);
    const {template, deleteTemplate, editTemplate, viewChart} = props;
    if (!template) return null;
    const {name, parameters} = template;
    const {type} = qs.parse(parameters);

    return (
        <Container confirm={confirm} type={type} onClick={viewChart} data-cy="template-chip">
            <ActionIcons confirm={confirm}>
                <div onClick={e => {e.stopPropagation(); setConfirm(true);}}>
                    <FontAwesomeIcon icon={['fas', 'times']}/>
                </div>
                <div style={{fontSize: 12}} onClick={e => {e.stopPropagation(); editTemplate();}}>
                    <FontAwesomeIcon icon={['fas', 'pen']}/>
                </div>
                <div onClick={viewChart}>
                    <FontAwesomeIcon icon={['fas', 'chevron-right']}/>
                </div>
            </ActionIcons>
            <TitleContainer confirm={confirm}>
                {type === 'bubble' ? <BubbleIcon/> : <LineIcon/>}
                <span>{name}</span>
            </TitleContainer>
            {confirm ? <ConfirmRemove>
                <div style={{fontSize: 12}}><em>Are you sure?</em></div>
                    <div style={{display: 'flex', marginTop: 4}}>
                    <OverrideButton>
                        <Button onClick={e => {e.stopPropagation(); deleteTemplate();}}>Yes, remove</Button>
                    </OverrideButton>
                    <Button cancel onClick={e => {e.stopPropagation(); setConfirm(false);}}>No</Button>
                </div>
            </ConfirmRemove> : null}
        </Container>
    )
};

TemplateChip.propTypes = {
    template: PropTypes.object.isRequired,
    deleteTemplate: PropTypes.func.isRequired,
    editTemplate: PropTypes.func.isRequired,
    viewChart: PropTypes.func.isRequired
};

export default TemplateChip;
