export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';

export const LOGIN_START = 'LOGIN_START';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';

export const LOGOUT_START = 'LOGOUT_START';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAIL = 'LOGOUT_FAIL';

export const LOAD_COMPANIES_START = 'LOAD_COMPANIES_START';
export const LOAD_COMPANIES_SUCCESS = 'LOAD_COMPANIES_SUCCESS';
export const LOAD_COMPANIES_FAIL = 'LOAD_COMPANIES_FAIL';

export const LOAD_SEGMENTS_START = 'LOAD_SEGMENTS_START';
export const LOAD_SEGMENTS_SUCCESS = 'LOAD_SEGMENTS_SUCCESS';
export const LOAD_SEGMENTS_FAIL = 'LOAD_SEGMENTS_FAIL';

export const LOAD_METRICS_START = 'LOAD_METRICS_START';
export const LOAD_METRICS_SUCCESS = 'LOAD_METRICS_SUCCESS';
export const LOAD_METRICS_FAIL = 'LOAD_METRICS_FAIL';

export const SET_SELECTION_TYPE = 'SET_SELECTION_TYPE';

export const ADD_SEGMENT_START = 'ADD_SEGMENT_START';
export const ADD_SEGMENT_SUCCESS = 'ADD_SEGMENT_SUCCESS';
export const ADD_SEGMENT_FAIL = 'ADD_SEGMENT_FAIL';

export const ADD_COMPANY_START = 'ADD_COMPANY_START';
export const ADD_COMPANY_SUCCESS = 'ADD_COMPANY_SUCCESS';
export const ADD_COMPANY_FAIL = 'ADD_COMPANY_FAIL';

export const ADD_SEGMENT_TO_COMPANY = 'ADD_SEGMENT_TO_COMPANY';
export const REMOVE_SEGMENT_FROM_COMPANY = 'REMOVE_SEGMENT_FROM_COMPANY';

export const ADD_SEGMENTS_TO_COMPANIES = 'ADD_SEGMENTS_TO_COMPANIES';

export const REMOVE_SEGMENT = 'REMOVE_SEGMENT';
export const REMOVE_COMPANY = 'REMOVE_COMPANY';

export const ADD_COMPANY_TO_SEGMENT = 'ADD_COMPANY_TO_SEGMENT';
export const REMOVE_COMPANY_FROM_SEGMENT = 'REMOVE_COMPANY_FROM_SEGMENT';

export const REMOVE_METRIC_FROM_DATA_PAIR = 'REMOVE_METRIC_FROM_DATA_PAIR';

export const ADD_METRIC_TO_ALL_PAIRS = 'ADD_METRIC_TO_ALL_PAIRS';

export const CONFIRM_METRIC_START = 'CONFIRM_METRIC_START';
export const CONFIRM_METRIC_SUCCESS = 'CONFIRM_METRIC_SUCCESS';
export const CONFIRM_METRIC_FAIL = 'CONFIRM_METRIC_FAIL';

export const OPEN_COMPANY_PANEL = 'OPEN_COMPANY_PANEL';
export const CLOSE_COMPANY_PANEL = 'CLOSE_COMPANY_PANEL';

export const LOAD_COMPANIES_TO_COMPANY_PANEL_START = 'LOAD_COMPANIES_TO_COMPANY_PANEL_START';
export const LOAD_COMPANIES_TO_COMPANY_PANEL_SUCCESS = 'LOAD_COMPANIES_TO_COMPANY_PANEL_SUCCESS';
export const LOAD_COMPANIES_TO_COMPANY_PANEL_FAIL = 'LOAD_COMPANIES_TO_COMPANY_PANEL_FAIL';

export const LOAD_ANALYTICS_START = 'LOAD_ANALYTICS_START';
export const LOAD_ANALYTICS_SUCCESS = 'LOAD_ANALYTICS_SUCCESS';
export const LOAD_ANALYTICS_FAIL = 'LOAD_ANALYTICS_FAIL';

export const LOAD_BUBBLE_START = 'LOAD_BUBBLE_START';
export const LOAD_BUBBLE_SUCCESS = 'LOAD_BUBBLE_SUCCESS';
export const LOAD_BUBBLE_FAIL = 'LOAD_BUBBLE_FAIL';

export const LOAD_BUBBLE_TEMPLATES_START = 'LOAD_BUBBLE_TEMPLATES_START';
export const LOAD_BUBBLE_TEMPLATES_SUCCESS = 'LOAD_BUBBLE_TEMPLATES_SUCCESS';
export const LOAD_BUBBLE_TEMPLATES_FAIL = 'LOAD_BUBBLE_TEMPLATES_FAIL';

export const SET_DISPLAY_DATE = 'SET_DISPLAY_DATE';
export const SET_DISPLAY_PRESET = 'SET_DISPLAY_PRESET';

export const LOAD_TEMPLATES_START = 'LOAD_TEMPLATES_START';
export const LOAD_TEMPLATES_SUCCESS = 'LOAD_TEMPLATES_SUCCESS';
export const LOAD_TEMPLATES_FAIL = 'LOAD_TEMPLATES_FAIL';

export const SAVE_TEMPLATE_START = 'SAVE_TEMPLATE_START';
export const SAVE_TEMPLATE_SUCCESS = 'SAVE_TEMPLATE_SUCCESS';
export const SAVE_TEMPLATE_FAIL = 'SAVE_TEMPLATE_FAIL';

export const UPDATE_TEMPLATE_START = 'UPDATE_TEMPLATE_START';
export const UPDATE_TEMPLATE_SUCCESS = 'UPDATE_TEMPLATE_SUCCESS';
export const UPDATE_TEMPLATE_FAIL = 'UPDATE_TEMPLATE_FAIL';

export const DELETE_TEMPLATE_START = 'DELETE_TEMPLATE_START';
export const DELETE_TEMPLATE_SUCCESS = 'DELETE_TEMPLATE_SUCCESS';
export const DELETE_TEMPLATE_FAIL = 'DELETE_TEMPLATE_FAIL';

export const SET_BUBBLE_LOB = 'SET_BUBBLE_LOB';
export const SET_BUBBLE_TEMPLATE = 'SET_BUBBLE_TEMPLATE';
export const SET_BUBBLE_SEGMENT = 'SET_BUBBLE_SEGMENT';
export const ADD_BUBBLE_COMPANY = 'ADD_BUBBLE_COMPANY';
export const REMOVE_BUBBLE_COMPANY = 'REMOVE_BUBBLE_COMPANY';

export const ADD_BUBBLE_COMPANIES = 'ADD_BUBBLE_COMPANIES';

export const LOAD_COMPANIES_FOR_TEMPLATE_START = 'LOAD_COMPANIES_FOR_TEMPLATE_START';
export const LOAD_COMPANIES_FOR_TEMPLATE_SUCCESS = 'LOAD_COMPANIES_FOR_TEMPLATE_SUCCESS';
export const LOAD_COMPANIES_FOR_TEMPLATE_FAIL = 'LOAD_COMPANIES_FOR_TEMPLATE_FAIL';

export const SET_LOBS_FOR_SEGMENTS_LINE = 'SET_LOBS_FOR_SEGMENTS_LINE';

export const INIT_SETUP = 'INIT_SETUP';
export const SET_CHART_VIEW = 'SET_CHART_VIEW';

export const EXPORT_EXCEL_START = 'EXPORT_EXCEL_START';
export const EXPORT_EXCEL_SUCCESS = 'EXPORT_EXCEL_SUCCESS';
export const EXPORT_EXCEL_FAIL = 'EXPORT_EXCEL_FAIL';

export const SHARE_CHART_START = 'SHARE_CHART_START';
export const SHARE_CHART_SUCCESS = 'SHARE_CHART_SUCCESS';
export const SHARE_CHART_FAIL = 'SHARE_CHART_FAIL';

export const SHARE_CHART_AGAIN = 'SHARE_CHART_AGAIN';

export const CLOSE_ENTRY_ERROR = 'CLOSE_ENTRY_ERROR';
