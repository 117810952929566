import React from 'react';
import {keyframes} from '@emotion/core';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const loading = keyframes`
    from {left: 50%; width: 0;z-index:100;}
    33.3333% {left: 0; width: 100%;z-index: 10;}
    to {left: 0; width: 100%;}
`;

const Container = styled.div`
    position: relative;
    margin-top: 0;
    width: 100%;
    height: ${props => props.height || 6}px;
    background-color: transparent;
    &>div {
        content: "";
        display: inline;
        position: absolute;
        width: 0;
        height: 100%;
        left: 50%;
        text-align: center;     
    }
`;

const Bar1 = styled.div`
    // background-color: pink;
    background-color: ${props => props.theme.highlight};
    animation: ${loading} 3s linear infinite;
`;
const Bar2 = styled.div`
    background-color: ${props => props.theme.primaryDark};
    animation: ${loading} 3s linear 1s infinite;
`;
const Bar3 = styled.div`
    background-color: ${props => props.theme.primary};
    animation: ${loading} 3s linear 2s infinite;
`;

const BarLoader = (props) => props.show
    ? <Container height={props.height} data-cy="bar-loader">
        <Bar1 />
        <Bar2 />
        <Bar3 />
    </Container> : null;

BarLoader.propTypes = {
    show: PropTypes.bool,
    height: PropTypes.number
};

export default BarLoader;
