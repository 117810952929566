import React from 'react';
import styled from "@emotion/styled";

const Wrapper = styled.div`
    position: absolute;
    right: 0;
    width: 100%;
`;

const Container = styled.div`
    z-index: 1;
    position: absolute;
    right: calc(-100% - 56px);
    top: -23px;
    width: calc(100% + 36px);
    padding: 18px;
    background: white;
    border-radius: 2px;
    box-sizing: border-box;
    box-shadow: ${props => props.theme.shadow2};
`;

const Triangle = styled.div`
    position: absolute;
    left: -18px;
    top: 19px;
    width: 0;
    height: 0;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    border-right: 20px solid white;
`;

const CompanyListContainer = (props) => {
    return (
        <Wrapper>
            <Container>
                <Triangle/>
                {props.children}
            </Container>
        </Wrapper>
    )
};

export default CompanyListContainer;
