import React from 'react';

const QuadrantPlacementCircles = ({data, xScale, yScale, rScale, color, ghosted, id, xKey, yKey, rKey}) => {
    return data.map((d, i) => <circle
                key={i}
                cx={xScale(d[xKey])}
                cy={yScale(d[yKey])}
                r={rScale(d[rKey])}
                stroke={color}
                strokeOpacity=".2"
                strokeWidth="1"
                opacity={d.noValue ? 0 : ghosted ? 1 : 0}
                fill={color}
                fillOpacity=".1"
                className={`centers-${id}`}/>
            )};

export default QuadrantPlacementCircles;
