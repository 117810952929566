import React from 'react';
import Home from "./Pages/Home";
import {Route, Switch} from "react-router";
import Comparison from "./Pages/Comparison";
import Custom from "./Pages/Custom";
import ComparisonChart from "./Pages/ComparisonChart";
import BubblePage from "./Pages/BubblePage";
import Template from "./Pages/Template";

const ContentRoutes = () => {
    return (
        <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/comparison-analytics" exact component={Comparison} />
            <Route path="/custom-analytics" exact component={Custom} />
            <Route path="/chart" component={ComparisonChart} />
            <Route path="/bubble" component={BubblePage} />
            <Route path="/templates" exact component={Template} />
        </Switch>
    )
};

export default ContentRoutes;
