import React, {useState} from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import 'rc-tooltip/assets/bootstrap.css';
import ShowHideCompanyListIcon from "./ShowHideCompanyListIcon";
import SegmentCompanyContainer from "./SegmentCompanyContainer";
import EmptyContainer from "../EmptyContainer/EmptyContainer";
import CustomCompanyListSelector from "./CustomCompanyListSelector";
import CircleIcon from "../Icons/CircleIcon/CircleIcon";

const Container = styled.div`
    background: ${({pendingRemove, theme}) => pendingRemove ? theme.errorDark : theme.primaryDark};
    padding: ${props => props.theme.pad1};
    margin-bottom: 18px;
    border-radius: 2px;
    margin-right: 4px;
    path {
        stroke: ${props => props.theme.errorDark};
    }
`;

const Title = styled.div`
    color: white;
    font-weight: 700;
    padding: 2px 0 6px 10px;
`;

const InnerContainer = styled.div`
    display: flex;
    justify-content: space-between;
    position: relative;
`;

const SegmentCompanySelectBox = (props) => {
    const [pendingRemove, setPendingRemove] = useState(false);
    const selChildren = props.groupBy === 'segments'
        ? props.selected.selectedCompanies : props.selected.selectedSegments;
    const selectedParents = selChildren || [];

    const inFilteredList = company => !selChildren || !selChildren.find(s => s.code === company.code);

    const filteredList = props.selected.companyList
        && props.selected.companyList.filter(inFilteredList);

    return (
        <Container pendingRemove={pendingRemove} position={props.position} data-cy="segment-company-select-box">
            <InnerContainer>
                <Title>
                    <span data-cy="data-pair-title">{props.selected.name}</span>
                </Title>
                <div style={{display: 'flex'}}>
                    <CircleIcon
                        circle
                        remove
                        removing={pendingRemove}
                        handleOver={setPendingRemove}
                        onClick={(() => props.onRemoveParent(props.selected.code))}
                    />

                    { props.groupBy === 'segments' ?
                    <ShowHideCompanyListIcon
                        pendingRemove={pendingRemove}
                        showList={props.openCompanyPanel === props.selected.code}
                        setShowList={props.openCompanyPanel === props.selected.code
                            ? props.onCloseCompanyPanel
                            : props.onOpenCompanyPanel}
                    /> : null }
                </div>
            </InnerContainer>

            <CustomCompanyListSelector
                isLoading={props.isLoading}
                companyList={filteredList}
                openCompanyPanelCode={props.openCompanyPanel}
                onClose={props.onCloseCompanyPanel}
                code={props.selected.code}
                onAddCompany={props.onAddChild}
            />

            <div>{selectedParents.length
                ? selectedParents.map(company => <SegmentCompanyContainer
                    comparison={props.comparison}
                    key={company.code}
                    company={company}
                    onRemove={() => props.onRemoveChild(props.selected.code, company)} // change to child code
                    onRemoveMetric={metricCode => props.onRemoveMetric(props.selected.code, company.code, metricCode)}
                />)
                : <EmptyContainer onOpen={props.onOpenCompanyPanel}>No {props.groupBy === 'segments' ? 'companies' : 'segments'} selected</EmptyContainer>}
            </div>
        </Container>
    )
};

SegmentCompanySelectBox.defaultProps = {
    groupBy: 'segment'
};

SegmentCompanySelectBox.propTypes = {
    groupBy: PropTypes.string,
    selected: PropTypes.object,
    onAddChild: PropTypes.func,
    onRemoveChild: PropTypes.func.isRequired,
    onRemoveParent: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    position: PropTypes.number,
    openCompanyPanel: PropTypes.string,
    onOpenCompanyPanel: PropTypes.func,
    onCloseCompanyPanel: PropTypes.func,
    onRemoveMetric: PropTypes.func,
    comparison: PropTypes.bool
};

export default SegmentCompanySelectBox;
