import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import colors from '../styles/theme';
import LineIcon from "./LineIcon";

const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    user-select: none;
    text-align: center;
`;

const ChipContainer = styled.div`
    background: ${props => props.noLine ? props.color : 'white'};
    min-width: 140px;
    border-radius: 4px;
    padding: 6px 12px 12px 12px;
    font-size: 12px;
    margin-right: 6px;
    margin-bottom: 6px;
    color: ${props => props.noData ? props.theme.gray400 : props.noLine ? 'white' : props.theme.gray800};
    font-weight: 400;
    cursor: ${props => props.noData ? 'default' : 'pointer'};
    border: 1px solid ${props => props.noData ? props.theme.gray300 : props.color};
    // background: ${props => props.noData ? props.theme.gray300 : props.color};
    opacity: ${props => props.muted ? 0.2 : 1};
    &:hover {
        opacity: ${props => props.noData ? 1 : props.muted ? 0.2 : 0.8 };
    }
`;
const Title = styled.span`
    font-size: 14px;
    font-weight: 700;
    line-height: 200%;
`;

ChipContainer.displayName = 'ChipContainer';

const ButtonChips = (props) => {
    const {series, hidden, handleShow, handleHide, handleHover, handleUnhover, idKey, noLine, titleOnly} = props;

    const sorted = series.slice().sort((a, b) => {
        if (b.company > a.company)  return -1;
        if (b.company < a.company)  return 1;
        return 0;
    });

    return (
        <Container>
            {sorted.map((com, i) => <ChipContainer
                data-cy="button-chip"
                onMouseOver={() => !hidden[com[idKey]] && !com.noData && com.color ? handleHover(com) : null}
                onMouseLeave={handleUnhover}
                onClick={() => hidden[com[idKey]] ? handleShow(com) : handleHide(com)}
                key={i}
                noData={!com.color}
                color={com.color ? com.color : colors.gray300}
                muted={hidden[com[idKey]]}
                noLine={noLine}
            >
                <Title>{com.company}</Title><br />
                {!titleOnly ? <span>{com.segment}<br />{com.name}</span> : null}
                {!com.color || noLine ? null : <><br /><LineIcon width={200} color={com.color} lineStyle={com.lineStyle} /></> }
            </ChipContainer>)}
        </Container>
    )
};

ButtonChips.defaultProps = {
    hidden: {},
    idKey: 'id'
};

ButtonChips.propTypes = {
    hidden: PropTypes.object,
    noLine: PropTypes.bool
};

export default ButtonChips;
