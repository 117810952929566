import React from 'react';
import styled from "@emotion/styled";
import TextFormat from "../components/TextFormat";
import format from 'date-fns/format';
import {label} from '../utilities/formatter';

const Wrapper = styled.div`
    line-height: 1.6em;
    font-weight: 400;
`;

const TitleContainer = styled.div`
    font-size: 12px;
    font-weight: 700;
`;

const DetailContainer = styled.div`
    margin-top: 14px;
`;

const SegmentWrapper = styled.div`
    color: ${props => props.theme.primary};
    font-size: 14px;
    font-weight: 700;
`;
const Projection = styled.span`
    font-style: italic;
    font-size: 12px;
    font-weight: 400;
`;

const PositionChartTipDetail = (props) => {
    const {companyName, name, segmentName, date, value, isProjection, type, metric} = props.tip.data;
    const formattedDate = format(date, props.period === 'quarterly' ? 'yyyy-QQQ' : 'yyyy');

    return (
        <Wrapper>
            <TitleContainer>{ companyName } | {name} </TitleContainer>
            <SegmentWrapper>{formattedDate} | {segmentName} {isProjection ? <Projection> | TBR Projection</Projection> : null}</SegmentWrapper>
            <DetailContainer>
                {TextFormat(value, label({type, metric}).format)}
            </DetailContainer>
        </Wrapper>
    )
};

export default PositionChartTipDetail;
