import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const Container = styled.div`
    overflow-y: auto;
    & > div:first-of-type {
          /* width */
        &::-webkit-scrollbar {
            width: 6px;
        }
    
        /* Track */:
        &::-webkit-scrollbar {
            box-shadow: inset 0 0 2px ${props => props.theme.gray900};
            border-radius: 3px;
        }
    
        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: ${props => props.color ? props.theme[props.color] : props.theme.primaryLight};
            border-radius: 3px;
            cursor: pointer;
            &:hover {
                background: ${props => props.theme.primary};
            }
        }      
    }
`;

const FancyScroll = (props) => {
    const fn = child => React.cloneElement(child, {style: {overflow: 'auto'}});
    return <Container color={props.color}>{React.Children.map(props.children, fn)}</Container>
};

FancyScroll.propTypes = {
    container: PropTypes.object,
    color: PropTypes.string
};

export default FancyScroll;
