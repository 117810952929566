import qs from 'querystring';

const getParis = params => {
    const paramOptions = qs.parse(params.replace('?', ''));
    return paramOptions.combinations.split(';');
};

export const lineSegmentSelection = (params, segments, companies, metrics) => {
    const pairs = getParis(params);
    const flattenedSegments = getMapped(segments);

    const matchedSegments = pairs.map(p => p.split(':')[1])
        .map(code => {
            return Object.entries(flattenedSegments).find(e => e[1].code === code)[1];
        }).filter((v, i, s) => s.indexOf(v) === i);

    const matchedCompanies = pairs.reduce((acc, curr) => {
        const segCode = curr.split(':')[1];
        const comCode = curr.split(':')[0];
        const comObject = companies.find(co => co.code === comCode);
        const alreadyThere = acc[segCode] && acc[segCode].find(c => comCode === c.code);
        return {...acc, [segCode]: acc[segCode]
                ? alreadyThere ? acc[segCode]
                    : [...acc[segCode], comObject]
                : [comObject]}
    }, {});

    const withSelectedCompanies = matchedSegments
        .map(s => ({...s,
            selectedCompanies: matchedCompanies[s.code].map(c => ({
                ...c, metrics: pairs.filter(p => {
                    const vals = p.split(':');
                    return vals[0] === c.code && vals[1] === s.code;
                }).map(p => metrics.find(m => m.code === p.split(':')[2]))
            }))
        })
    );

    const lobs = pairs.reduce((acc, curr) => {
        const lobCode = curr.split(':')[1].split('_')[0];
        const fullLob = Object.entries(flattenedSegments).find(e => e[0] === lobCode);
        return {...acc, [lobCode]: fullLob[1]}
    }, {});

    const selectedLobCodes = Object.entries(lobs).map(e => ({code: e[1].lob}));
    const selectedLobs = selectedLobCodes.map(l => segments.find(s => s.code === l.code));

    return {
        selected: {segments: withSelectedCompanies},
        selectedLobs
    };
};

// This is not being used
export const lineCompanySelection = (params, segments, companies, metrics) => {
    console.log('dude');

    const pairs = getParis(params);
    const flattenedSegments = getMapped(segments);

    const matchedCompanies = pairs.reduce((acc, curr) => {
        const coCode = curr.split(':')[0];
        return {...acc, [coCode]: companies.find(co => co.code === coCode)}
    }, {});

    const matchedCompanyArray = Object.entries(matchedCompanies).map(e => e[1]);

    const matchedSegments = matchedCompanyArray.reduce((acc, curr) => {
        const segs = pairs
            .filter(p => p.split(':')[0] === curr.code)
            .map(p => flattenedSegments[p.split(':')[1]])
            .filter((v, i, s) => s.indexOf(v) === i)
            .map(s => ({...s, metrics: metricsFromPairs(metrics, pairs, s.code, curr.code)}))
        ;

        return {...acc, [curr.code]: segs};
    }, {});

    const withSegments = matchedCompanyArray
        .map(co => ({...co, selectedSegments: matchedSegments[co.code]}));

    return {selected: {companies: withSegments}};
};

export const bubbleSelection = (params, segments, companies, metrics) => {
    // Note: We need to pass along bubble id for this
    return {}
};

const metricsFromPairs = (metrics, pairs, s, c) => {
    const matchedPairs = pairs.filter(p => p.split(':')[0] === c && p.split(':')[1] === s);
    return matchedPairs.map(p => metrics.find(m => m.code === p.split(':')[2]));
};

function getMapped(segments, lob, memo = {}) {
    segments.forEach(seg => {
        if (!seg) return;
        memo[seg.code] = {code: seg.code, name: seg.name, lob: lob || seg.code};
        getMapped(seg.segments, lob || seg.code, memo);
    });
    return memo;
}
