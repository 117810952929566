import React, {useEffect, useRef, useState} from 'react';

import {select} from 'd3-selection';
import {easeLinear} from 'd3';
import PropTypes from 'prop-types';

// How to use 'time' to make this be correct on multiple changes with buttons
const TimeKeeper = ({playing, setTime, timeMarker, duration, setPlaying}) => {
    const placeRef = useRef(null); // Placeholder
    const [pauseValue, setPauseValue] = useState(0);

    useEffect(() => {
        const cur = placeRef.current;
        if (playing) {
            transition();
        } else {
            interrupted();
        }
        return () => {
            select(cur).interrupt();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [playing, timeMarker]);

    const transition = () => {
        select(placeRef.current)
            .transition()
            .duration(duration)
            .ease(easeLinear)
            .attrTween('opacity', update(placeRef))
            .on('end', () => {
                setTime(1); // Looping
                setPlaying(false, true);
            })
            .on('start', () => {
                setTime(timeMarker);
            });
    };

    function interrupted() {
        if (playing) {
            select(placeRef.current).transition().duration(0);
            setPauseValue(timeMarker);
            setTime(timeMarker);
        }
        setTimeout(() => {
        }, 0);
    }

    function update(placeRef) {
        if (!placeRef.current) return;
        return i => t => {
            let localT = t + pauseValue;
            setPauseValue(localT % 1);
            setTime(localT % 1);
        }
    }

    return (
        <div ref={placeRef} style={{position: 'absolute'}}/>
    )
};

TimeKeeper.propTypes = {
    playing: PropTypes.bool,
    setTime: PropTypes.func.isRequired,
    updateTime: PropTypes.number
};

export default TimeKeeper;
