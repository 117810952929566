import React from 'react';
import DateFilters from "../DateFilter/DateFilters";
import * as actions from "../../store/actions";
import RadioButton from "../RadioButton/RadioButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import styled from "@emotion/styled";
import PropTypes from 'prop-types';
import saveImage from "../../utilities/saveImage";
import Tooltip from "rc-tooltip";
import ChartShare from "./ChartShare";

const DateContainer = styled.div`
    margin-top: 22px;
    input {
        font-size: 12px;
        width: 90px;
        border-color: ${props => props.theme.gray300};
        text-align: center;
        padding-left: 8px;
    }
    span {
        margin-top: 6px;
        color: ${props => props.theme.gray500};
    }
`;

const RadioContainer = styled.div`
    margin-left: 24px;
    display: flex;
`;

const IconContainer = styled.div`
    margin-left: 24px;
    margin-top: -6px; 
    display: flex;
    font-size: 22px;
    cursor: pointer;
    min-width: 100px;
    svg {
        margin-right: 8px;
    }
    path {
        fill: ${props => props.theme.primary};
    }
`;

const Container = styled.div`
    display: flex;
    margin: 8px 0 24px 0;
    height: 20px;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 18px);
`;

const TipContent = styled.div`
    position: relative;
    top: 8px;
    padding: 0 18px;
`;

const TopActionElements = (props) => {
    const {ready, dateRange, dispatch, period, setPeriod, bubble, params, loading} = props;

    return (
        <Container>
            {ready ?
                <DateContainer>
                    <DateFilters
                        period={period}
                        dateRange={dateRange}
                        setDates={(d) => dispatch(actions.setDisplayDate(d))}
                        setPreset={preset => dispatch(actions.setDisplayPreset(preset))}
                    />
                </DateContainer> : null
            }
            {!bubble ?
                <RadioContainer>
                    <RadioButton label="Quarterly" name="term" checked={period === 'quarterly'}
                                 value="quarterly" onChange={() => setPeriod('quarterly')}/>
                    <RadioButton label="Annual" name="term" checked={period === 'annual'}
                                 value="annual" onChange={() => setPeriod('annual')}/>
                </RadioContainer> : null
            }

            <IconContainer>
                {loading === 'export'
                    ? <FontAwesomeIcon icon={['fas', 'circle-notch']} spin/>
                    : <>
                        <Tooltip overlay={<TipContent>Export to Excel</TipContent>} placement="top" trigger={['hover']}>
                            <div onClick={() => dispatch(actions.exportExcel(params))}>
                                <FontAwesomeIcon icon={['fa', 'file-excel']}/>
                            </div>
                        </Tooltip>

                        <Tooltip overlay={<TipContent>Export as Image</TipContent>} placement="top" trigger={['hover']}>
                            <div onClick={saveImage}>
                                <FontAwesomeIcon icon={['fa', 'file-image']}/>
                            </div>
                        </Tooltip>

                        <ChartShare dispatch={dispatch}/>

                    </>
                }
            </IconContainer>
        </Container>
    )
};

TopActionElements.propTypes = {
    ready: PropTypes.bool,
    dateRange: PropTypes.object,
    dispatch: PropTypes.func
};

export default TopActionElements;
