import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {withRouter} from "react-router";
import qs from 'qs';
import * as actions from '../store/actions/index';
import LineChartMultiAxis from "../charts/LineChartMultiAxis/LineChartMultiAxis";
import BarLoader from "../components/BarLoader/BarLoader";
import styled from '@emotion/styled';
import TopActionElements from "../components/TopActionElements/TopActionElements";
import LineDataTable from "./Line/LineDataTable";
import useAnnual from "../hooks/useAnnual/useAnnual";
import TemplateFormConnected from "../components/TemplateForm/TemplateFormConnected";
import TemplateMenu from "../components/TemplateMenu/TemplateMenu";
import EmptyTemplates from "../components/EmptyTemplates/EmptyTemplates";
import {Link} from "react-router-dom";
import useChartVisibility from "../charts/hooks/useChartVisibility";

const chipNames = s => ({
    id: s.id,
    name: s.name,
    color: s.color,
    company: s.companyName,
    segment: s.segmentName,
    lineStyle: s.lineStyle
});

const Title = styled.div`
    font-weight: 700;
    color: ${props => props.theme.primary};
`;
const HeaderContainer = styled.div`
    display: flex;
    margin-top: -8px;
    margin-bottom: 24px;
    border-bottom: 1px solid ${props => props.theme.gray100};
    padding-bottom: 12px;
    justify-content: space-between;
`;

const ComparisonChart = (props) => {
    const {series, requestedPairs, dateRange, loading} = useSelector(state => state.selection);
    const [period, setPeriod] = useState('quarterly');
    const [saving, setSaving] = useState(false);
    const dispatch = useDispatch();
    const params = props.location.search.replace('?', '');
    const annualFiltered = useAnnual(series, period === 'annual');
    const chartVis = useChartVisibility();

    useEffect(() => {
        dispatch(actions.loadAnalytics(params));
    }, [dispatch, params]);

    return (
        <div>
            {saving
                ? <TemplateFormConnected onComplete={() => setSaving(false)} params={params} higher />
                : <HeaderContainer>
                    <Title>Custom Analytics</Title>
                    <TemplateMenu
                        onSave={() => setSaving(true)}
                        editTo={`/comparison-analytics?${params}`}
                    />
                </HeaderContainer>
            }

            <TopActionElements
                ready={!!(series && series.length)}
                dateRange={dateRange}
                dispatch={dispatch}
                setPeriod={setPeriod}
                period={period}
                params={qs.parse(params)}
                loading={loading}
            />

            <div>
                {loading === 'analytics-data'
                    ? <div>
                        <div style={{marginBottom: 8}}>Loading...</div>
                        <BarLoader show={true}/>
                    </div>
                    : series && series.length ?
                    <>
                        <LineChartMultiAxis
                            dateRange={[dateRange.minDisplay, dateRange.maxDisplay]}
                            period={period}
                            showNames={requestedPairs.map(chipNames)}
                            data={annualFiltered}
                            chartVis={chartVis}
                        />
                        <LineDataTable
                            dateRange={[dateRange.minDisplay, dateRange.maxDisplay]}
                            series={annualFiltered.filter(a => !chartVis.muted[a.id])}
                            period={period}
                        />
                    </>
                        : <EmptyTemplates
                            message="There are no results for your selected criteria"
                            action={<div><Link to={`/comparison-analytics?${params}`}>Change criteria</Link></div>}
                            border />
                }
            </div>
        </div>
    )
};

export default withRouter(ComparisonChart);
