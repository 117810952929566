import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const Container = styled.div`
    background: ${props => props.theme.gray200};
    height: calc(100vh - ${props => props.heightOverride ? 118 : 228}px);
    padding: 24px 4px 24px 8px;
    width: 30%;
    position: relative;
    border-radius: 2px;
    user-select: none;
`;

const SelectorColumn = (props) => {
    return <Container heightOverride={props.heightOverride} {...props}>{props.children}</Container>
};

SelectorColumn.propTypes = {
    heightOverride: PropTypes.number,
    lastColumn: PropTypes.bool
};

export default SelectorColumn;
