import React, {useRef, useEffect} from 'react';
import {select} from 'd3-selection';
import {axisLeft} from "d3-axis";
import {format} from "d3-format";
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import theme from '../../styles/theme';

const LabelText = styled.text`
    font-size: 12px;
    fill: ${props => props.theme.primary};
`;

const YAxis = ({yScale, name, height, margin, chartVis, i, id, colorIndex, colors, tickFormat}) => {
    const yAxis = useRef(null);
    const innerHeight = height - margin.top - margin.bottom;
    const nameAppend = null;

    const doFormat = val => {
        const d3Format = format(tickFormat);
        const formatted = d3Format(val);
        return formatted.replace(/G/, 'B');
    };

    useEffect(() => {
       select(yAxis.current)
           .attr('transform', `translate(${margin.left + (i * margin.left)}, 0)`)
           .call(axisLeft(yScale)
               .tickFormat(doFormat) // Format will need to be passed down
               .tickPadding([0])
               .tickSizeOuter(0)
           );
    });


    const getAxisId = () => {
        return chartVis.pumped.split('_').filter((v,i,s) => i === s.length - 1)[0]
    };

    const pumpedId = chartVis.pumped ? getAxisId() : chartVis.pumped;

    function getRectFill() {

        if (chartVis.pumped && pumpedId === id) {
            return '#1A4379';
            // return colors ? colors[colInd] : chartColors[colInd];
        } else {
            return 'none';
        }
    }

    function getAxisClass() {
        return ['axis', 'y-axis', chartVis.pumped && pumpedId === id ? 'active' : null].join(' ');
    }

    function getLabelClass() {
     return `series-label${chartVis.pumped && pumpedId === id ? ' active' : ''}`;
    }

    return (
        <g id={`y-axis-${id}`} style={{
            fontFamily: 'Helvetica Neue, sans-serif',
            textTransform: 'uppercase'
        }}>
            <style dangerouslySetInnerHTML={{__html: `
                .axis .tick line {stroke: rgba(0,0,0,0.2); }
                .axis.x-axis path, .axis.y-axis path { stroke: rgba(0,0,0,0.2); }
                .axis.x-axis text, .axis.y-axis text {fill: #757575; }
                .series-label text {fill: ${theme.primary};}
            `}} />
                <rect
                    height={height - margin.top - margin.bottom + 16}
                    width={margin.left}
                    transform={`translate(${i * margin.left - 0}, ${margin.top - 8})`}
                    style={{transition: '.1s linear fill'}}
                    fill={getRectFill()}
                />
                <g ref={yAxis} className={getAxisClass()}/>
            <g className={getLabelClass()}>
                <LabelText
                    style={{fontSize: 12}}
                    textAnchor="middle"
                    transform={`translate(${(margin.left * (i + 1)) - (margin.left - 18)}, ${margin.top + innerHeight * .5}) rotate(-90)`}>
                    {name}{nameAppend}
                </LabelText>
            </g>
        </g>
    )
};

YAxis.defaultProps = {
    chartVis: {},
    i: 0
};

YAxis.propTypes = {
    yScale: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    height: PropTypes.number.isRequired,
    margin: PropTypes.object.isRequired,
    chartVis: PropTypes.object,
    i: PropTypes.number,
    selectedCompanies: PropTypes.array,
    colorIndex: PropTypes.number
};

export default YAxis;
