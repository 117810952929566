import React from 'react';
import styled from '@emotion/styled';
import format from 'date-fns/format';

const Container = styled.div`
    position: absolute;
    top: 460px;
    left: 10px;
    font-size: 12px;
    font-weight: 700;
    color: ${props => props.theme.primary};
    border: 1px solid ${props => props.theme.primary};
    padding: 4px 8px;
`;

const BubbleDisplayDate = (props) => {
    const {time, dates} = props;
    const closest = Math.ceil(time * dates.length);
    const dateKey = closest -1 >= 0 ? closest -1 : 0;

    return (
        <Container>{dates[dateKey] ? format(dates[dateKey], 'yyyy-QQQ') : null}</Container>
    )
};

export default BubbleDisplayDate;
