import React from 'react';
import PropTypes from 'prop-types';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome/index";
import styled from '@emotion/styled';

const Container = styled.div`
    padding: 12px 18px;
    margin-bottom: 12px;
    border: 1px solid ${props => props.theme.error};
    border-radius: 2px;
    color: ${props => props.theme.error};
    path {
        fill: ${props => props.theme.error};
    }
`;

const TextContainer = styled.span`
    padding-left: 8px;
    font-weight: 400;
    
`;

const ErrorOnPage = (props) => {
    return props.show ?
        <Container>
            <FontAwesomeIcon icon={['fas', 'exclamation']} />
            <TextContainer>{props.text}</TextContainer>
        </Container>
        : null
};

ErrorOnPage.defaultProps = {
    show: true,
    text: 'Something went wrong. Try again later.'
};

ErrorOnPage.propTypes = {
    text: PropTypes.string,
    show: PropTypes.bool
};

export default ErrorOnPage;
