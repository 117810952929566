import * as actionTypes from './actionTypes';
import axios from '../../axios';
import qs from 'qs';

export const checkAuth = () => dispatch => {
    const user = JSON.parse(localStorage.getItem('user'));

    if (!user) {
        return dispatch({type: actionTypes.AUTH_FAIL});
    }
    return dispatch({type: actionTypes.AUTH_SUCCESS, user});
};

export const login = (email, password ) => {
    return dispatch => {
        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        };

        const requestBody = {
            username: email,
            password: password
        };

        dispatch({type: actionTypes.LOGIN_START});

        return axios.post('/token', qs.stringify(requestBody), config).then(res => {
            const {accessToken, user} = res.data;
            const {userName, firstName, lastName, createdDate} = user;

            const storageUser = {
                token: accessToken,
                name: firstName + ' ' + lastName,
                email: userName,
                joined: createdDate
            };

            dispatch({
                type: actionTypes.LOGIN_SUCCESS,
                name: firstName + ' ' + lastName,
                email: userName,
                joined: createdDate,
                meta: {
                    token: {
                        user: storageUser,
                        create: true
                    }
                }
            });
        }).catch(err => {
            return dispatch({
                type: actionTypes.LOGIN_FAIL,
                error: 'The email or password you entered is incorrect.'
            });
        });
    }
};

export const logout = () => {
    return dispatch => {
        dispatch({type: actionTypes.LOGOUT_START});
        return dispatch({
            type: actionTypes.LOGOUT_SUCCESS,
            meta: {
                token: {
                    destroy: true
                }
            }
        });
    }
};

export const share = (email, name, link, message) => {
    return {
        type: actionTypes.SHARE_CHART_START,
        meta: {
            api: {
                method: 'post',
                endpoint: 'api/user/share',
                payload: {
                    toEmail: email,
                    toName: name,
                    link,
                    message
                }
            }
        }
    }
};

export const shareAgain = () => ({type: actionTypes.SHARE_CHART_AGAIN});
