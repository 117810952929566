import React, {useState} from 'react';
import styled from '@emotion/styled';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PropTypes from 'prop-types';
import CircleIcon from "../Icons/CircleIcon/CircleIcon";

const Container = styled.div`
    margin-left: ${props => props.sub ? 18 : 0}px;
    padding: 10px 0;
    font-size: 14px;
    position: relative;
`;

const OpenerContainer = styled.div`
    cursor: pointer;
    margin-right: 8px;
    border: 1pt solid ${props => props.theme.primary};
    border-radius: 50%;
    width: 16px;
    height: 16px;
    font-size: 9px;
    flex-shrink: 0;
    display: ${props => props.hasChildren ? 'flex' : 'none'};
    align-items: center;
    justify-content: center;
    path {
        stroke: white;
        stroke-width: 2.2pc;
        fill: ${props => props.theme.primary};
    }
`;

const LabelContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    cursor: ${({hasChildren}) => hasChildren ? 'pointer' : 'default'};
    &:hover {
        color: ${props => props.hasChildren ? props.theme.highlight : props.theme.black};
    }
    path {
        fill: ${({hasChildren, theme}) => hasChildren ? theme.primary : theme.gray600};
        ${({hasChildren, theme}) => hasChildren ? `
            stroke-width: 42px;
            stroke: ${theme.primary};
        ` : null}
    }
    &:hover path {
        fill: ${({hasChildren, theme}) => hasChildren && theme.highlight};
        stroke: ${({hasChildren, theme}) => hasChildren && theme.highlight};
    }
`;

const LabelContainerGroup = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
`;

const SegmentBlockItem = (props) => {
    const [open, setOpen] = useState(true);
    const hasChildren = props.segments && props.segments.length;
    const isSelected = props.type === 'companies' && !props.child ? true
        : props.selected && props.selected.find(sel => sel.code === props.code);

    const handleClick = () => {
        props.segments && props.segments.length && setOpen(!open);
    };

    const handleAdd = () => {
        const {id, code, name, isGranted, child, type} = props;
        if (!isGranted) return null;
        if (!child && type === 'companies') {
            return props.handleRemoveParent(props.code);
        }
        return isSelected ? props.handleRemove({code, name}) : props.handleAdd({id, code, name});
    };

    const opener = <OpenerContainer hasChildren={hasChildren} onClick={handleClick}>
        {hasChildren ?
            <FontAwesomeIcon
                icon={['fas', `chevron-${open ? 'up' : 'down'}`]}
                size="lg"
            /> : null }
    </OpenerContainer>;

    return (
        <Container sub={props.sub} data-cy="segment-block-item">
            <LabelContainerGroup>
                <LabelContainer
                    hasChildren={hasChildren}
                    data-cy="segment-block-expander"
                >
                    <div style={{display: 'flex'}}>
                        <CircleIcon data-cy="segment-block-add"
                                    onClick={handleAdd}
                                    checked={!!isSelected || (!props.child && props.type === 'companies')}
                                    empty={!isSelected}
                                    locked={!props.isGranted}
                        />
                        <span data-cy="segment-block-add-title">{props.name}</span>
                    </div>
                </LabelContainer>
                {opener}
            </LabelContainerGroup>
            {open ? props.segments && props.segments.map(seg => <SegmentBlockItem
                child
                type={props.type}
                selected={props.type === 'segments' || props.child
                    ? props.selected
                    :  props.selected.find(s => s.code === props.code)
                        ? props.selected.find(s => s.code === props.code).selectedSegments
                        : []
                }
                handleAdd={props.handleAdd}
                handleRemove={props.handleRemove}
                key={seg.code} {...seg} sub
            />) : null}
        </Container>
    )
};

SegmentBlockItem.propTypes = {
    handleAdd: PropTypes.func,
    handleRemove: PropTypes.func,
    handleRemoveParent: PropTypes.func
};

export default SegmentBlockItem;
