import * as actionTypes from './actionTypes';
import qs from 'qs';

export const initSetup = params => dispatch => {
    const type = qs.parse(params).type;
    if (type === 'bubble') {
        return dispatch(initBubble(params));
    } else {
        dispatch({
            type: actionTypes.SET_CHART_VIEW,
            val: {
                value: 'line',
                name: 'Line'
            }
        });
        const dispatchAfter = type === 'line-companies' ? initCompaniesCompanies : initSegments;
        return dispatch({
            type: actionTypes.LOAD_METRICS_START,
            meta: {
                api: {
                    method: 'GET',
                    endpoint: 'api/metrics',
                    dispatchAfter,
                    dispatchAfterParams: params
                }
            },
            init: true
        })
    }
};

export const initCompaniesCompanies = params => dispatch => {

    dispatch({
        type: actionTypes.SET_SELECTION_TYPE,
        groupBy: {name: 'Companies', value: 'companies'},
        init: true
    });

    const companyCodes = qs.parse(params).combinations
        .split(';')
        .map(c => c.split(':')[0])
        .filter((v, i, s) => s.indexOf(v) === i)
    ;

    dispatch({
        type: actionTypes.LOAD_COMPANIES_START,
        meta: {
            api: {
                method: 'GET',
                endpoint: 'api/companies',
                dispatchAfter: initAddCompany,
                dispatchAfterParams: {params, remainingCompanyCodes: companyCodes}
            }
        }
    })
};

export const initAddCompany = params => {

    const company = params.remainingCompanyCodes[0];
    const remainingCompanyCodes = params.remainingCompanyCodes.filter((r, i) => i !== 0);
    const dispatchAfter = remainingCompanyCodes.length ? initAddCompany : initAddSegmentToCompany;
    const dispatchAfterParams = remainingCompanyCodes.length
        ? {...params, remainingCompanyCodes: remainingCompanyCodes} : params.params;

    return {
        type: actionTypes.ADD_COMPANY_START,
        meta: {
            api: {
                method: 'GET',
                endpoint: `api/companies/${company}/segments`,
                dispatchAfter,
                dispatchAfterParams
            }
        },
        code: company
    }
};

export const initAddSegmentToCompany = params => {
    return {
        type: actionTypes.ADD_SEGMENTS_TO_COMPANIES,
        params
    }
};

export const initSegments = params => ({
    type: actionTypes.LOAD_SEGMENTS_START,
    meta: {
        api: {
            method: 'GET',
            endpoint: 'api/segments',
            dispatchAfter: initCompanies,
            dispatchAfterParams: params
        }
    }
});

const initCompanies = params => ({
    type: actionTypes.LOAD_COMPANIES_START,
    meta: {
        api: {
            method: 'GET',
            endpoint: 'api/companies',
            dispatchAfter: completeInitSetup,
            dispatchAfterParams: params
        }
    }
});

export const initBubble = params => (dispatch, getState) => {
    dispatch({
        type: actionTypes.SET_CHART_VIEW,
        val: {value: 'bubble', name: 'Bubble'}
    });
    const {bubbleTemplates} = getState().selection;
    if (bubbleTemplates.length) {
        return dispatch(initBubbleSelection(params));
    } else {
        return dispatch({
            type: actionTypes.LOAD_BUBBLE_TEMPLATES_START,
            meta: {
                api: {
                    method: 'GET',
                    endpoint: 'api/bubble-templates',
                    dispatchAfter: initBubbleSelection,
                    dispatchAfterParams: params
                }
            }
        })
    }
};

export const initBubbleSelection = (params) => (dispatch, getState) => {
    const {bubbleTemplates} = getState().selection;
    const paramObject = qs.parse(params);

    const activeTemplate = bubbleTemplates.find(t => +t.id === +paramObject.id);
    dispatch({
        type: actionTypes.SET_BUBBLE_LOB,
        lob: activeTemplate.lob
    });

    dispatch({
        type: actionTypes.SET_BUBBLE_SEGMENT,
        segment: activeTemplate.segment
    });

    dispatch({
        type: actionTypes.SET_BUBBLE_TEMPLATE,
        template: activeTemplate
    });

    return dispatch({
        type: actionTypes.LOAD_COMPANIES_FOR_TEMPLATE_START,
        meta: {
            api: {
                method: 'GET',
                endpoint: `api/bubble-templates/${activeTemplate.id}/companies`,
                dispatchAfter: initBubbleCompanies,
                dispatchAfterParams: params
            }
        }
    })
};

export const initBubbleCompanies = params => {
    const companyCodes = qs
        .parse(params).combinations
        .split(';')
        .map(c => c.split(':')[0])
        .filter((v, i, s) => s.indexOf(v) === i)
    ;
    return {
        type: actionTypes.ADD_BUBBLE_COMPANIES,
        companyCodes
    }
};

export const completeInitSetup = params => dispatch => {

    return dispatch({
        type: actionTypes.INIT_SETUP,
        params
    })
};
