import React, {useState, useEffect} from 'react';
import Tooltip from "rc-tooltip";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import styled from "@emotion/styled";
import {useSelector} from 'react-redux';
import Input from "../Input/Input";
import Button from "../Button/Button";
import Form from "@bit/gstanto.treehouse_tech.form";
import Validate from "@bit/gstanto.treehouse_tech.validate";
import * as actions from '../../store/actions/index';


const TipContent = styled.div`
    position: relative;
    top: 8px;
    padding: 0 18px;
`;

const SharingPanel = styled.div`
    position: absolute;
    right: -10px;
    height: 300px;
    width: 400px;
    background: white;
    padding: 24px 36px;
    border: 1px solid ${props => props.theme.gray500};
    z-index: 10;
    font-size: 14px;
    border-radius: 2px;
    box-shadow: ${props => props.theme.shadow2};
`;

const Title = styled.div`
    font-weight: 700;
    font-size: 16px;
    color: ${props => props.theme.primary};
    path {
        fill: ${props => props.theme.primary};
    }
`;

const Label = styled.div`
    margin-top: 12px;
    margin-bottom: 6px;
`;

const ChartShare = (props) => {
    const {dispatch} = props;
    const [entering, setEntering] = useState(false);
    const sharing = useSelector(state => state.auth.sharing);
    const shareComplete = useSelector(state => state.auth.shareComplete);

    const handleSubmit = (vals) => {
        const pack = {...vals, link: window.location.href};
        const {email, link, message, name} = pack;
        dispatch(actions.share(email, name, link, message));
    };

    useEffect(() => {
        if (!entering) {
            dispatch(actions.shareAgain());
        }
    }, [entering, dispatch]);

    return (
        <div style={{position: 'relative'}}>
            {entering ? <SharingPanel>
                <Title>
                    <FontAwesomeIcon icon={['fas', 'share-alt-square']} />
                    <span>Share with a colleague</span>
                </Title>
                {shareComplete ? <div>
                        <div style={{marginTop: 24, fontSize: 18}}>Your message has been sent</div>
                        <div style={{display: 'flex', marginTop: 24}}>
                            <Button onClick={() => setEntering(false)}>Close</Button>
                            <div style={{marginLeft: 12}}>
                                <Button onClick={() => dispatch(actions.shareAgain())}>Share Again</Button>
                            </div>
                        </div>
                </div> :
                    <Form fields={['email', 'name', 'message']} onSubmit={handleSubmit}>
                        {formApi => <>
                            <Validate formApi={formApi} required email>
                                <Label>Recipient Email</Label>
                                <Input
                                    placeholder="Email"
                                    name="email"
                                    value={formApi.values['email']}
                                    cy="input-email-share"
                                />
                            </Validate>
                            <Validate formApi={formApi} required>
                                <Label>Recipient Name</Label>
                                <Input
                                    placeholder="Name..."
                                    name="name"
                                    value={formApi.values['name']}
                                    cy="input-name-share"
                                />
                            </Validate>

                            <Validate formApi={formApi}>
                                <Label>Message</Label>
                                <Input
                                    placeholder="Message"
                                    name="message"
                                    value={formApi.values['message']}
                                    cy="input-message-share"
                                />
                            </Validate>

                            <div style={{marginTop: 28, display: 'flex'}}>
                                <Button loading={sharing.toString()} disabled={!formApi.formValid}>Send</Button>
                                <div style={{marginLeft: 6}}>
                                    <Button type="button" cancel onClick={() => setEntering(false)}>Cancel</Button>
                                </div>
                            </div>
                        </>
                        }
                    </Form>
                }
            </SharingPanel> : null}
            <Tooltip overlay={<TipContent>Share</TipContent>} placement="top" trigger={['hover']}>
                <div onClick={() => setEntering(true)}>
                    <FontAwesomeIcon icon={['fa', 'share-alt-square']}/>
                </div>
            </Tooltip>
        </div>
    )
};

export default ChartShare;
