export const getDashArray = i => {
    switch (i) {
        case 1:
            return '8 2';
        case 2:
            return '.35 10';
        case 3:
            return '14 4';
        case 6:
            return '24 4 4 4';
        default:
            return '';
    }
};

export const getCapStyle = i => {
    if (i === 2) {
        return 'round';
    } else {
        return '';
    }
};

export const getWidth = (i, isHover) => {
    if (isHover) {
        return 6;
    }

    switch(i) {
        case 2: return 3;
        case 4: return .4;
        case 5: return 1;
        default: return 1.2;
    }
};

export const isDouble = (i) => {
    return i === 4 || i === 5;
};
