import {saveAs} from 'file-saver';
import html2canvas from 'html2canvas';
import watermark from '../styles/watermark.png';

const margin = 36;
const ratio = .537;

export default async () => {
    const el = document.querySelector('#chart-div');

    html2canvas(el).then(canvas => {
        const width = canvas.getAttribute('width');
        const height = canvas.getAttribute('height');
        const watermarkWidth = width / 2;
        const waterMarkHeight = watermarkWidth * ratio;
        const waterMarkLeftMargin = (width / 2) - (watermarkWidth *.3);
        const waterMarkTopMargin = (height / 2) - (waterMarkHeight * .4);
        const ctx = canvas.getContext('2d');
        const imageData = ctx.getImageData(0,0, +width, +height);
        const fontSize = height / 44;
        console.log('font size: ', fontSize);
        ctx.clearRect(0, 0, +width, +height);
        canvas.width = +width + margin * 2;
        canvas.height = +height + margin * 2;
        ctx.fillStyle = 'white';

        // Move over margin distance
        ctx.fillRect(0,0,+width + margin * 2, +height + margin * 2);
        ctx.putImageData(imageData, margin, margin);

        ctx.fillStyle = '#1A4379';
        ctx.font = `${fontSize}px Helvetica`;
        ctx.textAlign = 'end';
        ctx.textBaseline = 'bottom';
        ctx.fillText('SOURCE: tbri.com', width - 2, height - 2);

        const img = new Image();
        img.onload = function() {
            ctx.globalAlpha = 0.10;
            ctx.drawImage(img, waterMarkLeftMargin, waterMarkTopMargin, watermarkWidth, waterMarkHeight);
            const data = canvas.toDataURL('image/png');
            save(data);
        };

        img.src = watermark;
    });

    function save(dataBlob) {
        saveAs(dataBlob, 'tbri_insight_center.png'); // FileSaver.js function
    }
}
