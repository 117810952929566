import React from 'react';
import CompanyListContainer from "./CompanyListContainer";
import PanelLoading from "../PanelLoading/PanelLoading";
import SegmentCompanyList from "./SegmentCompanyList";
import PropTypes from 'prop-types';
import useMatchFilter from "../useMatchFilter/useMatchFilter";

const CustomCompanyListSelector = (props) => {
    const [term, setTerm, list] = useMatchFilter(props.companyList);

    return props.openCompanyPanelCode === props.code ?
                <CompanyListContainer>
                    {props.isLoading
                        ? <PanelLoading text="Finding Companies..."/>
                        : <SegmentCompanyList
                            term={term}
                            setTerm={setTerm}
                            list={list}
                            onAddCompany={props.onAddCompany}
                            onClose={props.onClose}
                        />
                    }
                </CompanyListContainer> : null
        };


CustomCompanyListSelector.propTypes = {
    isLoading: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool
    ]),
    companyList: PropTypes.array,
    openCompanyPanelCode: PropTypes.string,
    onClose: PropTypes.func,
    code: PropTypes.string,
    onAddCompany: PropTypes.func,
};

export default CustomCompanyListSelector;
