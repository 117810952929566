import React from 'react';
import colors from "../../../styles/theme";

const trackStyle = {
    width: '100%',
    cursor: 'pointer',
    boxShadow: '1px 1px 1px rgba(0,0,0,0.05), 0 0 1px rgba(0,0,0,0.05)',
    background: colors.gray300,
    borderRadius: 2,
    position: 'absolute'
};

const Track = (props) => {
    return (
        <div style={{...trackStyle, width: props.width, top: props.top, height: props.height, left: props.left}} />
    )
};

export default Track;
