import PropTypes from 'prop-types';
import {format as d3Format} from 'd3-format';
import {timeFormat} from 'd3-time-format';

const TextFormat = (value, format, zeroCondition = false) => {

    let formatted = value;
    if (!value && zeroCondition) return zeroCondition;
    if (value === undefined) return value;
    if (format === 'money') {
        formatted = d3Format('$,.2r')(value);
    }

    if (format === 'money-approx') {
        formatted = d3Format('$~s')(value).replace('G', 'B');
    }

    if (format === 'count') {
        formatted = d3Format(',')(value);
    }
    if (format === 'dollars') {
        formatted = d3Format('$,')(value);
    }

    if (format === 'percent') {
        formatted = d3Format(',~%')(value);
    }
    if (format === 'percent-whole') {
        formatted = `${value}%`;
    }
    if (format === 'percent-plain') {
        formatted = `${Math.floor(value * 100)}`;
    }
    if (format === 'date') {
        if (Date.parse(value)) {
            const d = new Date(value);
            const formatDate = timeFormat('%b-%Y');
            formatted = formatDate(d);
        } else {
            formatted = '';
        }
    }
    return formatted;
};

TextFormat.propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    format: PropTypes.string
};

export default TextFormat;
