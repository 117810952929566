import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from '@emotion/styled';
import Input from "../components/Input/Input";
import Button from "../components/Button/Button";
import Form from "@bit/gstanto.treehouse_tech.form";
import Validate from "@bit/gstanto.treehouse_tech.validate";
import InputError from "../components/InputError/InputError";
import {Redirect} from "react-router";
import * as actions from '../store/actions/index';
import LoginLeft from "../components/Login/LoginLeft";
import LoginRightContainer from "../components/Login/LoginRightContainer";
import FormError from "../components/FormError";
import qs from 'qs';

const Wrapper = styled.div`
    display: flex;
    height: 100vh;
    &>div {
        width: 50%;
    }
`;

const Login = props => {
    const dispatch = useDispatch();
    const send = type => e => dispatch({type});
    const isAuth = useSelector(state => state.auth.isAuth);
    const error = useSelector(state => state.auth.error);
    const qString = qs.parse(props.location.search);
    const pathname = qString.type === 'bubble' ? '/bubble' : '/chart';
    const toLocation = props.location.search ? pathname + props.location.search : '/';

    if (isAuth) return <Redirect to={toLocation}/>;

    const handleSubmit = ({email, password}) => {
        dispatch(actions.login(email, password));
    };

    return (
        <Wrapper>
            <LoginLeft/>
            <LoginRightContainer
                send={send}
                error={<FormError cy="error-login-page" show={!!error}>{error}</FormError>}
            >
                <Form fields={['email', 'password']} required={['email', 'password']} onSubmit={handleSubmit}>
                    {formApi => (
                        <>

                            <div style={{
                                margin: `${formApi.touched['password'] && formApi.errors['password'] ? 46 : 36}px 0`,
                                position: 'relative'
                            }}>

                                {formApi.touched['email'] && formApi.errors['email'] &&
                                <InputError active={formApi.active === 'email'}>
                                    {formApi.errors['email'].type === 'required' ? 'Your email is required' : null}
                                    {formApi.errors['email'].type === 'email' ? 'Your username must be a valid email' : null}
                                </InputError>
                                }

                                <Validate formApi={formApi} required email>
                                    <Input
                                        placeholder="Email"
                                        name="email"
                                        value={formApi.values['email']}
                                        cy="input-email-login"
                                    />
                                </Validate>
                            </div>

                            <div style={{margin: '36px 0', position: 'relative'}}>

                                {formApi.touched['password'] && formApi.errors['password'] &&
                                <InputError active={formApi.active === 'password'}>
                                    {formApi.errors['password'].type === 'required' ? 'You must enter your password to login' : null}
                                </InputError>}

                                <Validate formApi={formApi} required>
                                    <Input
                                        placeholder="Password"
                                        type="password"
                                        name="password"
                                        value={formApi.values['password']}
                                        cy="input-password-login"
                                    />
                                </Validate>
                            </div>

                            <Button disabled={!formApi.formValid} cy="button-submit-login">
                                submit
                            </Button>
                        </>
                    )}
                </Form>
            </LoginRightContainer>
        </Wrapper>
    )
};

export default Login;
