import * as actionTypes from '../actions/actionTypes';

const initialState = {
    checked: false,
    isAuth: false,
    sharing: false,
    shareComplete: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.AUTH_FAIL:
            return {...state, isAuth: false, checked: true};

        case actionTypes.AUTH_SUCCESS:
            const {name, email} = action.user;
            return {...state,
                isAuth: true,
                checked: true,
                name,
                email
            };

        case actionTypes.LOGIN_SUCCESS:
            return {...state,
                name: action.name,
                email: action.email,
                isAuth: true,
                error: null
            };

        case actionTypes.LOGOUT_SUCCESS:
            return {...state, isAuth: false};

        case actionTypes.LOGIN_START:
            return {...state, error: null};

        case actionTypes.LOGIN_FAIL:
            return {...state, isAuth: false, error: action.error};

        case actionTypes.SHARE_CHART_START:
            return {...state, sharing: true};

        case actionTypes.SHARE_CHART_FAIL: {
            return {...state, sharing: false, error: action.error}
        }

        case actionTypes.SHARE_CHART_SUCCESS: {
            return {...state, sharing: false, shareComplete: true}
        }

        case actionTypes.SHARE_CHART_AGAIN: {
            return {...state, shareComplete: false}
        }

        default:
            return state;
    }
};
