import React from 'react';
import PropTypes from 'prop-types';
// Needs to be refactored to only return point index, x and y values
const DataMarkers = ({xScale, yScale, indices, d, hoveringId, activeTipId, setActive, valueKey}) => {

    return indices.map((v, j) => <circle
        key={j}
        stroke={d.color}
        fill={v.isProjection ? '#efefef' : d.color}
        strokeWidth="2"
        opacity={hoveringId && (hoveringId !== `${j}-${d.id}` && hoveringId !== d.id) ? .3 : 1}
        className="data-marker"
        cx={xScale(v.date)}
        cy={yScale(v[valueKey])}
        r={activeTipId === `${j}-${d.id}` ? 9 : hoveringId === d.id ? 6 : 4}
        onMouseOver={() => setActive({ // Everything but tipPosition can go
            showTip: true,
            tipPosition: {
                x: xScale(v.date),
                y: yScale(v[valueKey]),
                point: `${j}-${d.id}`,
                id: `${j}-${d.id}`,
                data: {...v, companyName: d.companyName, segmentName: d.segmentName}
            }
        })}
        onMouseOut={() => setActive({
            showTip: false,
            tipPosition: null
        })}
    >
    </circle>)};

DataMarkers.propTypes = {
    xScale: PropTypes.func.isRequired,
    yScale: PropTypes.func.isRequired,
    indices: PropTypes.array.isRequired,
    d: PropTypes.object.isRequired, // 'd' is a terrible name
    activeTipId: PropTypes.string,
    setActive: PropTypes.func.isRequired,
    valueKey: PropTypes.string
};

export default DataMarkers;
