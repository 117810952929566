import React, {useEffect} from 'react';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import {Redirect} from "react-router";
import * as actions from '../store/actions/index';

const Logout = () => {
    const isAuth = useSelector(state => state.auth, shallowEqual);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(actions.logout());
    }, [dispatch]);

    if (isAuth) return null;
    return (
        <Redirect to="/login" />
    )
};

export default Logout;
