import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import 'normalize.css';
import {createStore, applyMiddleware, combineReducers, compose} from 'redux';
import {Provider} from 'react-redux';
import thunk from 'redux-thunk';
import * as Sentry from '@sentry/browser';
import mixpanel from 'mixpanel-browser';
import trackMixpanel from './store/middlewares/trackMixpanel';
import * as serviceWorker from './serviceWorker';
import authReducer from '../src/store/reducers/auth';
import selectionReducer from '../src/store/reducers/selection';
import templateReducer from '../src/store/reducers/template';
import {BrowserRouter} from 'react-router-dom';
import api from '@bit/gstanto.treehouse_tech.api';
import tokenStorage from '../src/store/middlewares/tokenStorage';
import headers from '../src/store/middlewares/headers';
import dispatchAfter from '../src/store/middlewares/dispatchAfter';
import {ThemeProvider} from "emotion-theming";
import './index.scss';
import theme from './styles/theme';
import './styles/fontLibrary';
import AppBoundary from "./AppBoundary";

if (process.env.NODE_ENV === 'production') {
    mixpanel.init('f7b8ee61c62461267e776c58a3c3dae0');
    Sentry.init({dsn: 'https://edd4c7d2f3ce41a4af04108d22263af5@sentry.io/1792995'}); // Production panel
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
    auth: authReducer,
    selection: selectionReducer,
    template: templateReducer
});

const store = createStore(rootReducer, composeEnhancers(
    applyMiddleware(
        thunk,
        tokenStorage,
        headers,
        api,
        dispatchAfter,
        trackMixpanel
    )
));

if (window.Cypress) {
    window.store = store;
}

const app = (
    <Provider store={store}>
        <BrowserRouter>
            <ThemeProvider theme={theme}>
                <AppBoundary>
                    <App/>
                </AppBoundary>
            </ThemeProvider>
        </BrowserRouter>
    </Provider>
);

ReactDOM.render(app, document.getElementById('root'));


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
