import React from 'react';
import format from 'date-fns/format';
import styled from "@emotion/styled";

const Container = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    color: ${props => props.theme.gray500};
    font-weight: 400;
    font-size: 12px;
`;

const Label = styled.div`
    font-weight: 400;
    font-size: 12px;
    user-select: none;
    color: ${props => props.active ? props.theme.primary : props.theme.gray800};
    &:hover {
        cursor: pointer;
    }
`;
Label.displayName = 'TimelineLabel';

const QuadrantTimelineLabels = ({dates, setDate, time, quarterly}) => {
    const activeIndex = Math.round(time * (dates.length - 1));
    // Limit number of labels
    const isMaxed = Math.ceil(dates.length / 13);
    const datesMaxed = dates.filter((d, i) => i % isMaxed === 0);

    const handleSetDate = i => {
        setDate(dates.findIndex(d => datesMaxed[i] === d));
    };

    return (
        <Container>
            {datesMaxed.map((date, i) => (
                <Label
                    key={i}
                    active={activeIndex === i}
                    data-cy="label-timeline-date"
                    onClick={() => handleSetDate(i)}>
                    {format(date, quarterly ? 'yyyy-QQQ' : 'MMM-yyyy')}
                </Label>
            ))}
        </Container>
    )
};

export default QuadrantTimelineLabels;
