import React from 'react';
import PropTypes from 'prop-types';
import styled from "@emotion/styled";
import BarLoader from "../BarLoader/BarLoader";

const LoadingText = styled.div`
    margin-bottom: 12px;
    color: ${props => props.theme.primaryDark};
`;

const PanelLoading = (props) => {
    return (
        <div style={{position: 'relative'}}>
            <LoadingText>{props.text}</LoadingText>
            <BarLoader show />
        </div>
    )
};

PanelLoading.propTypes = {
    text: PropTypes.string
};

export default PanelLoading;
