import React from 'react';
import styled from '@emotion/styled';

const Button = styled.button`
    background: ${props => props.active ? props.theme.primary : props.theme.gray300};
    border-radius: 2px;
    padding: ${props => props.theme.pad1};
    border: none;
    font-size: 12px;
    margin: 4px;
    color: ${({active, theme}) => active ? 'white' : theme.gray600};
    cursor: pointer;
    &:hover {
        background: ${({active, theme}) => active ? theme.primaryDark : theme.gray400};
    }
`;

const DateRangeButtonsGroup = (props) => {
    return (
        <div style={{marginTop: -2}}>
            <Button
                cy="button-set-date-quarter"
                onClick={() => props.setDates('oneYear')} left
                active={props.active === 'oneYear'}
            >1 Year</Button>
            <Button
                cy="button-set-date-quarter"
                onClick={() => props.setDates('threeYear')} left
                active={props.active === 'threeYear'}
            >3 Years</Button>

            <Button
                cy="button-set-date-year"
                onClick={() => props.setDates('fiveYear')} middle
                active={props.active === 'fiveYear'}
            >5 Years</Button>

            <Button
                cy="button-set-date-all"
                onClick={() => props.setDates('all')} right
                active={props.active === 'all'}
            >All Time</Button>

            {props.period === 'annual'
                ?
                <Button
                    cy="button-set-date-all"
                    onClick={() => props.setDates('forecasts')} right
                    active={props.active === 'forecasts'}
                >Forecasts</Button>
                : null}
        </div>
    )
};

export default DateRangeButtonsGroup;
