import React, {useRef, useEffect} from 'react';
import XAxis from "../axes/XAxis";
import YAxis from "../axes/YAxis";
import colors from "../../styles/chartColors";
import DataMarkers from "../DataMarkers/DataMarkers";
import {extent} from "d3-array";
import {scaleLinear, scaleTime} from "d3-scale";
import {select, selectAll} from "d3-selection";
import theme from "../../styles/theme";
import {curveCatmullRom, curveLinear, line} from "d3-shape";
import {getCapStyle, getWidth, getDashArray} from '../utilities/lineStyles';
import {d3FormatCode} from "../../utilities/formatter";

const LineMultiAxis = ({data, chartVis, width, height, margin, dateRange, displayedNames, curve, valueKey = 'value', period}) => {
    let xScale = null;
    let yScales = {};

    let svgRef = useRef(null);
    let topBorder = useRef(null);
    let lines = useRef(null);

    data.forEach(series => {
        if (!series.values.length) return;
        const existingValues = yScales[series.name] ? yScales[series.name].scale.domain() : [];
        const newValues = series.values.map(d => d[valueKey]);
        const valueRange = extent([...newValues, ...existingValues]);

        let padding = (valueRange[1] - valueRange[0]) * .05;
        if (!padding) padding = valueRange[0] * .05;
        const paddedValueRange = [valueRange[0] - padding, valueRange[1] + padding];

        yScales[series.name] = {
            name: series.name,
            id: series.id.split('_').filter((v, i, s) => i === s.length -1)[0],
            tickFormat: d3FormatCode(series),
            scale: scaleLinear()
                .domain(paddedValueRange)
                .range([height - margin.bottom, margin.top])
        };
    });

    xScale = scaleTime()
        .domain(dateRange)
        .range([margin.left * Object.keys(yScales).length, width - margin.right]);

    const yearBands = Array(dateRange[1].getYear() - dateRange[0].getYear()).fill(null).map((y, i) => {
            const jan1 = new Date((1900 + dateRange[0].getYear() + i) + '-01-01');
            const dec31 = new Date((1900 + dateRange[0].getYear() + i) + '-12-31');
            const xPos = +xScale(jan1).toFixed(2);
            const numScales = Object.keys(yScales).length;
            const xPosAdjusted = xPos < margin.left * numScales ? margin.left * numScales : xPos;
            return {
                x: xPosAdjusted,
                width: +(xScale(dec31) - xPosAdjusted),
                isOdd: (dateRange[0].getYear() + i) % 2 === 0
            }
        }
    );

    useEffect(() => {
        const draw = () => {
            if (!data.length) return;

            select(topBorder.current)
                .attr('x1', xScale(dateRange[0])).attr('x2', width - margin.right + 1)
                .attr('y1', margin.top).attr('y2', margin.top)
                .attr('stroke-width', 1).attr('stroke', theme.gray400);

            // Clean up axes and lines before drawing them again
            selectAll('path.multi-line').remove();

            // Individual lines with own axis values
            data.forEach(series => {

                const valueLine = line()
                    .x(d => xScale(d.date))
                    .y(d => yScales[series.name].scale(d[valueKey]))
                    .curve(curve === 'curve' ? curveCatmullRom : curveLinear);

                let opacity = (chartVis.pumped && series.id !== chartVis.pumped) ? .3 : 1;

                select(lines.current).append('path')
                    .data([series.values])
                    .attr('class', 'multi-line')
                    .attr('fill', 'none')
                    .attr('stroke', series.color)
                    .attr('opacity', opacity)
                    .attr('stroke-dasharray', getDashArray(series.lineStyle))
                    .attr('stroke-width', getWidth(series.lineStyle, series.id === chartVis.pumped))
                    .attr('stroke-linecap', getCapStyle(series.lineStyle))
                    .attr('d', valueLine);
            });
        };
        draw();
    });

    const drawMarkers = series => {
        if (yScales[series.name]) {
            return <DataMarkers
                key={series.id}
                valueKey={valueKey}
                d={series}
                xScale={xScale}
                yScale={yScales[series.name].scale}
                indices={series.values}
                setActive={vals => chartVis.showTip(vals.tipPosition)}
                activeTipId={chartVis.tip && chartVis.tip.id}
                hoveringId={chartVis.pumped}
            />
        } else return null;
    };

    return (
        <svg className='multi-line' ref={svgRef} height={height} width={width} style={{position: 'absolute'}}>
            {yearBands.map(y => y.isOdd ?
                <rect key={y.x} x={y.x} width={y.width} y={margin.top} height={height - margin.top - margin.bottom} fill="#efefef" opacity={.4}/> : null)}

            <g ref={lines}/>
            <XAxis height={height} margin={margin} xScale={xScale} dateRange={dateRange} period={period}/>
            {Object.entries(yScales).map(en => en[1]).map((ys, i) => (
                <YAxis
                    key={i}
                    yScale={ys.scale}
                    id={ys.id}
                    chartVis={chartVis}
                    margin={margin}
                    height={height}
                    axisWidth={margin.left}
                    name={ys.name}
                    colorIndex={data.findIndex(d => d.id === ys.id)}
                    colors={colors}
                    tickFormat={ys.tickFormat}
                    i={i}
                />
            ))}

            <line ref={topBorder}/>

            {xScale ? data.map(series => drawMarkers(series)) : null}
        </svg>
    )
};

LineMultiAxis.defaultProps = {
    width: 800, height: 500
};

export default LineMultiAxis;
