import React from 'react';
import styled from "@emotion/styled";

const getHoverBackground = props => {
    return props.value && !props.open ? props.theme.primaryDark : props.open ? 'white' : props.theme.primary
};

const getBackground = props => {
    return props.value && !props.open ? 'white' : props.open ? props.theme.gray300 : 'white';
    // return props.value && !props.open ? props.theme.primary : props.open ? props.theme.gray300 : 'white';
};

const getColor = props => {
    return props.value && !props.open ? props.theme.primary : props.open ? props.theme.gray400 : props.theme.gray800;
};

const getBorderColor = props => {
    return props.value ? props.theme.primary : props.theme.gray400;
};

const getBorderStyle = props => {
    return props.value || props.open ? 'solid' : 'solid';
};

const Container = styled.div`
    height: 24px;
    display: flex;
    align-items: center;
    padding: 8px 12px;
    border: 1px ${getBorderStyle} ${getBorderColor};
    border-radius: 4px;
    cursor: pointer;
    background: ${getBackground};
    color: ${getColor};
    &:hover {
        background: ${getHoverBackground};
        color: ${props => props.open ? props.theme.gray600 : 'white'};
    }
`;

const DropdownableContainer = (props) => {
    return (
        <Container
            data-cy={props.cy}
            value={props.value}
            open={props.open}
            onClick={props.onClick}
        >{props.children}</Container>
    )
};

export default DropdownableContainer;
