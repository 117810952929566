import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const Container = styled.label`
    display: block;
    position: relative;
    padding-left: 24px;
    margin-bottom: 12px;
    margin-right: 12px;
    font-size: 14px;
    user-select: none;
    cursor: pointer;
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }
    &:hover input ~ span {
        background: ${props => props.theme.gray100};
    }
    input:checked ~ span {
        background: ${props => props.theme.primary};
        border: none;
    }
    input:checked ~ span:after {
        display: block;
        border: 2px solid white;
    }
    span:after {
        top: 1px;
        left: 1px;;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background: transparent;
        box-sizing: border-box;
    }
`;

const Checkmark = styled.span`
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    border-radius: 50%;
    box-sizing: border-box;
    border: 1px solid ${props => props.theme.gray400};
    &:after {
        content: '';
        position: absolute;
    }
`;

const RadioButton = (props) => {
    return (
        <Container>{props.label}
            <input type="radio" checked={props.checked} name={props.name} onChange={props.onChange} value={props.value}/>
            <Checkmark/>
        </Container>
    )
};

RadioButton.propTypes = {
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
};

export default RadioButton;
