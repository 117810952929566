import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import QuadrantTimelineHandle from "./QuadrantTimelineHandle";
import QuadrantTimelineLabels from "./QuadrantTimelineLabels";

const Wrapper = styled.div`
    width: 100%;
    padding-bottom: 8px;
    max-width: calc(100% - 24px);
`;

const SlideTray = styled.div`
    position: relative;
    background: ${props => props.theme.gray200};
    height: 6px;
    border-radius: 4px;
    width: 100%;
    margin-top: 12px;
`;

const SlideTrayStatus = styled.div`
    position: absolute;
    background: ${props => props.theme.primary};
    height: 5px;
    left: 0;
    top: 0;
    width: ${props => props.width + 4}px;
    border-radius: 4px;
`;

const QuadrantTimeline = (props) => {
    const {dates, time} = props;
    const widthRef = useRef(null);

    return (
        <Wrapper>
            <QuadrantTimelineLabels {...props} />
            <SlideTray ref={widthRef}>
                <SlideTrayStatus width={widthRef.current ? widthRef.current.getBoundingClientRect().width * time : 1000 * time} />
                {widthRef.current &&
                <QuadrantTimelineHandle
                    fullWidth={widthRef.current.getBoundingClientRect().width}
                    length={dates.length}
                    time={time}
                />
                }
            </SlideTray>
        </Wrapper>
    )
};

QuadrantTimeline.defaultProps = {
    dates: [],
    time: 0
};

QuadrantTimeline.propTypes = {
    dates: PropTypes.array.isRequired,
    time: PropTypes.number,
    quarterly: PropTypes.bool
};

export default QuadrantTimeline;
