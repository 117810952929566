import { useState, useEffect } from 'react';

const useChartVisibility = (initialState = {muted: {}}) => {
    const [muted, setMuted] = useState({});
    const [tip, showTip] = useState(null);
    const [pumped, setPumped] = useState(null);

    useEffect(() => {
        // setMuted(initialState.muted);
    }, [initialState]);

    function addMuted(val) {
        setMuted({...muted, [val]: true});
    }

    function removeMuted(val) {
        if (muted[val]) {
            const mutedCopy = {...muted};
            delete mutedCopy[val];
            setMuted(mutedCopy);
        }
    }

    const tempShowTip = (val) => {
        showTip(val);
    };

    const handlePumped = val => {
        setPumped(val);
    };

    return {
        muted: muted,
        addMuted: addMuted,
        removeMuted: removeMuted,
        showTip: tempShowTip,
        pumped: pumped,
        setPumped: handlePumped,
        tip: tip
    };
};

export default useChartVisibility;
