import React from 'react';
import Input from "../Input/Input";
import FancyScroll from "../FancyScroll/FancyScroll";
import theme from "../../styles/theme";
import ClickList from "../ClickList/ClickList";
import styled from "@emotion/styled";
import Button from "../Button/Button";
import PropTypes from 'prop-types';

const CompanyList = styled.div`
    height: 360px;
    background: white;
    padding-right: 12px;
`;

const TitleContainer = styled.div`
    margin-bottom: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const SegmentCompanyList = (props) => {
    const {term, setTerm, list, onAddCompany, onClose} = props;

    return (
        <>
            <TitleContainer>
                <div style={{fontWeight: 700}}>Add companies</div>
                <div><Button cy="close-company-panel" onClick={onClose}>Done</Button></div>
            </TitleContainer>
            <div style={{marginBottom: 18}}><Input placeholder="refine..." value={term} onChange={e => setTerm(e.target.value)} /></div>
            <FancyScroll color={theme.gray300}>
                <CompanyList data-cy="company-list-container">
                    <ClickList items={list} onClick={onAddCompany} cy="company-list-item" />
                </CompanyList>
            </FancyScroll>
        </>
    )
};

SegmentCompanyList.propTypes = {
    term: PropTypes.string,
    setTerm: PropTypes.func,
    list: PropTypes.array,
    onAddCompany: PropTypes.func,
    onClose: PropTypes.func
};

export default SegmentCompanyList;
