import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import CircleIcon from "../Icons/CircleIcon/CircleIcon";

const Item = styled.div`
    background: ${({active, theme, locked, noIcons}) => noIcons ? 'white' : locked && active ? theme.gray400 : locked ? theme.gray300 : active ? theme.primary : 'white'};
    padding: ${props => props.tight ? '4px 12px' : props.theme.pad1};
    padding-right: 0;
    color: ${({active, theme, locked}) => locked ? theme.gray600 : active ? 'white' : theme.gray900};
    margin: 2px;
    margin-bottom: ${props => props.tight ? 8 : 12}px;
    cursor: pointer;
    border-radius: 2px;
    display: flex;
    justify-content: space-between;
    font-size: 15px;
    &:hover {
        box-shadow: ${props => props.outline ? props.theme.outline : props.theme.shadow2};
        div {
            border-color: ${({active, theme, locked}) => locked ? 'transparent' : active ? 'white' : theme.highlight};      
        }
    }    
`;

const ClickList = (props) => {
    const {items, active, selectedCodes, icons, withLocked, onClick, tight} = props;

    return items.map((item, i) => <Item
            data-cy={props.cy}
            tight={tight}
            locked={withLocked && !item.isGranted}
            noIcons={!icons}
            active={active.find(sel => item.code === sel.code)}
            outline
            key={item.code + i || item.id}
            onClick={() => onClick(item)}
        >
            <div style={{fontWeight: 700}}>
                {item[props.displayProperty || 'name']}
            </div>
            {icons ?

                <CircleIcon
                    circle
                    selected={!!active.find(sel => item.code === sel.code)}
                    locked={withLocked && !item.isGranted}
                    checked={!!(selectedCodes && selectedCodes.find(s => s === item.code))}
                />
                : null}
        </Item>
    )
};

ClickList.defaultProps = {
    active: []
};

ClickList.propTypes = {
    withLocked: PropTypes.bool,
    items: PropTypes.array.isRequired,
    onClick: PropTypes.func,
    displayProperty: PropTypes.string,
    cy: PropTypes.string,
    active: PropTypes.array
};

export default ClickList;
