import React from 'react';
import Input from "../Input/Input";
import format from 'date-fns/format';

const DateFilterDisplay = ({set, minDisplay, maxDisplay}) => {
    return (
        <div style={{
            marginRight: 12,
            // width: 300,
            display: 'flex'
        }} className="date-inputs" onClick={set}>
            <Input
                onChange={() => console.log('change date, overridden')}
                value={format(minDisplay, 'yyyy-QQQ')}
                small
            />

            <span style={{padding: '0 8px'}}>to</span>

            <Input
                onChange={() => console.log('change date, overridden')}
                value={format(maxDisplay, 'yyyy-QQQ')}
                small
            />
        </div>
    )
};

export default DateFilterDisplay;
