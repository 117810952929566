import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as actions from '../store/actions/index';
import styled from '@emotion/styled';
import TemplateChip from "../components/TemplateChip/TemplateChip";
import EmptyTemplates from "../components/EmptyTemplates/EmptyTemplates";
import BarLoader from "../components/BarLoader/BarLoader";
import {Link} from "react-router-dom";
import Button from "../components/Button/Button";
import qs from 'qs';

const Span = styled.span`
    padding-left: 6px;
    font-size: 14px;
    font-weight: 400;
`;

const H3 = styled.h3`
    margin-top: 72px;
    font-weight: 400;
`;

const Home = (props) => {
    const dispatch = useDispatch();
    const {list, loading, loaded} = useSelector(state => state.template);

    useEffect(() => {
        dispatch(actions.loadTemplates())
    }, [dispatch]);

    const chartDest = params => qs.parse(params).type === 'bubble' ? 'bubble' : 'chart';

    return (
        <div>
            <h2 data-cy="title-home">Welcome to the Insight Center <Span>beta</Span></h2>

            <div style={{marginBottom: 18}}>
                <Link to="/comparison-analytics">
                    <Button cancel>Create chart</Button>
                </Link>
            </div>
            <H3>Your templates</H3>
            <div style={{display: 'flex', marginBottom: 12, flexWrap: 'wrap'}}>
                {!list.length && loaded
                    ? <EmptyTemplates/>
                    : list.map(item => item ? <TemplateChip
                        key={item.id}
                        template={item}
                        deleteTemplate={() => dispatch(actions.deleteTemplate(item.id))}
                        editTemplate={() => props.history.push(`/comparison-analytics?${item.parameters}`)}
                        viewChart={() => props.history.push(`/${chartDest(item.parameters)}?${item.parameters}`)}
                    /> : null) }
            </div>

            <BarLoader show={loading} />

        </div>
    )
};

export default Home;
