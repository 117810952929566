import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import SegmentBlockItem from "./SegmentBlockItem";

const Wrapper = styled.div`
    padding-right: 4px;
    margin-bottom: 12px;
`;

const Container = styled.div`
    background: white;
    padding-left: 6px;
`;

const SegmentSelectBlock = (props) => {

    return (
            <Wrapper>
                <Container>
                    <SegmentBlockItem {...props} />
                </Container>
            </Wrapper>
    )
};

SegmentSelectBlock.propTypes = {
    code: PropTypes.string,
    name: PropTypes.string,
    isGranted: PropTypes.bool,
    segments: PropTypes.array,
    selected: PropTypes.array,
    type: PropTypes.string
};

export default SegmentSelectBlock;
