import React, {useEffect} from 'react';
import Layout from "./components/Layout/Layout";
import {shallowEqual, useSelector, useDispatch} from 'react-redux';
import {Route, Switch, withRouter} from "react-router";
import Login from "./Pages/Login";
import * as actions from './store/actions/index';
import Logout from "./Pages/Logout";

function App({history, location}) {
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth, shallowEqual);

    useEffect(() => {
        dispatch(actions.checkAuth());
    }, [dispatch]);

    useEffect(() => {
        if (auth.isAuth === false && auth.checked && location.pathname !== '/login') {
            history.push('/login' + location.search);
        }
    }, [auth, history, location]);

    return (
        <div className="App">
            {auth.checked ?
                <Switch>
                    <Route path="/login" exact component={Login} />
                    <Route path="/logout" exact component={Logout} />
                    <Route path="/" component={Layout} />
                </Switch> : null
            }
        </div>
    );
}

export default withRouter(App);
