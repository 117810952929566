import axios from 'axios';
const devApi = 'http://tbr-insight-center-api-staging.us-east-2.elasticbeanstalk.com';

const base = process.env.NODE_ENV === 'test'
    ? 'http://test.com/'
    : process.env.REACT_APP_API_ENDPOINT;

export default axios.create({
    baseURL: base || devApi
});
