import React, {Component} from 'react';
import * as Sentry from '@sentry/browser';
import ErrorOnPage from "./components/ErrorOnPage/ErrorOnPage";

class AppBoundary extends Component {
    state = {
        error: null,
        eventId: null
    };

    componentDidCatch(error, errorInfo) {
        if (process.env.NODE_ENV === 'production') {
            this.setState({error});
            Sentry.withScope(scope => {
                scope.setExtras(errorInfo);
                const eventId = Sentry.captureException(error);
                this.setState({eventId});
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.error && !prevState.error) {
            setTimeout(() => {
                window.location.reload();
            }, 8000);
        }
    }

    render() {
        if (this.state.error) {
            return (
                <div>
                    <ErrorOnPage show text="Oops. Something went wrong. Technical support has been notified." />
                    <button onClick={() => Sentry.showReportDialog({ eventId: this.state.eventId })}>Report feedback</button>
                </div>
            );
        } else {
            return this.props.children;
        }
    }
}

export default AppBoundary;
