import React from 'react';

const BubbleIcon = (props) => {
    return (
        <svg fill="none" height={25} width={26} viewBox="0 0 25.63 24.31">
                <polyline points="0.63 0.63 0.63 21.35 25 21.35"/>
                <line x1="22.55" y1="23.68" x2="25" y2="23.68"/>
                <line x1="14.86" y1="23.68" x2="20.02" y2="23.68"/>
                <line x1="7.28" y1="23.68" x2="13.3" y2="23.68"/>
                <line x1="5.15" y1="23.68" x2="5.92" y2="23.68"/>
                <line x1="0.63" y1="23.68" x2="2.88" y2="23.68"/>
                <circle cx="13.39" cy="8.69" r="6.11"/>
                <circle cx="22.89" cy="4.37" r="1.19"/>
                <circle cx="21.14" cy="15.47" r="2"/>
                <path d="M10.81,14.3a3.12,3.12,0,1,1-3.12-3.12"/>
        </svg>
    )
};

export default BubbleIcon;

