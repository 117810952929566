import React from 'react';
import styled from '@emotion/styled';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PropTypes from 'prop-types';

const Container = styled.div`
    margin: 8px;
    font-size: 20px;
    path {
        stroke: white;
        stroke-width: 1.8pc;
        fill: ${props => props.theme.gray500};
    }
`;

const ArrowColumn = (props) => {
    return (
        <Container>
            <FontAwesomeIcon icon={['fas', `chevron-${props.left ? 'left' : 'right'}`]} size="lg"/>
        </Container>
    )
};

ArrowColumn.propTypes = {
    left: PropTypes.bool
};

export default ArrowColumn;
