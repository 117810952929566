import axios from '../../axios';
import * as actionTypes from "../actions/actionTypes";

const headers = store => next => action => {
    if (action.meta && action.meta.api) {
        if (action.meta.api.public) {
            return next({...action, apiClient: axios});
        } else {
            const user = JSON.parse(localStorage.getItem('user'));

            if (!user) {
                return next({type: actionTypes.AUTH_FAIL});
            }

            axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.token;
            return next({...action, apiClient: axios});
        }

    } else {
        return next(action);
    }
};

export default headers;
