export default [
    '#a1c62c',
    '#4778b3',
    '#cd5715',
    '#de9600',
    '#5d4698',
    '#808000',
    '#40a5d7',
    '#a25250',
    '#004c00',
    '#ffc979',
    '#5b5847',
];
