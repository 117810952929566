const useQsMaker = (selected, type='line-segments') => {

    if (!selected || (Object.entries(selected).length === 0 && selected.constructor === Object)) return null;

    const parent = type === 'line-segments' ? 'segments' : 'companies';
    const children = type === 'line-companies' ? 'selectedSegments' : 'selectedCompanies';

    if (type.includes('line')) {
        const mapped = selected[parent].reduce((acc, par) => {
            par[children].forEach(chi => {
                chi.metrics.forEach(m => {
                    if (parent === 'companies') {
                        acc = acc + par.code + ':' + chi.code + ':' + m.code + ';';
                    } else {
                        acc = acc + chi.code + ':' + par.code + ':' + m.code + ';';
                    }
                });
            });
            return acc;
        }, '');
        return 'combinations=' + mapped.slice(0, -1) + '&type=' + type;
    } else {
        const {sizeSegment, sizeMetric, xSegment, xMetric, ySegment, yMetric, id} = selected.template;
        const qs = selected.companies.reduce((acc, curr) => {
            acc += `${curr.code}:${sizeSegment.code}:${sizeMetric.code}:${sizeMetric.valueType};`;
            acc += `${curr.code}:${xSegment.code}:${xMetric.code}:${xMetric.valueType};`;
            acc += `${curr.code}:${ySegment.code}:${yMetric.code}:${yMetric.valueType};`;
            return acc;
        }, '');
        const cleaned = qs.slice(0, -1);
        let acc = `&xAxis=${xSegment.code}:${xMetric.code}:${xMetric.valueType}`;
        acc += `&yAxis=${ySegment.code}:${yMetric.code}:${yMetric.valueType}`;
        acc += `&size=${sizeSegment.code}:${sizeMetric.code}:${sizeMetric.valueType}`;
        return 'combinations=' + cleaned + acc + '&type=' + type + '&id=' + id;
    }
};

export default useQsMaker;
