import React from 'react';
import styled from '@emotion/styled';
import UserMenu from './UserMenu/UserMenu';
import TBRLogo from "../Icons/TBRLogo";
import LeftMenuItem from "./LeftMenuItem/LeftMenuItem";
import Content from "../Content";
import HoverMenuItem from "../Menus/HoverMenuItem";
import {useSelector} from 'react-redux';
import {Link} from "react-router-dom";

const TopMenu = styled.div`
    color: ${props => props.theme.primary};
    background: white;
    padding: 12px 32px;
    display: flex;
    justify-content: space-between;
    height: 60px;
    align-items: center;
    box-sizing: border-box;
    box-shadow: 0 0px 4px 2px ${props => props.theme.gray200}; 
`;

const LeftMenu = styled.div`
    width: 220px;
    background: ${props => props.theme.primary900};
    min-height: calc(100vh - 60px);
    padding-top: 24px;
    font-size: 14px;
    box-sizing: border-box;
    position: relative;
    flex-shrink: 0;
    align-items: stretch;
`;

const Layout = (props) => {
    const auth = useSelector(state => state.auth);

    return (
        <div>
            <TopMenu>
                <div data-cy="logo-tbr-dashboard"><Link to="/"><TBRLogo /></Link></div>
                    <UserMenu light showName name={auth.name}>
                        <div style={{padding: '16px 0'}}>
                            <HoverMenuItem cy="link-change-password" to="/change-password">Change Password</HoverMenuItem>
                            <HoverMenuItem cy="link-logout" to="/logout">Logout</HoverMenuItem>
                        </div>
                    </UserMenu>
            </TopMenu>
            <div style={{display: 'flex'}}>
                <LeftMenu>
                    <div>
                        <LeftMenuItem to="/">Home</LeftMenuItem>
                        <LeftMenuItem to="/comparison-analytics">Insights & Analytics</LeftMenuItem>
                    </div>
                </LeftMenu>
                <div id="content-container" style={{width: '100%'}}>
                    <Content />
                </div>
            </div>

        </div>
    )
};

export default Layout;
