import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const Container = styled.div`
    background: ${({active, theme}) => active ? theme.primary : theme.gray200};
    padding: 6px 8px;
    border-radius: 6px;
    display: inline-block;
    cursor: pointer;
    svg {
        fill: none;
    }
    polyline, line {
        fill: none;
        stroke: ${({active, theme}) => active ? 'white' : theme.primary};
    }
    circle {
        fill: ${({active, theme}) => active ? 'white' : theme.primary};
    }
    &:hover {
        background: ${({active, theme}) => active ? theme.primaryDark : theme.gray300};
        polyline, line {
            fill: none;
            stroke: ${({active, theme}) => active ? 'white' : theme.primaryDark};
    }
        circle {
            fill: ${({active, theme}) => active ? 'white' : theme.primaryDark};
    }
    }
`;

const IconButton = (props) => {
    const {active, onClick, cy} = props;
    return (
        <Container data-cy={cy} active={active} onClick={onClick}>{props.children}</Container>
    )
};

IconButton.propTypes = {
    active: PropTypes.bool,
    onClick: PropTypes.func
};

export default IconButton;
