import React, {useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import {scaleLinear} from "d3-scale";
import {axisRight, axisBottom} from "d3-axis";
import {select} from 'd3-selection';
import styled from '@emotion/styled';
import theme from '../../styles/theme';
import {label, d3FormatCode} from '../../utilities/formatter';
import {format as d3Format} from 'd3-format';

const LabelContainer = styled.g`
    // text-transform: uppercase;
    fill: ${props => props.theme.primary};
    font-size: 13px;
    font-weight: 700;
`;

const QuadrantAxes = (props) => {
    const {axes, margin, width, height, xExtent, yExtent} = props;
    const {top, bottom, left, right} = margin;
    const xAxisRef = useRef(null);
    const yAxisRef = useRef(null);

    const xScale = scaleLinear()
        .domain(xExtent)
        .range([left, width - left - right]);

    const yScale = scaleLinear()
        .domain(yExtent)
        .range([height - top - bottom, top]).nice();

    const axisTickValue = axis => val => {
        const fn = d3FormatCode(axis);
        return fn === '%' ? val + '%' : d3Format(fn)(val);
    };

    const xAxis = axisBottom(xScale)
        .tickSize(6)
        .tickSizeOuter(0)
        .tickPadding(2)
        .tickFormat(axisTickValue(axes.xAxis));

    const yAxis = axisRight(yScale)
        .tickFormat(axisTickValue(axes.yAxis));

    const drawAxes = () => {
        select(xAxisRef.current)
            .call(xAxis)
            .selectAll('.tick line')
            .attr('transform', 'translate(0, -8)');
        select(yAxisRef.current).call(yAxis);
    };

    useEffect(() => {
        drawAxes();
    });

    return (
        <g className="axes">
            <style dangerouslySetInnerHTML={{__html: `
                .axis .tick line {stroke: rgba(0,0,0,0.2); }
                .axis.x-axis path, .axis.y-axis path { stroke: rgba(0,0,0,0.2); }
                .axis.x-axis text, .axis.y-axis text {fill: #757575; }
            `}} />

            <g
                ref={xAxisRef}
                className="axis x-axis"
                transform={`translate(${left}, ${height / 2 + top})`}
            />
            <g
                ref={yAxisRef}
                className="axis y-axis"
                transform={`translate(${(width - right) / 2 + left}, ${top})`}
            />

            <LabelContainer>
                <text
                    style={{fill: theme.primary, fontSize: 12, fontWeight: 700}}
                    transform={`translate(${width - right - 6}, 22)`}
                    textAnchor="end"
                >
                    {axes.xAxis.segmentName} {axes.xAxis.name}
                    <tspan style={{fontSize: 10}}> ( {label(axes.xAxis).label} )
                    </tspan>
                </text>
                <g transform={`translate(20, ${props.height / 2 + 12})`}>
                    <text
                        style={{fill: theme.primary, fontSize: 12, fontWeight: 700}}
                        transform={`rotate(-90)`}
                        textAnchor="middle"
                    >
                        {axes.yAxis.segmentName} {axes.yAxis.name}
                        <tspan style={{fontSize: 10, paddingLeft: 4}}> ( {label(axes.yAxis).label} )
                        </tspan>
                    </text>
                </g>
            </LabelContainer>
        </g>
    )
};

QuadrantAxes.defaultProps = {
    data: []
};

QuadrantAxes.propTypes = {
    data: PropTypes.array.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    margin: PropTypes.object.isRequired,
    xExtent: PropTypes.array.isRequired,
    yExtent: PropTypes.array.isRequired
};

export default QuadrantAxes;
