import React from 'react';
import PropTypes from 'prop-types';
import './LineChartMultiAxis.scss';
import LineMultiAxis from "./LineMultiAxis";
import ChartBase from "../ChartBase/ChartBase";
import ButtonChips from "../ButtonChips";
import PositionChartTip from "../PositionChartTip";
import PositionChartTipDetail from "../PositionChartTipDetail";

const margin = {top: 10, right: 40, bottom: 40, left: 58};

const dateValueFilter = dateRange => v => v.date >= dateRange[0] && v.date <= dateRange[1];
const dateFiltered = dateRange => d => ({...d, values: d.values.filter(dateValueFilter(dateRange))});

const LineChartMultiAxis = props => {
    const {data, dateRange, period, chartVis} = props;

    const filteredData = data
        .map(dateFiltered(dateRange))
        .filter(f => !chartVis.muted[f.id]);

    const displayedNames = props.showNames.map(n => n.name);

    return (
        <div id="chart-div">
            <style dangerouslySetInnerHTML={{__html: `
                .axis .tick line {stroke: rgba(0,0,0,0.2); }
                .axis.x-axis path, .axis.y-axis path { stroke: rgba(0,0,0,0.2); }
                .axis.x-axis text, .axis.y-axis text {fill: #757575; }
            `}} />
            <ChartBase>
                <LineMultiAxis
                    period={period}
                    chartVis={chartVis}
                    margin={margin}
                    dateRange={dateRange}
                    displayedNames={displayedNames}
                    data={filteredData}
                    curve={props.curve}
                />
                <div style={{position: 'relative'}}>
                    <PositionChartTip {...chartVis.tip} offset={-30} yOffset={8}>
                        <PositionChartTipDetail {...chartVis} period={period}/>
                    </PositionChartTip>
                </div>

                <div style={{marginTop: 18}}>
                <ButtonChips
                    series={props.showNames}
                    handleHover={(val) => chartVis.setPumped(val.id)}
                    handleUnhover={() => chartVis.setPumped(null)}
                    handleShow={(val) => chartVis.removeMuted(val.id)}
                    handleHide={(val) => {chartVis.addMuted(val.id)}}
                    dataKey="values"
                    hidden={chartVis.muted}
                />
                </div>
            </ChartBase>
        </div>
    )
};

LineChartMultiAxis.defaultProps = {
    hoveringId: 0
};

LineChartMultiAxis.propTypes = {
    data: PropTypes.array.isRequired, // Do we want this as an object or as an array?
    showNames: PropTypes.array.isRequired,
    hoveringId: PropTypes.number,
    dateRange: PropTypes.array.isRequired
};

export default LineChartMultiAxis;
