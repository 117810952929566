import React, {useState} from 'react';
import DataTable from "../../components/DataTable/DataTable";
import sort from "../../components/DataTable/sorting";
import {label} from '../../utilities/formatter';

const BubbleDataTable = (props) => {
    const [sorting, setSorting] = useState({sortBy: 'date', sortOrder: 'desc'});

    const {series, compareAxes} = props;
    if (!compareAxes) return null;

    const handleSetSorting = (sortBy, sortOrder) => {
      setSorting({sortBy, sortOrder});
    };

    const {xAxis, yAxis, size} = compareAxes;

    const columns = [
        {key: 'date', format: 'quarter', label: 'Period'},
        {key: 'name', format: 'string', label: 'Company'},
        {key: 'xAxis', format: label(xAxis).format, label: `${xAxis.segmentName}`, labelMetric: `${xAxis.name} (${label(xAxis).label})`},
        {key: 'yAxis', format: label(yAxis).format, label: `${yAxis.segmentName}`, labelMetric: `${yAxis.name} (${label(yAxis).label})`},
        {key: 'size', format: label(size).format, label: `${size.segmentName}`, labelMetric: `${size.name} (${label(size).label})`}
    ];

    return (
        <div style={{marginTop: 24}}>
            <DataTable
                sorting={sorting}
                setSort={handleSetSorting}
                sortableKeys={['date', 'name']}
                data={sort(series, sorting)}
                columns={columns.map(col => col.label
                    ? col
                    : {...col, label: compareAxes[col.key].name})}
            />
        </div>
    )
};

export default BubbleDataTable;


