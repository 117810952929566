import React, {useState} from 'react';
import Input from "../Input/Input";
import Button from "../Button/Button";
import styled from "@emotion/styled";
import PropTypes from 'prop-types';

const SavingContainer = styled.div`
    width: 100%;
    padding: 9px 18px;
    border-radius: 2px;
    margin-top: ${props => props.higher ? -18 : -6}px;
    margin-bottom: ${props => props.higher ? 22 : 0}px;
    background: ${props => props.theme.gray100};
    // border: 1px solid ${props => props.theme.primaryDark};
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    align-items: center;
    button {
        margin-left: 18px;
    }
`;

const Title = styled.div`
    color: ${props => props.theme.primaryDark};
    width: 200px;
`;

const TemplateForm = (props) => {

    const {onComplete, params, higher} = props;
    const [name, setName] = useState('');

    const handleSubmit = e => {
        e.preventDefault();
        props.handleSubmit(name, params);
    };

    return (
        <form onSubmit={handleSubmit} style={{width: '100%'}}>
            <SavingContainer higher={higher}>
                    <div style={{display: 'flex', width: 600, alignItems: 'center'}}>
                        <Title>Save as template</Title>
                            <Input placeholder="Template name..." value={name} onChange={e => setName(e.target.value)}/>
                    </div>
                    <div style={{}}>
                        <Button type="button" cancel onClick={onComplete}>Cancel</Button>
                        <Button onClick={handleSubmit} disabled={!name || name.length < 3}>Save</Button>
                    </div>
            </SavingContainer>
        </form>
    )
};

TemplateForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    params: PropTypes.string.isRequired,
    higher: PropTypes.bool
};

export default TemplateForm;
