import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import theme from "../../styles/theme";
import styled from "@emotion/styled";
import PropTypes from 'prop-types';

const EmptyCompanyContainer = styled.div`
    cursor: pointer;
    padding: 4px 10px;
    color: white;
    border-radius: 2px;
    font-size: 14px;
    span {
        padding-left: 12px;
        color: white;
    }
`;

const EmptyContainer = (props) => {
    return (
        <EmptyCompanyContainer onClick={() => props.onOpen ? props.onOpen(true) : null}>
            <FontAwesomeIcon icon={['fas', 'info']} color={theme.primaryLight}/>
            <span>{props.children}</span>
        </EmptyCompanyContainer>
    )
};

EmptyContainer.propTypes = {
    onOpen: PropTypes.func
};

export default EmptyContainer;
