import { library } from '@fortawesome/fontawesome-svg-core'
import {
    faTimes,
    faPlusSquare,
    faMinusSquare,
    faPlusCircle,
    faMinusCircle,
    faSquare,
    faChevronCircleRight,
    faInfo,
    faTrash,
    faTimesCircle,
    faLock,
    faArrowsAlt,
    faFileCsv,
    faFileExcel,
    faFilePdf,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faChevronDown,
    faCheck,
    faPlus,
    faMinus,
    faFastBackward,
    faStepBackward,
    faPauseCircle,
    faPlayCircle,
    faStepForward,
    faFastForward,
    faLayerGroup,
    faCircle,
    faSortAmountUp,
    faSortAmountDown,
    faArrowsAltH,
    faArrowsAltV,
    faCircleNotch,
    faEdit,
    faPen,
    faFileImage,
    faShareAltSquare
} from '@fortawesome/free-solid-svg-icons';

import {
    faHandPointer,
    faTimesCircle as farTimesCircle,
    faSave as farSave
} from '@fortawesome/free-regular-svg-icons'

library.add(
    faTimes,
    faPlusSquare,
    faMinusSquare,
    faPlusCircle,
    faMinusCircle,
    faSquare,
    faChevronCircleRight,
    faInfo,
    faTrash,
    faTimesCircle,
    faLock,
    faHandPointer,
    faArrowsAlt,
    farTimesCircle,
    faFileCsv,
    faFileExcel,
    faFilePdf,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faChevronDown,
    faCheck,
    faPlus,
    faMinus,
    farSave,
    faFastBackward,
    faStepBackward,
    faPauseCircle,
    faPlayCircle,
    faStepForward,
    faFastForward,
    faLayerGroup,
    faCircle,
    faSortAmountUp,
    faSortAmountDown,
    faArrowsAltH,
    faArrowsAltV,
    faCircleNotch,
    faEdit,
    faPen,
    faFileImage,
    faShareAltSquare
);
