import React, {useRef, useEffect, useState} from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import DropdownableItems from "./DropdownableItems";
import DropdownableContainer from "./DropdownableContainer";
import OrphanClose from "./OrphanClose";

const width = 300;
const padding = 0;

const Wrapper = styled.div`
    position: relative;
    width: ${props => props.small ? 100 : width}px;
    margin-left: ${padding}px;
    margin-bottom: 12px;
    font-size: 14px;
    user-select: none;
    ${({position, sibling}) => position ? `
        &:before {
            position: absolute;
            left: -${sibling ? padding/2 : padding}px;
            top: 18px;
            width: ${sibling ? padding/2 : padding}px;
            height: 1px;
            background: ${props => props.theme.primaryColor};
            content: '';
        }    
    ` : null}
`;

const Dropdownable = (props) => {

    const [open, setOpen] = useState(false);
    const wrapRef = useRef(null);

    const handleClick = e => {
        if (wrapRef.current.contains(e.target)) {
            return;
        }
        setOpen(false);
    };

    useEffect(() => {
       document.addEventListener('mousedown', handleClick);
       return (() => {
           document.removeEventListener('mousedown', handleClick);
       })
    });

    useEffect(() => {
        setOpen(false);
    }, [props.value]);

    return (
        <Wrapper ref={wrapRef} position={props.position} sibling={props.sibling} small={props.small}>
            <DropdownableContainer
                cy={props.cy && `${props.cy}-parent`}
                value={props.value}
                open={open}
                onClick={() => setOpen(!open)}
            >
                {props.value ? props.value.name : `${props.placeholder}...`}
            </DropdownableContainer>

            {open ? <DropdownableItems {...props} data-cy={props.cy && `${props.cy}-item`}/> : null }
            {props.sibling && props.value
                ? <OrphanClose onClear={props.handleClear} {...props}/>
                : null}
        </Wrapper>
    )
};

Dropdownable.defaultProps = {
    placeholder: 'Choose',
    position: 0
};

Dropdownable.propTypes = {
    valueType: PropTypes.string,
    options: PropTypes.array.isRequired,
    value: PropTypes.object,
    onSelect: PropTypes.func.isRequired,
    handleClear: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    sibling: PropTypes.bool
};

export default Dropdownable;
