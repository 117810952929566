import { useState, useEffect } from 'react';

const useListClamper = (fullList = [], count = 10) => {
    const [displayed, setDisplayed] = useState(fullList.filter((f, i) => i < count));
    const [clamped, setClamped] = useState(fullList.filter((f, i) => i >= count));

    useEffect(() => {
       setDisplayed(fullList.filter((f, i) => i < count));
       setClamped(fullList.filter((f, i) => i >= count));
    }, [fullList, setClamped, setDisplayed, count]);

    const release = (id) => {
        const toRelease = clamped.find(d => d.id === id);
        const toClamp = displayed[displayed.length -1];
        const newDisplayList = [...displayed.filter(d => d.id !== toClamp.id), toRelease];
        const newClampList = [...clamped.filter(c => c.id !== id), toClamp];
        setDisplayed(newDisplayList);
        setClamped(newClampList);
    };

    return {
        displayed: displayed,
        clamped: clamped,
        release: release
    }
};

export default useListClamper;
