import React from 'react';
import styled from '@emotion/styled';
import {Link} from "react-router-dom";
import PropTypes from 'prop-types';

const Container = styled.div`
    margin: 4px 0;
    padding: 4px 18px;
    font-size: 14px;
    color: ${props => props.theme.gray800};
    &:hover {
        background: ${props => props.theme.gray100};
        color: ${props => props.theme.highlight};
    }
`;

const HoverMenuItem = (props) => {
    return (
        <Link to={props.to}>
            <Container data-cy={props.cy}>{props.children}</Container>
        </Link>
    )
};

HoverMenuItem.propTypes = {
    to: PropTypes.string.isRequired,
    cy: PropTypes.string
};

export default HoverMenuItem;
