import React from 'react';
import styled from "@emotion/styled";
import {Link} from "react-router-dom";
import {withRouter} from "react-router";

const MenuItem = styled.div`
    color: rgba(255, 255, 255, 0.8);
    padding: 18px 12px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    cursor: pointer;
    border-left: 4px solid ${props => props.active ? props.theme.primaryLight : 'transparent'};
    background: ${props => props.active ? 'rgba(255, 255, 255, 0.1)' : 'transparent'};
    &:hover {
        background: rgba(255, 255, 255, 0.1);
        border-left: 4px solid ${props => props.theme.primaryLight};
        div {
            background: ${props => props.theme.primaryLight}; 
        }
    }
`;

const Bullet = styled.div`
    margin-right: 12px;
    height: 18px;
    width: 18px;
    background: white;
    border-radius: 6px;
    margin-left: 8px;
`;

const LeftMenuItem = (props) => {
    return (
        <Link to={props.to}>
            <MenuItem data-cy="left-menu-item" active={props.location.pathname === props.to}>
                <Bullet/>
                {props.children}
            </MenuItem>
        </Link>
    )
};

export default withRouter(LeftMenuItem);
