import React, {useState} from 'react';

import ChartBase from "../charts/ChartBase/ChartBase";
import QuadrantChartView from "../charts/QuadrantChart/QuadrantChartView";
import {extent} from 'd3-array';
import useTimelineControls from "../hooks/useTimelineControls";
import useListClamper from "../hooks/useListClamper";
import styled from '@emotion/styled';
import TimeKeeper from "../charts/QuadrantChart/TimeKeeper";
import TimelineNavigationControls from "../charts/QuadrantChart/TimelineNavigationControls/TimelineNavigationControls";
import ButtonChips from "../charts/ButtonChips";
import PositionChartTip from "../charts/PositionChartTip";
import QuadrantChartTipDetail from "../charts/QuadrantChart/QuadrantChartTipDetail";
import CircleSizeLabel from "../charts/QuadrantChart/CircleSizeLabel";
import BubbleDisplayDate from "../charts/QuadrantChart/BubbleDisplayDate";
import GhostingToggle from "../charts/QuadrantChart/GhostingToggle";

const Container = styled.div`
    svg {
        fill: white;
    }
`;

const maxCount = 14;

const fullRange = l => (acc, curr) => {
    return [...acc, ...curr.values.map(v => v[l])];
};

const Bubble = (props) => {
    const {series, compareAxes, chartVis, dateRange} = props;

    const [ghosted, setGhosted] = useState(true);

    const timeline = useTimelineControls(series[0].values.length - 1);

    const countClamper = useListClamper(series, maxCount);

    const showTip = tipValues => {
        if (tipValues) {
            timeline.getNearestMarker();
        } else {
            // timeline.setPlaying(true);
        }
        chartVis.showTip(tipValues);
    };

    const setPlaying = val => {
        timeline.setPlaying(val);
        chartVis.showTip(null);
    };

    const extents = {
        xExtent: extent(series.reduce(fullRange('xAxis'), [])),
        yExtent: extent(series.reduce(fullRange('yAxis'), [])),
        rExtent: extent(series.reduce(fullRange('size'), [])),
    };

    return (
        <Container>
            <TimeKeeper
                setPlaying={timeline.setPlaying}
                playing={timeline.playing}
                setTime={timeline.setTime}
                timeMarker={timeline.timeMarker}
                time={timeline.time}
                duration={8000}
            />

            <TimelineNavigationControls
                playing={timeline.playing}
                setPlaying={setPlaying}
                time={timeline.time}
                setTimeMarker={timeline.setTimeMarker}
                setTime={timeline.setTime}
                dates={dateRange}
                ghosted={ghosted}
                setGhosted={setGhosted}
                quarterly
            />

            <GhostingToggle ghosted={ghosted} setGhosted={setGhosted}/>

            <div style={{position: 'relative', background: 'white'}} id="chart-div">
                <ChartBase>
                    <QuadrantChartView
                        axes={compareAxes}
                        xExtent={extents.xExtent}
                        yExtent={extents.yExtent}
                        rExtent={extents.rExtent}
                        chartVis={chartVis}
                        timeline={timeline}
                        idKey={'companyCode'}
                        ghosted={ghosted}
                        showTip={showTip}
                        data={series}
                        countClamper={countClamper}
                        width={800}
                        height={500}
                    />
                </ChartBase>

                <BubbleDisplayDate time={timeline.time} dates={dateRange}/>

                <div style={{position: 'relative'}}>
                    <PositionChartTip playing={timeline.playing} {...chartVis.tip} offset={-40} yOffset={-10}>
                        <QuadrantChartTipDetail
                            data={series}
                            tip={chartVis.tip} time={timeline.time}
                            valueIndex={timeline.markerIndex}
                            idKey={'companyCode'}
                            axes={compareAxes}
                            titleKey="companyName"
                            subtitleKey="segmentName"
                        />
                    </PositionChartTip>
                </div>

                <CircleSizeLabel unit={compareAxes.size}/>

                <ButtonChips
                    noLine
                    titleOnly
                    series={props.showNames}
                    handleHover={(val) => chartVis.setPumped(val.id)}
                    handleUnhover={() => chartVis.setPumped(null)}
                    handleShow={(val) => chartVis.removeMuted(val.id)}
                    handleHide={(val) => {chartVis.addMuted(val.id)}}
                    dataKey="values"
                    hidden={chartVis.muted}
                />
            </div>

        </Container>)
};

export default Bubble;
