import React from 'react';
import IconButton from "../../components/TBRIcons/IconButton";
import LineIcon from "../../components/TBRIcons/LineIcon";
import BubbleIcon from "../../components/TBRIcons/BubbleIcon";
import InstructTip from "../../components/InstructTip/InstructTip";
import 'rc-tooltip/assets/bootstrap.css';
import Tooltip from "rc-tooltip";
import styled from '@emotion/styled';

const TipContent = styled.div`
    position: relative;
    top: 8px;
    padding: 0 18px;
`;

const ChartTypeToggle = (props) => {
    const {chartView, setChartView, chartViewOptions, showTips} = props;
    return (
            <div style={{marginRight: 24, marginTop: -12}}>
                <div style={{display: 'flex'}}>
                    <div style={{marginRight: 8}}>
                        <Tooltip overlay={<TipContent>Line Chart</TipContent>} placement="top" trigger={[showTips ? null : 'hover']}>
                            <div>
                                <IconButton cy="toggle-line" active={chartView.value === 'line'} onClick={() => setChartView(chartViewOptions[0])}>
                                    <LineIcon/>
                                </IconButton>
                            </div>
                        </Tooltip>
                    </div>

                    <div style={{position: 'relative'}}>
                        <InstructTip show={showTips} bottom text="If you're looking for a bubble chart, start here"/>
                        <Tooltip overlay={<TipContent>Bubble Chart</TipContent>} placement="top" trigger={[showTips ? null : 'hover']}>
                            <div>
                                <IconButton cy="toggle-bubble" active={chartView.value === 'bubble'} onClick={() => setChartView(chartViewOptions[1])}>
                                    <BubbleIcon/>
                                </IconButton>
                            </div>
                        </Tooltip>
                    </div>
                </div>
            </div>
    )
};

export default ChartTypeToggle;
