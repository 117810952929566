import React, {useState} from 'react';
import styled from '@emotion/styled';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PropTypes from 'prop-types';

const Container = styled.div`
    padding: 4px 12px;
    border-radius: 2px;
    margin-top: 4px;
    margin-right: 6px;
    display: flex;
    font-size: 14px;
    color: white;
    background: ${({requested, theme, noData}) => requested && noData ? theme.gray600 : requested ? theme.primaryLight : 'none'};
    &:hover {
        background: ${({removing, theme, noData, requested}) => requested && noData ? theme.gray600 : removing ? theme.errorDark : 'transparent'};
    }
`;

const IconContainer = styled.div`
    cursor: pointer;
    height: 14px;
    width: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    border: 1px solid white;
    font-size: 9px;
    margin-right: 10px;
    flex-shrink: 0;
    path {
        stroke: ${({removing, theme}) => removing ? theme.errorDark : theme.primaryDark};
        stroke-width: 2.4pc;
    }
`;

const SelectedMetricItem = (props) => {
    const {requested, code, name, noData} = props;
    const [removing, setRemoving] = useState(false);
    return (
        <Container removing={removing} data-cy="selected-metric-item" requested={requested} noData={noData}>
            {!requested ?
                <IconContainer
                    data-cy="icon-metric-remove"
                    onMouseEnter={() => setRemoving(true)}
                    onMouseLeave={() => setRemoving(false)}
                    onClick={() => props.onRemove(code)}
                    removing={removing}
                >
                    <FontAwesomeIcon icon={['fas', 'times']}/>
                </IconContainer>
                : null}
            <div>
                {requested && !noData ? <span style={{paddingRight: 12}}>
                    <FontAwesomeIcon icon={['fas', 'circle-notch']} spin/>
                </span>
                    : null}
                {name}
                {noData ? <span style={{fontSize: 10}}><br />not available</span> : null}
            </div>
        </Container>
    )
};

SelectedMetricItem.propTypes = {
    onRemove: PropTypes.func
};

export default SelectedMetricItem;
