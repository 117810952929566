import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const Container = styled.div`
    background: ${props => props.theme.error};
    color: white; 
    font-size: 14px;
    padding: 14px 12px;
    text-align: center;
    border-radius: 2px;
    margin-top: 6px;
    margin-bottom: 12px;
`;

const FormError = (props) => {
    return props.show ?
        <Container data-cy={props.cy}>{props.children}</Container>
    : null;
};

FormError.propTypes = {
    cy: PropTypes.string
};

export default FormError;
