import {useState} from 'react';

const useMatchFilter = (list = [], haystackProperty = 'name') => {
    const [term, setTerm] = useState('');
    const filtered = !term ? list : list.filter(item => {
        const regex = RegExp(term.replace(/[\s.,]/g, ''), 'gi');
        return regex.test(item[haystackProperty].replace(/[\s.,]/g, ''));
    });

    return [term, setTerm, filtered];
};

export default useMatchFilter;
