import React, {useRef, useEffect, Fragment, useCallback} from 'react';
import {select, selectAll} from 'd3-selection';
import {line as d3Line, curveCatmullRom} from 'd3-shape';
import QuadrantPlacementCircles from "./QuadrantPlacementCircles";
import QuadrantPlacementLine from "./QuadrantPlacementLine";
import {calculateWeightedValues, getBetweenPositions} from './utilities/utilities';
import styled from '@emotion/styled';

const ActiveCircle = styled.circle`
    cursor: pointer;
    stroke-width: ${({hasTip}) => hasTip ? 6 : 0}px;
    stroke: yellow;
    opacity: ${props => props.hideActive ? 0 : 1};
    &:hover {
       stroke-width: 4;
       stroke-opacity: .3;
    }
`;

const QuadrantChart = ({
                           data, scales, playing, color, ghosted, id, time, hasTip, showTip,
                           tip, setTipPosition, xKey, yKey, rKey, width}) => {

    let circleRef = useRef(null),
        lineRef = useRef(null),
        ghostRef = useRef(null),
        pathRef = useRef(null),
        dLength = data.length - 1,
        pointPositions = [];

    const line = d3Line()
        .x(d => scales.x(d[xKey]))
        .y(d => scales.y(d[yKey]))
        .curve(curveCatmullRom);

    const translateAlongPath = useCallback(path => {
        if (!path) return 'translate(0,0)';
        const averagedDistance = calculateWeightedValues('totalDist', time, pointPositions, path, pointPositions, dLength);
        // somehow we are going between point positions

        let {x,y} = path.getPointAtLength(averagedDistance);
        return `translate(${Math.floor(x)},${Math.floor(y)})`;
    }, [dLength, pointPositions, time]);

    const growAlongPath = useCallback((path) => {
        return scales.r(calculateWeightedValues(rKey, time, data, path, pointPositions, dLength));
    }, [dLength, data, pointPositions, rKey, scales, time]);

    const setPointValues = useCallback(() => {
        selectAll(`circle.centers-${id}`).nodes().forEach((d, i) => {
            const pointValues = {
                x: d.cx.baseVal.value,
                y: d.cy.baseVal.value
            };

            pointValues.dist = i === 0
                ? 0
                : getBetweenPositions(pointPositions[i - 1], pointValues);

            pointValues.totalDist = pointPositions.reduce((acc, curr) => {
                return acc + curr.dist;
            }, 0) + pointValues.dist;

            pointPositions.push(pointValues);
        });
    }, [id, pointPositions]);

    const currentInt = Math.floor(time * data.length -1);
    const hideActive = (data[currentInt + 1] && data[currentInt + 1].noValue) || (data[currentInt] && data[currentInt].noValue);

    useEffect(() => {
        setPointValues();
        const thisPath = select(pathRef.current);
        const circle = select(circleRef.current);
        const transformValue = translateAlongPath(thisPath.node());

        circle
            .style('fill', color)
            .attr('transform', transformValue)
            .attr('r', growAlongPath(thisPath.node()))
            .on('mousedown', () => {
                showTip(hasTip ? null : {id: id});
            });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [playing, time, hasTip, width]);

    useEffect(() => {
        if (hasTip) {
            showTip(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [width]);

    useEffect(() => {
        setPointValues();
        let circle = select(circleRef.current);
        const offset = 12;
        let coords = circle.node().getAttribute('transform').replace('translate(', '').replace(')', '').split(',');
        coords = coords.map(coo => parseInt(coo));

        if (tip && tip.id === id && (!tip.x || tip.x !== coords[0] + offset)) {
            // setTimeout... refactor.
            setTimeout(() => {
                const r = parseInt(circle.node().getAttribute('r'));
                setTipPosition({x: coords[0] + offset, y: coords[1] + 20 - r, id: id});
            }, 100);
        }
    }, [tip, id, setPointValues, setTipPosition]);


    return (
        <Fragment>
            <g ref={lineRef}/>
            <g ref={ghostRef}/>
            <QuadrantPlacementCircles id={id} data={data} color={color} ghosted={ghosted} xKey={xKey} yKey={yKey} rKey={rKey}
                                      xScale={scales.x} yScale={scales.y} rScale={scales.r} />
            <QuadrantPlacementLine line={line} data={data} ghosted={ghosted} color={color} pathRef={pathRef}/>
            <ActiveCircle
                data-cy="quadrant-active-bubble"
                ref={circleRef}
                hasTip={hasTip}
                hideActive={hideActive}
            />
        </Fragment>
    )
};

QuadrantChart.defaultProps = {
    xKey: 'xAxis',
    yKey: 'yAxis',
    rKey: 'size'
};

export default QuadrantChart;
