const highlight = '#2684ff';
const primary = '#1085C6';
const primaryLight = '#06BBF9';
const primaryDark = '#1A4379';
const primary900 = '#052240';
const primaryTint = '#F1F6F8';
const grayTint = '#F7F7F7';
const grayDark = '#6C6E74';
const accent = '#00804D';
const accentOrange = '#F47735';
const error = '#cc2300';
const errorDark = '#ac1e00';
const lightError = '#e18c7b';
const disabled = '#88b3c6';
const gray50 = '#FAFAFA';
const gray100 = '#F5F5F5';
const gray200 = '#EEEEEE';
const gray300 = '#E0E0E0';
const gray400 = '#BDBDBD';
const gray500 = '#9E9E9E';
const gray600 = '#757575';
const gray700 = '#616161';
const gray800 = '#424242';
const gray900 = '#212121';
const pad1 = '8px 12px';

export default {
    primary,
    primaryLight,
    primaryDark,
    primary900,
    primaryTint,
    grayTint,
    grayDark,
    accent,
    accentOrange,
    highlight,
    error,
    lightError,
    errorDark,
    disabled,
    gray50,
    gray100,
    gray200,
    gray300,
    gray400,
    gray500,
    gray600,
    gray700,
    gray800,
    gray900,
    pad1,
    inputBorderWidth: 1.5,
    inputBorderColor:  gray500,
    outline: `0 0 0 1.2pt ${highlight}`,
    /* Shadow 1dp */
    shadow1: '0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.10)',
    /* Shadow 2dp */
    shadow2: '0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.10)',
    /* Shadow 3dp */
    shadow3: '0 3px 4px 0 rgba(0,0,0,0.14), 0 3px 3px -2px rgba(0,0,0,0.12), 0 1px 8px 0 rgba(0,0,0,0.10)',
    /* Shadow 4dp */
    shadow4: '0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.10)',
    /* Shadow 6dp */
    shadow6: '0 6px 10px 0 rgba(0,0,0,0.14), 0 1px 18px 0 rgba(0,0,0,0.12), 0 3px 5px -1px rgba(0,0,0,0.10)',
};
