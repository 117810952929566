import React from 'react';

const QuadrantPlacementLine = ({line, data, ghosted, color, pathRef}) => {
    return (
        <path d={line(data)} opacity={ghosted ? 1 : 0} fill="none" stroke={color} strokeWidth="2" strokeOpacity=".05" ref={pathRef}
              id="my-path"/>
    )
};

export default QuadrantPlacementLine;
