import React from 'react';
import styled from '@emotion/styled';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Container = styled.div`
    position: absolute;
    left: -36px;
    top: -2px;
    height: 20px;
    font-size: 18px;
    margin: 8px auto;
    cursor: pointer;
    border-radius: 4px;
    padding: 4px;
    path {
        fill: ${props => props.primaryLight};
    }
    &:hover {
        background: ${props => props.theme.error};
        path {
            fill: white;
        }
    }
`;

const OrphanClose = (props) => {
    return (
        <Container onClick={() => props.onClear(props)}>
            <FontAwesomeIcon icon={['fas', 'times-circle']} />
        </Container>
    )
};

export default OrphanClose;
