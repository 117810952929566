import React from 'react';
import styled from '@emotion/styled';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Container = styled.div`
    width: calc(100% - 22px);
    border-radius: 2px;
    background: ${({active, theme}) => active ? theme.primary : 'white'};
    padding: 12px 8px;
    font-size: 12px;
    margin-bottom: 8px;
    cursor: pointer;
    color: ${({active, theme}) => active ? 'white' : 'black'};
    border: 2px solid ${({active, theme}) => active ? theme.primary : 'white'};
    overflow: hidden;
    display: inline-block;
    &:hover {
        box-shadow: ${props => props.theme.shadow1};
        border: 2px solid ${props => props.theme.highlight};
    }
    path {
        fill: ${({active, theme}) => active ? 'white' : theme.primary};
    }
`;

const MetricContainer = styled.div`
    display: flex;
    margin-bottom: ${props => props.last ? 0 : 12}px;
    line-height: 160%;
    div {
        flex-shrink: 0;
    }
`;

const LabelTitle = styled.span`
    color: ${({active, theme}) => active ? 'white' : theme.primary};
    font-weight: 700;
`;

const IconContainer = styled.div`
    width: 10px;
    flex-grow: 0;
    margin-right: 10px;
    text-align: center;
`;

const BubbleSelectorItem = (props) => {
    const convert = {
      yty: 'year-to-year (% change)',
      amount: ' ($)',
      absGrowth: 'year-to-year ($ change)'
    };

    const makeLabel = (segment, metric) => (
        <div style={{width: 'calc(100% - 20px)'}}>
            <LabelTitle active={props.active}>{segment.name}</LabelTitle><br />
            {metric.name} {convert[metric.valueType]}
        </div>
    );

    return (
        <Container onClick={props.onClick} active={props.active} data-cy="bubble-chart-element">
            <MetricContainer>
                <IconContainer><FontAwesomeIcon icon={['fas', 'arrows-alt-h']}/></IconContainer>
                {makeLabel(props.xSegment, props.xMetric)}
            </MetricContainer>
            <MetricContainer>
                <IconContainer><FontAwesomeIcon icon={['fas', 'arrows-alt-v']}/></IconContainer>
                {makeLabel(props.ySegment, props.yMetric)}
            </MetricContainer>
            <MetricContainer last>
                <IconContainer><FontAwesomeIcon icon={['fas', 'circle']} size="xs"/></IconContainer>
                {makeLabel(props.sizeSegment, props.sizeMetric)}
            </MetricContainer>
        </Container>
    )
};

export default BubbleSelectorItem;
