import React, {useState} from 'react';
import HoverMenu from '../../HoverMenu/HoverMenu';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import UserIcon from "./UserIcon";

const fillFromMode = props => props.mode === 'light' ? props.theme.primary : 'white';

const Container = styled.div`
    display: inline-block
    align-items: center;
    position: relative;
    cursor: pointer;
    color: ${fillFromMode};
    .name {
        font-size: 14px;
        margin-bottom: 4px;
    }
    .email {
        font-weight: 400;
        font-size: 12px;
    }
    .user-icon-container {
        margin-left: 18px;
        border: 1px solid ${fillFromMode};
        border-radius: 50%;
        width: 32px;
        height: 32px;
        overflow: hidden;
        background: ${props => props.theme.primaryTint};
        svg {
            margin-top: 6px;
            height: 26px;
            path {
                fill: ${fillFromMode};
            }
        }
    }
`;

const DisplayedText = styled.div`
    text-align: center;
    color: ${props => props.theme.primary};
`;

const UserMenu = (props) => {
    const {light, showName, showEmail, name, email} = props;
    const [open, setOpen] = useState(false);

    return (
        <Container data-cy="profile-closed" onClick={() => setOpen(true)} mode={light ? 'light' : 'dark'}>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <DisplayedText>
                    {showName && <div className="name">{name || 'Doc Holiday'}</div>}
                    {showEmail && <div className="email">{email || 'dholiday@mail.com'}</div>}
                </DisplayedText>
                <div className="user-icon-container">
                    <UserIcon />
                </div>
            </div>
            <HoverMenu open={open} close={(event) => {if (event) event.stopPropagation(); setOpen(false)}}>
                <div style={{lineHeight: '1.4em'}}>
                    {props.children}
                </div>
            </HoverMenu>
        </Container>
    )
};

UserMenu.propTypes = {
    name: PropTypes.string,
    email: PropTypes.string
};

export default UserMenu;
