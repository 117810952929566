import React, {Component, Fragment} from 'react';
import './DateRangeSlider.scss';
import SliderLabel from "./SliderLabel";
import Track from "./Track";
import SelectedTrack from "./SelectedTrack";
import PropTypes from 'prop-types';

class DateRangeSlider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 0,
            value2: 1
        };
        this.container = React.createRef();
    }

    componentDidMount() {
        const initialMin = monthDiff(this.props.minDate, this.props.minDisplay);
        const initialMax = monthDiff(this.props.minDate, this.props.maxDisplay);
        this.initValues(initialMin, initialMax + 1);
    }

    handleChange = (positionValue1, positionValue2) => {
        if (positionValue2 - positionValue1 < 3) return;
        this.initValues(positionValue1, positionValue2, true);
    };

    initValues = (positionValue1, positionValue2, changed = false) => {
        const months = monthDiff(this.props.minDate, this.props.maxDate);
        let dimensions = this.container.current.getBoundingClientRect();
        const rangeWidth = dimensions.width - this.props.labelWidth * 2;
        const position1 = (rangeWidth / (months + 2)) * positionValue1 + this.props.labelWidth + 16;
        const position2 = (rangeWidth / (months + 2)) * positionValue2 + this.props.labelWidth + 12;

        const minDisplay = this.makeDate(positionValue1);
        const maxDisplay = this.makeDate(positionValue2);

        this.setState({
            value: positionValue1,
            value2: parseInt(positionValue2),
            position1: position1,
            position2: position2,
            minDisplay: minDisplay,
            maxDisplay: maxDisplay,
            containerWidth: dimensions.width,
            months: months
        }, () => {
            if (changed) {
                this.props.onChange({
                    minDisplay: this.state.minDisplay,
                    maxDisplay: this.state.maxDisplay
                });
            }
        });
    };

    makeDate = (value) => {
        const startDate = new Date(this.props.minDate);
        startDate.setMonth(startDate.getMonth() + Number(value));
        startDate.setHours(0, 0, 0, 0);
        return startDate;
    };

    render() {
        const startDate = this.props.minDate;
        const trackTop = 30;
        const trackHeight = 4;
        const sliderHeight = 28;
        let {position1, position2, value, value2} = this.state;
        const rangeWidth = this.state.containerWidth - (this.props.labelWidth * 2);

        return (
            <div ref={this.container} className="handle-square" style={{position: 'relative'}}>
                {!isNaN(rangeWidth) ?
                    <Fragment>
                        <SliderLabel
                            position={position1 - this.props.labelWidth}
                            value={this.state.minDisplay}
                            type="from"
                            startDate={startDate}
                            width={this.props.labelWidth}/>

                        <SliderLabel
                            position={position2}
                            value={this.state.maxDisplay}
                            type="to"
                            startDate={startDate}
                            width={this.props.labelWidth}/>

                        <Track width={rangeWidth - 16} top={trackTop} height={trackHeight} left={this.props.labelWidth + 16}/>
                        <SelectedTrack p1={position1} p2={position2}
                                       top={trackTop}/>

                        <input
                            style={{
                                width: rangeWidth,
                                position: 'absolute',
                                top: trackTop - sliderHeight / 2 - trackHeight * 1.5,
                                left: this.props.labelWidth
                            }}
                            type="range"
                            step="1" min="0"
                            max={this.state.months}
                            value={value}
                            onChange={(event) => this.handleChange(event.target.value, value2)}
                        />
                        <input
                            style={{
                                width: rangeWidth,
                                position: 'absolute',
                                top: trackTop - sliderHeight / 2 - trackHeight * 1.5,
                                left: this.props.labelWidth
                            }}
                            type="range"
                            step="1" min="0"
                            max={this.state.months + 1}
                            value={value2}
                            onChange={event => this.handleChange(value, event.target.value)}
                        />

                    </Fragment>
                    : null
                }
            </div>
        )
    }
}

DateRangeSlider.defaultProps = {
    labelWidth: 80
};

DateRangeSlider.propTypes = {
    labelWidth: PropTypes.number,
    minDate: PropTypes.object.isRequired,
    maxDate: PropTypes.object.isRequired,
    minDisplay: PropTypes.object.isRequired,
    maxDisplay: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired
};

export default DateRangeSlider;


function monthDiff(d1, d2) {
    d1 = new Date(d1);
    d2 = new Date(d2);
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth() + 1;
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
}
