import startOfYear from 'date-fns/startOfYear';

const useAnnual = (list, annual = false) => {
    if (!list) return [];

    if (!annual) {
        return list.map(item => ({...item, values: item.values.filter(v => v.periodType === 'Quarterly' && !v.isFuture)}));
    }

    const yearsWithAnnual = list
        .map(l => l.values.filter(v => v.periodType === 'Annual').map(v => v.date.getYear()))
        .flat()
        .filter((v, i, s) => s.indexOf(v) === i);

    const filtered = list.map(item => ({
        ...item,
        values: item.values.filter(v => v.periodType === 'Quarterly')
            .reduce((acc, curr) => {
                const yr = curr.date.getYear();
                if (yearsWithAnnual.find(y => y === yr)) return acc;

                const newAmount = acc[yr] ? acc[yr].value += curr.value : curr.value;
                const newCount = acc[yr] ? acc[yr].quarterCount + 1 : 1;

                return {...acc, [yr]: {...curr,
                        value: newAmount,
                        date: startOfYear(curr.date),
                        quarterCount: newCount
                    }}
            }, {}
        )}));

    const arrayed = filtered.map(f => ({...f, values: Object.entries(f.values)
            .map(e => ({...e[1], value: (e[1].value * 4) / e[1].quarterCount}))
    }));

    return arrayed.map(pair => {
        return {
            ...pair,
            values: [...pair.values, ...list
                    .find(item => item.id === pair.id).values
                    .filter(v => v.periodType === 'Annual').map(v => ({...v, date: startOfYear(v.date)}))]
        }
    });
};

export default useAnnual;
