import React from 'react';
import {Redirect} from "react-router";
import useQsMaker from "../../hooks/useQsMaker";

const ComparisonRedirect = (props) => {
    const {selected, type} = props;
    const qString = useQsMaker(selected, type);
    const section = type.includes('line') ? 'chart' : 'bubble';
    return  <Redirect to={`${section}?${qString}`}/>
};

export default ComparisonRedirect;
