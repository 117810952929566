const tokenStorage = store => next => action => {
    if (action.meta && action.meta.token) {
        if (action.meta.token.create) {
            localStorage.setItem('user', JSON.stringify(action.meta.token.user))
        }

        if (action.meta.token.destroy) {
            localStorage.removeItem('user');
        }
    }
    return next(action);
};

export default tokenStorage;
