import * as actionTypes from '../actions/actionTypes';

const initialState = {
    loading: false,
    loaded: false,
    saving: false,
    list: [],
    error: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOAD_TEMPLATES_START: {
            return {...state, loading: true};
        }

        case actionTypes.LOAD_TEMPLATES_FAIL: {
            return {...state, loading: false, error: action.error}
        }

        case actionTypes.LOAD_TEMPLATES_SUCCESS: {
            return {...state, loading: false, list: action.results, loaded: true}
        }

        case actionTypes.SAVE_TEMPLATE_START: {
            return {...state, saving: true};
        }

        case actionTypes.SAVE_TEMPLATE_SUCCESS: {
            return {...state, saving: false, list: [...state.list, action.result]};
        }

        case actionTypes.SAVE_TEMPLATE_FAIL: {
            return {...state, loading: false, error: action.error};
        }

        case actionTypes.DELETE_TEMPLATE_SUCCESS: {
            return {
                ...state,
                loading: false,
                list: state.list.filter(item => item.id !== action.id)}
        }

        default: return state;
    }
}
