export const calculateWeightedValues = (key, t, dataSet, path, pointPositions, dLength) => {
    const lineLength = path.getTotalLength();
    const totalDist = pointPositions[dLength];
    const ratio = totalDist ? lineLength / totalDist ? totalDist.totalDist : 1 : 1;

    if (t === 1) {
        return dataSet[dLength] ? dataSet[dLength][key] * ratio : 1;
    }

    let timePoint = (t % 1) * dLength;

    // get point before and point after
    let prevPoint = Math.floor(timePoint);
    let nextPoint = prevPoint + 1;

    let nextDec = timePoint % 1;
    let prevDec = 1 - nextDec;

    if (!dataSet[prevPoint] || !dataSet[nextPoint]) return 1;

    return ratio * ((dataSet[prevPoint][key] * prevDec) + (dataSet[nextPoint][key] * nextDec));
};

export const getBetweenPositions = (point1, point2) => {
    let xs = Math.pow((point2.x - point1.x), 2);
    let ys = Math.pow((point2.y - point1.y), 2);
    return Math.sqrt(xs + ys);
};
