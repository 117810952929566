import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import * as actionTypes from '../../store/actions/actionTypes';

const Container = styled.div`
    background: ${props => props.theme.error};
    padding: 12px 24px;
    color: white;
    position: absolute;
    z-index: 10;
    border-radius: 2px;
    top: -76px;
    span {
        padding-left: 12px;
        font-size: 12px;
        &:hover {
            cursor: pointer;
        }
    }
`;

const ComparisonError = (props) => {
    const {message, dispatch} = props;

    return (
        <Container>
            {message}
            <span onClick={() => dispatch({type: actionTypes.CLOSE_ENTRY_ERROR})}>close</span>
        </Container>
    )
};

ComparisonError.propTypes = {
    message: PropTypes.string
};

export default ComparisonError;
