export const label =  val => {

    const percents = ['OpMargin', 'GrossMargin'];

    if (val.type === 'yty') return {label: '% yty change', format: 'percent-whole'};

    if (percents.find(p => val.metric === p)) {
        return {label: '% change', format: 'percent-whole'};
    }

    if (counts.find(c => val.metric === c)) {
        return {label: 'Count', format: 'count'}
    }
    if (val.type === 'ttmYty') {
        return {label: 'TTMYTY', format: 'percent-whole'};
    }

    if (val.type === 'amount' || val.type === 'absGrowth') {
        return {label: val.type, format: 'money'};
    } else {
        return {label: val.type, format: ''};
    }
};

// For Y Axis Line chart only atm
export const d3FormatCode = val => {
    if (val.type === 'yty' || val.type === 'ttmYty' || val.metric === 'GrossMargin') {
        return '%';
    }
    return counts.find(c => val.metric === c) ? '~s' : '$~s';
};

const counts = ['NumOfEmp', 'CoveredPOPs', 'Subscribers', 'Units', 'ASP'];
