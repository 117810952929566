import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import styled from "@emotion/styled";
import {Link} from "react-router-dom";
import PropTypes from 'prop-types';

const Container = styled.div`
    font-size: 18px;
    display: flex;
    margin-left: 28px;
    color: black;
    opacity: ${props => props.saveDisabled ? .3 : 1};
    cursor: pointer;
    path {
        fill: ${props => props.theme.primary};
        stroke: white;
        stroke-width: 1.8pc;
        opacity: ${props => props.saveDisabled ? .6 : 1};
    }
    svg {
        margin-right: 8px;
        margin-top: -2px;
    }
    div {
        font-size: 14px;
    }
`;
const TemplateContainer = styled.div`
    display: flex;
    margin-right: 12px;
    font-weight: 700;
`;

const TemplateMenu = (props) => {
    const {editTo, onSave, saveDisabled} = props;
    return (
        <TemplateContainer>
            <Container data-cy="link-save-template" onClick={saveDisabled ? null : onSave} saveDisabled={saveDisabled}>
                <FontAwesomeIcon icon={['far', 'save']}/>
                <div>Save as template</div>
            </Container>

            <Link to="/">
                <Container data-cy="link-load-templates">
                    <FontAwesomeIcon icon={['far', 'save']}/>
                    <div>Load template</div>
                </Container>
            </Link>

            {editTo ?
            <Link to={editTo}>
                <Container data-cy="link-change-criteria">
                    <FontAwesomeIcon icon={['fas', 'edit']} size='sm'/>
                    <div>Change criteria</div>
                </Container>
            </Link> : null
            }

        </TemplateContainer>
    )
};

TemplateMenu.propTypes = {
    onSave: PropTypes.func.isRequired,
    editTo: PropTypes.string,
    saveDisabled: PropTypes.bool
};

export default TemplateMenu;
