import React, {useRef, useEffect} from 'react';
import {select} from "d3-selection";
import {axisBottom} from "d3-axis";
import differenceInMonths from 'date-fns/differenceInMonths/index';
import PropTypes from 'prop-types';
import format from 'date-fns/format';

const XAxis = ({height, margin, xScale, dateRange, period}) => {
    const maxTicks = 15;
    const minTicks = 3;
    const xAxis = useRef(null);
    const numberTicks = differenceInMonths(dateRange[1], dateRange[0]) - (period === 'quarterly' ? 10 : 60);

    const quarter = val => {
      return format(val, period === 'quarterly' ? 'yyyy-QQQ' : 'yyyy')
    };

    useEffect(() => {
        select(xAxis.current)
            .attr('transform', `translate(0, ${height - margin.bottom})`)
            .attr('class', 'axis x-axis')
            .attr('data-cy', 'x-axis')
            .call(axisBottom(xScale)
                .tickPadding([18])
                .ticks(numberTicks > maxTicks ? maxTicks : numberTicks < minTicks ? minTicks : numberTicks)
                .tickSize(-(height - margin.top - margin.bottom))
                .tickFormat(quarter)
            );
    });

    return (
        <g ref={xAxis}/>
    )
};

XAxis.propTypes = {
    height: PropTypes.number.isRequired,
    margin: PropTypes.object.isRequired,
    xScale: PropTypes.func.isRequired,
    dateRange: PropTypes.array.isRequired
};

export default XAxis;
