import React from 'react';
import styled from "@emotion/styled";
import QuadrantTimeline from "../QuadrantTimeline/QuadrantTimeline";
import QuadrantVideoControls from "../QuadrantTimeline/QuadrantVideoControls";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const QuadrantTimelineContainer = styled.div`
    margin-bottom: 12px;
    display: flex;
    align-items: center;
`;

const GhostedContainer = styled.div`
    margin-left: 24px;
    font-size: 24px;
    cursor: pointer;
    path {
        fill: ${({theme, ghosted}) => ghosted ? theme.primary : theme.gray300};
    }
`;

const TimelineNavigationControls = ({setPlaying, dates, playing, time, ghosted, setGhosted, setTimeMarker, setTime, quarterly}) => {

    const handleSetDate = i => {
        setPlaying(false);
        setTimeout(() => {
            setTime(i / (dates.length -1));
        }, 50);
    };

    return (
        <QuadrantTimelineContainer>
            <QuadrantVideoControls
                setPlaying={setPlaying}
                playing={playing}
                setTimeMarker={(direction) => setTimeMarker(direction, 1, time, dates.length - 1)}
            />

            <QuadrantTimeline dates={dates} time={time} quarterly={quarterly} setDate={handleSetDate}/>
            <GhostedContainer onClick={() => setGhosted(!ghosted)} ghosted={ghosted}>
                <FontAwesomeIcon icon={['fas', 'layer-group']}/>
            </GhostedContainer>
        </QuadrantTimelineContainer>
    )
};

export default TimelineNavigationControls;
