import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import styled from '@emotion/styled';

const Message = styled.div`
    margin-bottom: 12px;
    padding: ${({border, theme}) => border ? '18px 24px' : 0};
    background: ${({border, theme}) => border ? theme.primaryTint : 'none'};
    border-radius: 4px;
    span {
        margin-left: 12px;
    }
    path {
        fill: ${props => props.theme.primary};
    }
`;

const ActionContainer = styled.div`
    margin-left: 42px;
    font-size: 14px;
    text-decoration: underline;
    display: flex;
    svg {
        margin-left: 8px;
        position: relative;
        top: 2px;
    }
    path {
        fill: ${props => props.theme.primary};
    }
    a {
        color: ${props => props.theme.primary};
    }
`;

const EmptyTemplates = (props) => {
    return (
        <div>
            <Message border={props.border}>
                <FontAwesomeIcon icon={['fas', 'info']}/><span>{props.message}</span>
            </Message>
            {props.action ? <ActionContainer>
                {props.action}
                <FontAwesomeIcon icon={['fas', 'chevron-right']} />
            </ActionContainer> : null}
        </div>
    )
};

EmptyTemplates.defaultProps = {
    message: 'You have not saved any templates, yet'
};

export default EmptyTemplates;
