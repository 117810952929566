import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Container = styled.button`
    border: 0;
    padding: 12px 22px;
    min-width: 140px;
    border-radius: 2px;
    color: ${({theme, cancel, loading}) => cancel ? theme.primary : loading === 'true' ? theme.gray500 : 'white'};
    background: ${({theme, cancel, loading}) => loading === 'true' ? theme.gray600 : cancel ? theme.gray200 : theme.primary};
    cursor: ${({loading}) => loading === 'true' ? 'default' : 'pointer'};
    transition: .1s linear all;
    outline: none;
    position: relative;
    &:hover:enabled {
        box-shadow: 0 0 0 ${({loading, cancel}) => loading === 'true' || cancel ? 0 : .8}pt ${props => props.theme.primary};
    }
    &:disabled {
        background-color: ${props => props.theme.gray200};
        color: ${props => props.theme.gray400};
        cursor: not-allowed;
    }
`;
const Overlay = styled.div`
    position: absolute;
    left: 0;
    width: 100%;
    top: 0;
    margin-top: 6px;
    display: flex;
    justify-content: center;
    align-items: stretch;
    font-size: 20px;
    path {
        fill: ${props => props.theme.primaryLight};
    }
`;

const Button = (props) => {
    return (
        <Container data-cy={props.cy} {...props}>
            {props.loading === 'true' ?  <Overlay><FontAwesomeIcon icon={['fas', 'circle-notch']} size="lg" spin/></Overlay> : null}
            {props.children}
        </Container>
    )
};

Button.propTypes = {
    cy: PropTypes.string,
    loading: PropTypes.string,
    error: PropTypes.bool,
    success: PropTypes.bool,
    cancel: PropTypes.bool
};

export default Button;
