import React, {useEffect, useState} from 'react';
import qs from "qs";
import * as actions from "../store/actions";
import {useDispatch, useSelector} from "react-redux";
import Bubble from "./Bubble";
import TopActionElements from "../components/TopActionElements/TopActionElements";
import BubbleDataTable from "./Bubble/BubbleDataTable";
import TemplateFormConnected from "../components/TemplateForm/TemplateFormConnected";
import TemplateMenu from "../components/TemplateMenu/TemplateMenu";
import styled from "@emotion/styled";
import useChartVisibility from "../charts/hooks/useChartVisibility";
import useAnnual from "../hooks/useAnnual/useAnnual";
import ProcessingDataLoader from "../charts/ProcessingDataLoader";

const chipNames = s => ({
    id: s.companyCode,
    name: s.name,
    color: s.color,
    company: s.companyName,
    segment: s.segmentName,
    lineStyle: s.lineStyle
});

const Title = styled.div`
    font-weight: 700;
    color: ${props => props.theme.primary};
`;
const HeaderContainer = styled.div`
    display: flex;
    margin-top: -8px;
    margin-bottom: 24px;
    border-bottom: 1px solid ${props => props.theme.gray100};
    padding-bottom: 12px;
    justify-content: space-between;
`;

const BubblePage = (props) => {
    const dispatch = useDispatch();
    const {search} = props.location;
    const [saving, setSaving] = useState(false);
    const [period, setPeriod] = useState('quarterly');
    const series = useSelector(state => state.selection.series);
    const bubbleSeries = useSelector(state => state.selection.bubbleSeries);
    const compareAxes = useSelector(state => state.selection.compareAxes);
    const loading = useSelector(state => state.selection.loading);
    const dateRange = useSelector(state => state.selection.dateRange);
    const chartVis = useChartVisibility();
    const annualFiltered = useAnnual(series, false);
    const bubbleAnnualFiltered = useAnnual(bubbleSeries, false);

    // This is a nasty thing happening
    const qsParams = qs.parse(search.replace('?', ''));
    const params = search.split('?')[1];

    useEffect(() => {
        const qsParams = qs.parse(search);
        const params = 'combinations=' + qsParams['?combinations'];
        const {xAxis, yAxis, size} = qsParams;
        const axes = {xAxis, yAxis, size};
        dispatch(actions.loadBubble(params, axes));
    }, [dispatch, search]);

    const dateFiltered = annualFiltered.map(c => ({...c,
        values: c.values.filter(v =>
            new Date(v.date) >= dateRange.minDisplay &&
            new Date(v.date) <= dateRange.maxDisplay)}));

    const bubbleDateFiltered = bubbleAnnualFiltered.map(c => ({...c,
        values: c.values.filter(v =>
            new Date(v.date) >= dateRange.minDisplay &&
            new Date(v.date) <= dateRange.maxDisplay)}));

    const allDates = dateFiltered
        .reduce((acc, curr) => ([...acc, ...curr.values]), [])
        .map(v => v.date)
        .filter((v, i, s) => s.indexOf(v) === i)
        .sort()
        .map(v => new Date(v))
    ;

    return series && series.length && compareAxes && !loading
        ? <div>
            {saving
                ? <TemplateFormConnected
                    type="bubble"
                    onComplete={() => setSaving(false)}
                    params={params}
                    higher />
                : <HeaderContainer>
                    <Title>Custom Analytics</Title>
                    <TemplateMenu
                        onSave={() => setSaving(true)}
                        editTo={`/comparison-analytics?${params}`}
                    />
                </HeaderContainer>
            }
            <TopActionElements
                ready={!!series}
                dateRange={dateRange}
                dispatch={dispatch}
                period={period}
                setPeriod={setPeriod}
                bubble
                params={qsParams}
            />

            <Bubble
                series={bubbleDateFiltered}
                compareAxes={compareAxes}
                showNames={series.map(chipNames)}
                chartVis={chartVis}
                dateRange={allDates}
            />

            <BubbleDataTable
                series={dateFiltered
                    .filter(s => !Object.keys(chartVis.muted).find(k => k === s.companyCode))
                }
                compareAxes={compareAxes}
            />

        </div>
        : <ProcessingDataLoader/>
};

export default BubblePage;
