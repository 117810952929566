import * as actionTypes from './actionTypes';
import * as actions from './index';
import format from 'date-fns/format';

export const loadCompanies = () => ({
    type: actionTypes.LOAD_COMPANIES_START,
    meta: {
        api: {
            method: 'GET',
            endpoint: 'api/companies'
        }
    }
});

export const loadSegments = () => ({
    type: actionTypes.LOAD_SEGMENTS_START,
    meta: {
        api: {
            method: 'GET',
            endpoint: 'api/segments'
        }
    }
});

export const loadMetrics = params => {
    return (dispatch, getState) => {
        // This is where we handle the init phase
        // const {selectedLobs} = getState();
        return dispatch({
            type: actionTypes.LOAD_METRICS_START,
            meta: {
                api: {
                    method: 'GET',
                    endpoint: 'api/metrics'
                }
            }
        })
    }
};

export const addSegment = code => ({
    type: actionTypes.ADD_SEGMENT_START,
    meta: {
        api: {
            method: 'GET',
            endpoint: `api/segments/${code}/companies`
        }
    },
    code
});

export const addCompany = code => ({
    type: actionTypes.ADD_COMPANY_START,
    meta: {
        api: {
            method: 'GET',
            endpoint: `api/companies/${code}/segments`
        }
    },
    code
});

export const removeSegment = code => ({
    type: actionTypes.REMOVE_SEGMENT,
    code
});

export const removeCompany = code => ({
    type: actionTypes.REMOVE_COMPANY,
    code
});

export const addCompanyToSegment = (segmentCode, company) => ({
    type: actionTypes.ADD_COMPANY_TO_SEGMENT,
    segmentCode,
    company
});

export const removeCompanyFromSegment = (segmentCode, company) => ({
    type: actionTypes.REMOVE_COMPANY_FROM_SEGMENT,
    segmentCode,
    company
});

export const addSegmentToCompany = (companyCode, segment) => ({
    type: actionTypes.ADD_SEGMENT_TO_COMPANY,
    companyCode,
    segment
});

export const removeSegmentFromCompany = (companyCode, segment) => ({
    type: actionTypes.REMOVE_SEGMENT_FROM_COMPANY,
    companyCode,
    segment
});

export const addMetricToAllPairs = metric => (dispatch, getState) => {
    dispatch({
        type: actionTypes.ADD_METRIC_TO_ALL_PAIRS,
        metric
    });

    const {groupBy, selected} = getState().selection;
    const parent = groupBy.value;
    const children = groupBy.value === 'companies' ? 'selectedSegments' : 'selectedCompanies';
    const combos = selected[parent].reduce((acc, par) => {
        par[children].forEach(chi => {
                if (parent === 'companies') {
                    acc = acc + par.code + ':' + chi.code + ';';
                } else {
                    acc = acc + chi.code + ':' + par.code + ';';
                }
        });
        return acc;
    }, '');

    dispatch({
        type: actionTypes.CONFIRM_METRIC_START,
        code: metric.code,
        meta: {
            api: {
                method: 'GET',
                endpoint: `api/metrics/${metric.code}/available?combinations=${combos.substring(0, combos.length - 1)}`
            }
        }
    });

};

export const openCompanyPanel = code => (dispatch, getState) => {
    const segments = getState().selection.selected.segments;
    const hasCompanies = segments.find(s => s.code === code).companyList;
    if (!hasCompanies) {
        dispatch({
            type: actionTypes.LOAD_COMPANIES_TO_COMPANY_PANEL_START,
            meta: {
                api: {
                    method: 'GET',
                    endpoint: `api/segments/${code}/companies`
                }
            },
            code
        })
    }
    return dispatch({
        type: actionTypes.OPEN_COMPANY_PANEL,
        code
    });
};

export const closeCompanyPanel = () => ({
    type: actionTypes.CLOSE_COMPANY_PANEL
});

export const removeMetricFromPair = (parentCode, childCode, metricCode) => {
    return {
        type: actionTypes.REMOVE_METRIC_FROM_DATA_PAIR,
        parentCode,
        childCode,
        metricCode
    }
};

export const loadAnalytics = qs => {
    return {
        type: actionTypes.LOAD_ANALYTICS_START,
        meta: {
            api: {
                method: 'GET',
                endpoint: `api/analytics?${qs}`
            }
        },
    }
};

export const loadBubble = (qs, axes) => {
    // const today = format(new Date(), 'yyyy-MM-dd');
    // Load Bubble Date is not updating the redux dates (OR the dates are not coming from the selection)
    return {
        type: actionTypes.LOAD_BUBBLE_START,
        meta: {
            api: {
                method: 'GET',
                endpoint: `api/analytics?${qs}`
                // endpoint: `api/analytics?${qs}&from=2016-01-01&to=${today}`
            }
        },
        axes
    }
};

export const setDisplayDate = settings => {
    const {minDisplay, maxDisplay} = settings;
    return {
        type: actionTypes.SET_DISPLAY_DATE,
        minDisplay,
        maxDisplay,
        meta: {
            track: {
                ignore: true
            }
        }
    }
};

export const setDisplayPreset = preset => {
    return {
        type: actionTypes.SET_DISPLAY_PRESET,
        preset
    }
};

export const setType = groupBy => dispatch => {
    dispatch({type: actionTypes.SET_SELECTION_TYPE, groupBy});
    const fn = groupBy.value === 'companies' ? loadCompanies : loadSegments;
    return dispatch(fn());
};

export const toggleLobs = (types, segment) => {
    const typeCodes = types.map(t => t.code);
    const activeLobs = typeCodes.find(t => t === segment.code)
        ? types.filter(t => t.code !== segment.code)
        : [...types, segment];
    return {
        type: actionTypes.SET_LOBS_FOR_SEGMENTS_LINE,
        activeLobs
    };
};

export const setChartView = val => (dispatch, getState) => {
    dispatch({
        type: actionTypes.SET_CHART_VIEW,
        val
    });

    if (val.value === 'bubble') {
        const {bubbleTemplates} = getState().selection;
        if (!bubbleTemplates || !bubbleTemplates.length) {
            dispatch(actions.loadBubbleTemplates());
        }
    } else {
        if (!getState().selection.metrics.length) {
            dispatch(actions.loadMetrics())
        }
    }

};

export const addParent = (groupBy, code) => {
    const action = groupBy === 'companies' ? addCompany : addSegment;
    return action(code);
};

export const removeParent = (groupBy, code) => {
    const action = groupBy === 'companies' ? removeCompany : removeSegment;
    return action(code);
};

export const addChild = (groupBy, parentId, child) => {
    const action = groupBy === 'companies' ? addSegmentToCompany : addCompanyToSegment;
    return action(parentId, child);
};

export const removeChild = (groupBy, parentId, child) => {
    const action = groupBy === 'companies' ? removeSegmentFromCompany : removeCompanyFromSegment;
    return action(parentId, child);
};

export const loadBubbleTemplates = () => {
    return {
        type: actionTypes.LOAD_BUBBLE_TEMPLATES_START,
        meta: {
            api: {
                endpoint: 'api/bubble-templates',
                method: 'GET'
            }
        }
    }
};

export const setBubbleLob = lob => {
    return {
        type: actionTypes.SET_BUBBLE_LOB,
        lob
    }
};

export const setBubbleSegment = segment => {
    return {
        type: actionTypes.SET_BUBBLE_SEGMENT,
        segment
    }
};

export const setBubbleTemplate = template => {
    return dispatch => {
        dispatch(
            {
                type: actionTypes.SET_BUBBLE_TEMPLATE,
                template
            }
        );
        return dispatch({
            type: actionTypes.LOAD_COMPANIES_FOR_TEMPLATE_START,
            meta: {
                api: {
                    endpoint: `api/bubble-templates/${template.id}/companies`
                }
            }
        })
    }
};

export const addBubbleCompany = company => {
    return {
        type: actionTypes.ADD_BUBBLE_COMPANY,
        company
    }
};

export const removeBubbleCompany = company => {
    return {
        type: actionTypes.REMOVE_BUBBLE_COMPANY,
        company
    }
};

export const exportExcel = params => (dispatch, getState) => {

    const {minDisplay, maxDisplay} = getState().selection.dateRange;

    const fromDate = minDisplay ? format(minDisplay, 'yyyy-MM-dd') : '2016-01-01';
    const toDate = maxDisplay ? format(maxDisplay, 'yyyy-MM-dd') : '2019-01-31';
    const combinations = params.combinations;

    return dispatch({
        type: actionTypes.EXPORT_EXCEL_START,
        meta: {
            api: {
                type: 'GET',
                endpoint: `api/analytics/excel?combinations=${combinations}&from=${fromDate}&to=${toDate}`,
                file: true
            }
        }
    })
};

