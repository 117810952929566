import React from 'react';
import styled from "@emotion/styled";

const SelectionContainer = styled.div`
    border-radius: 4px;
    margin-top: 8px;
    box-shadow: ${props => props.theme.shadow3};
    overflow: hidden;
    background: white;
    position: absolute;
    z-index: 10;
    max-height: 300px;
    width: 100%;
    overflow-y: auto;
        &::-webkit-scrollbar {
        width: 6px;
    }
    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px #BDBDBD;
        border-radius: 3px;
        position: relative;
    }
    &::-webkit-scrollbar-thumb {
        background: ${props => props.theme.primaryLight};
        border-radius: 3px;
        cursor: pointer;
    }
`;

const Item = styled.div`
    padding: 16px 24px;
    cursor: pointer;
    &:hover {
        background: ${props => props.theme.primary};
        color: white;
    }
`;

const DropdownableItems = (props) => {
    return (
        <SelectionContainer className="fancy-scroll">
            {props.options.filter(op => op.value !== props.value).map(option =>
                <Item
                    data-cy={props.cy ? `${props.cy}-item` : "dropdownable-item"}
                    key={option.value}
                    onClick={() => props.onSelect(
                        {
                            valueType: props.valueType,
                            option: option,
                            options: props.options,
                            isOrphan: props.isOrphan,
                            id: props.id,
                            placeholder: props.placeholder,
                            isChange: !!props.value
                        }
                    )}>
                    {option.name}
                </Item>
            )}
        </SelectionContainer>
    )
};

export default DropdownableItems;
