import React from 'react';
import styled from '@emotion/styled';

const Handle = styled.div`
    position: absolute;
    top: -4px;
    left: ${props => props.left}px;
    height: 12px;
    width: 12px;
    background: white;
    border: 2px solid ${props => props.theme.primary};
    border-radius: 50%;
`;

const QuadrantTimelineHandle = ({fullWidth, time}) => {
    if (!fullWidth) {
        fullWidth = 1000;
    }

    let percentComplete = fullWidth * time;
    return (
        <Handle left={percentComplete}/>
    )
};

export default QuadrantTimelineHandle;
