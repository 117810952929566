import React from 'react';
import SelectorColumn from "../../components/SelectorColumn/SelectorColumn";
import ColumnTitle from "../../components/ColumnTitle/ColumnTitle";
import FancyScroll from "../../components/FancyScroll/FancyScroll";
import ClickList from "../../components/ClickList/ClickList";
import styled from "@emotion/styled";
import PropTypes from 'prop-types';
import ColumnLoader from "./ColumnLoader/ColumnLoader";
import Input from "../../components/Input/Input";
import useMatchFilter from "../../components/useMatchFilter/useMatchFilter";

const ListContainer = styled.div`
    height: calc(100vh - ${props => props.isCompany ? 300 : 240}px);
`;

const TypeElementsColumn = (props) => {
    const {items, title, active, handleSegmentAdd, type, selectedCodes, loading, hasLocked} = props;
    const [term, setTerm, list] = useMatchFilter(items);

    const parsedTitle = title ? title : `Select ${type || 'elements'}`;

    return (
        <SelectorColumn>
            <ColumnLoader loading={loading} />
            <ColumnTitle>{parsedTitle}</ColumnTitle>

            {type === 'companies'
                ? <div style={{marginBottom: 18, paddingRight: 12}}><Input placeholder="refine..." value={term} onChange={e => setTerm(e.target.value)} /></div>
                : null}

            <FancyScroll color="gray400">
                <ListContainer isCompany={type === 'companies'}>
                    <div style={{paddingRight: 4}}>
                        <ClickList
                            icons
                            withLocked={type === 'segments' || hasLocked}
                            // icons={type === 'segments'}
                            selectedCodes={selectedCodes}
                            active={active}
                            items={type === 'companies' ? list : items || []}
                            onClick={handleSegmentAdd(active)}
                            cy={type ? `chart-element-item-${type}` : 'chart-element-item-bubble'}
                        />
                    </div>
                </ListContainer>
            </FancyScroll>
        </SelectorColumn>
    )
};

TypeElementsColumn.defaultProps = {
    items: [],
    active: [],
    selectedCodes: []
};

TypeElementsColumn.propTypes = {
    items: PropTypes.array,
    active: PropTypes.array,
    handleSegmentAdd: PropTypes.func.isRequired,
    type: PropTypes.string,
    selectedCodes: PropTypes.array,
    title: PropTypes.string,
    loading: PropTypes.bool
};

export default TypeElementsColumn;
