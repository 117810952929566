import React from 'react';
import styled from '@emotion/styled';

const Container = styled.div`
    position: absolute;
    bottom: calc(100% + 6px);
    display: inline-block;
    background: ${props => props.active ? 'transparent' : props.theme.error};
    color: white;
    padding: 8px 24px;
    width: 100%;
    left: 0;
    border-radius: 2px;
    box-sizing: border-box;
    font-size: 14px;
`;

const InputError = (props) => {
    return (
        <Container active={props.active}>{props.children}</Container>
    )
};

export default InputError;
