import React from 'react';
import insightImage from "../Images/INSIGHTCENTER.png";
import * as actionTypes from "../../store/actions/actionTypes";
import styled from "@emotion/styled";
import mapImage from "../Images/Map2x.png";

const Right = styled.div`
    background: ${props => props.theme.primary900};
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url(${mapImage});
    background-size: 180% auto;
    background-repeat: no-repeat;
    background-position: center center;
`;

const LoginRightContainer = (props) => {
    return (
        <Right>
            <div style={{textAlign: 'center'}}>
                {props.error}
                <div><img src={insightImage} alt="Insight Center"/></div>
                {props.children}
                <div onClick={props.send(actionTypes.LOGIN_FAIL)} style={{fontSize: 14, marginTop: 36, color: 'white'}}>Forgot password?</div>
            </div>
        </Right>
    )
};

export default LoginRightContainer;
