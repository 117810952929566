import React from 'react';
import styled from '@emotion/styled';

const Button = styled.button`
    background: ${props => props.theme.primary};
    color: white;
    border-radius: 2px;
    font-size: 12px;
    border: none;
    padding: 4px 10px;
    cursor: pointer;
    margin-top: 24px;
    &:hover {
        background: ${props => props.theme.primaryDark};
    }
`;

const DateFilterOkButton = ({set}) => {
    return (
        <div style={{position: 'absolute', right: 22, top: 10, cursor: 'pointer'}}>
            <Button onClick={() => set(false)}>Ok</Button>
        </div>
    )
};

export default DateFilterOkButton;
