import React from 'react';

const DateRangeSliderContainer = (props) => {
    return (
            <div style={{width: 360, display: 'relative', marginTop: 10, marginLeft: -18}}>
                { props.children }
            </div>
    )
};

export default DateRangeSliderContainer;
