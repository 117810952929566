import * as actionTypes from '../actions/actionTypes';
import colors from '../../styles/chartColors';
import startOfQuarter from 'date-fns/startOfQuarter';
import subMonths from 'date-fns/subMonths';
import {lineSegmentSelection} from './setEdit';
import qs from 'qs';

const initialState = {
    chartView: {value: 'line', name: 'Line'},
    companies: [],
    segments: [],
    metrics: [],
    loading: '',
    groupBy: null,
    selected: {segments: [], companies: []},
    display: {companyPanel: null},
    bubbleTemplates: [],
    selectedBubble: {},
    selectedLobs: [],
    series: [],
    requestedPairs: [],
    dateRange: {},
    init: false,
    error: ''
};

const defaultMax = startOfQuarter(new Date());
const defaultMin = subMonths(defaultMax, 28);

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_CHART_VIEW:
            return {...state, chartView: action.val};

        case actionTypes.LOAD_COMPANIES_START:
            return {
                ...state,
                loading: 'companies',
                // selected: {companies: [], segments: []}
            };
        case actionTypes.LOAD_COMPANIES_SUCCESS:
            return {
                ...state,
                loading: false,
                companies: action.results,
                selectedLobs: []
            };
        case actionTypes.LOAD_SEGMENTS_START:
            return {
                ...state,
                loading: 'segments',
                // selected: {companies: [], segments: []}
            };

        case actionTypes.LOAD_SEGMENTS_SUCCESS:
            // Temp: remove when data has been sanitized and dupes have been removed
            const last = action.results[action.results.length - 1];

            if (last && last.code === 'SecServ1') action.results.pop();

            if (action.results[0] && action.results[0].segments && action.results[0].segments[6]) {
                if (action.results[0].segments[6].code === 'Cld_CldBrk&Int') delete action.results[0].segments[6];
            }

            return {
                ...state,
                loading: false,
                segments: action.results
            };

        case actionTypes.LOAD_SEGMENTS_FAIL:
            return {...state, error: action.error};

        case actionTypes.INIT_SETUP: {
            const {segments, companies, metrics} = state;
            const {selected, selectedLobs} = lineSegmentSelection(action.params, segments, companies, metrics);
            return {
                ...state,
                groupBy: {value: 'segments', name: 'Segments'},
                selected, selectedLobs
            };
        }

        case actionTypes.LOAD_METRICS_START:
            return {...state, loading: 'load-metrics', init: !!action.init};

        case actionTypes.LOAD_METRICS_SUCCESS:
            return {...state, metrics: action.results, loading: false};

        case actionTypes.LOAD_METRICS_FAIL:
            return {...state, loading: false, error: action.error};

        case actionTypes.SET_SELECTION_TYPE:
            return {
                ...state,
                groupBy: action.groupBy,
                selected: {segments: [], companies: []},
                selectedLobs: [],
                init: !!action.init
            };

        case actionTypes.ADD_SEGMENT_START: {
            const flatSegments = state.segments.reduce((acc, curr) => {
                return {
                    ...acc,
                    [curr.code]: {code: curr.code, name: curr.name},
                    ...getMapped(curr)
                }
            }, {});
            const segments = [...state.selected.segments, flatSegments[action.code]];

            return {
                ...state,
                selected: {...state.selected, segments},
                loading: action.code
            };
        }

        case actionTypes.LOAD_COMPANIES_TO_COMPANY_PANEL_START: {
            return {...state, loading: action.code}
        }

        // @todo below 2 are duplicated
        case actionTypes.ADD_SEGMENT_SUCCESS: {
            const segments = state.selected.segments.map(seg => seg.code === action.code
                ? {...seg, companyList: action.results}
                : seg
            );

            return {...state, selected: {...state.selected, segments}, loading: null};
        }

        case actionTypes.LOAD_COMPANIES_TO_COMPANY_PANEL_SUCCESS: {
            const segments = state.selected.segments.map(seg => seg.code === action.code
                ? {...seg, companyList: action.results}
                : seg
            );

            return {...state, selected: {...state.selected, segments}, loading: null};
        }

        case actionTypes.ADD_COMPANY_START: {
            return {...state, loading: 'company-segments'};
        }

        case actionTypes.ADD_COMPANY_SUCCESS: {
            if (state.selected.companies.find(com => com.code === action.code)) return state;

            const company = state.companies.find(c => c.code === action.code);

            const companies = [...state.selected.companies, {
                ...company,
                isGranted: true,
                company: true
            }];
            return {
                ...state,
                selected: {...state.selected, companies},
                selectedLobs: [...state.selectedLobs, {...company, segments: action.results, isGranted: true}],
                loading: null
            };
        }

        case actionTypes.REMOVE_SEGMENT: {
            const segments = state.selected.segments.filter(segment => segment.code !== action.code);
            return {...state,
                selected: {...state.selected, segments},
                error: ''
            };
        }

        case actionTypes.ADD_COMPANY_TO_SEGMENT: {
            const segments = state.selected.segments.map(seg => seg.code === action.segmentCode
                ? {
                    ...seg, selectedCompanies: seg.selectedCompanies
                        ? [...seg.selectedCompanies, action.company]
                        : [action.company]
                }
                : seg
            );
            return {...state, selected: {...state.selected, segments}, error: ''};
        }

        case actionTypes.SET_LOBS_FOR_SEGMENTS_LINE: {
            return {
                ...state, selectedLobs: action.activeLobs, selected: {
                    ...state.selected,
                    segments: state.selected.segments.filter(s => action.activeLobs.find(l => l.code === s.code))
                }
            };
        }

        case actionTypes.REMOVE_SEGMENT_FROM_COMPANY: {
            const selectedCompanies = state.selected.companies.map(co => co.code === action.companyCode
                ? {...co, selectedSegments: co.selectedSegments.filter(s => s.code !== action.segment.code)}
                : co);
            return {...state,
                selected: {...state.selected, companies: selectedCompanies},
                error: ''
            };
        }

        case actionTypes.REMOVE_COMPANY_FROM_SEGMENT: {
            const segments = state.selected.segments.map(seg => seg.code === action.segmentCode
                ? {
                    ...seg, selectedCompanies: seg.selectedCompanies
                        .filter(com => com.id !== action.company.id)
                }
                : seg
            );
            return {...state,
                selected: {...state.selected, segments},
                error: ''
            };
        }

        case actionTypes.ADD_SEGMENT_TO_COMPANY: {

            const companies = state.selected.companies.map(com => com.code === action.companyCode
                ? {
                    ...com,
                    selectedSegments: com.selectedSegments && com.selectedSegments.find(s => s.code === action.segment.code)
                        ? com.selectedSegments.filter(s => s.code !== action.segment.code) : com.selectedSegments
                            ? [...com.selectedSegments, action.segment]
                            : [action.segment]
                } : com
            );
            return {...state, selected: {...state.selected, companies}};
        }

        case actionTypes.ADD_SEGMENTS_TO_COMPANIES: {
            const pairs = qs.parse(action.params).combinations.split(';');

            const companyObjects = pairs
                .map(p => p.split(':')[0])
                .filter((v, i, s) => s.indexOf(v) === i)
                .map(code => state.companies.find(c => c.code === code));

            const addMetricsMap = seg => k => {
                return {
                    ...seg, metrics: pairs
                        .filter(mp => mp.split(':')[0] === k.code && mp.split(':')[1] === seg.code)
                        .map(metricCode => state.metrics.find(met => met.code === metricCode.split(':')[2]))
                };
            };

            const mapSegmentToLob = k => pair => {
                const sCode = pair.split(':')[1];
                // state.selectedLobs includes the top level company in a groupBy company context
                const segments = state.selectedLobs
                    .find(sl => sl.code === k.code).segments;

                const mappedSegments = segments.map(seg => getMapped(seg));
                const activeSegment = segments.find(s => s.code === sCode) || mappedSegments.find(seg => seg[sCode])[sCode];
                return addMetricsMap(activeSegment)(k);
            };

            const mapped = companyObjects.map(k => {
                return {
                    ...k, selectedSegments: pairs
                        .filter(p => p.split(':')[0] === k.code)
                        .map(mapSegmentToLob(k))
                        .reduce((acc, curr) => {
                            return {...acc, [curr.code]: curr}
                        }, {})
                }
            });

            const mappedArray = mapped.map(c => ({
                ...c,
                selectedSegments: Object.entries(c.selectedSegments).map(e => e[1])
            }));

            return {
                ...state,
                selected: {...state.selected, companies: mappedArray},
                init: false
            };
        }

        case actionTypes.REMOVE_COMPANY: {
            const removeWithCode = arr => arr.filter(c => c.code !== action.code);
            const remaining = removeWithCode(state.selected.companies);
            const remainingLobs = removeWithCode(state.selectedLobs);
            return {
                ...state, selected: {
                    ...state.selected,
                    companies: remaining
                },
                selectedLobs: remainingLobs,
                error: ''
            }
        }

        case actionTypes.ADD_METRIC_TO_ALL_PAIRS: {
            const groupBy = state.groupBy.value;
            const children = groupBy === 'companies' ? 'selectedSegments' : 'selectedCompanies';

            const selectedTypes = state.selected[groupBy].map(seg => ({
                ...seg, [children]: seg[children]
                    .map(co => co.requestedMetrics && co.requestedMetrics.length
                        ? {
                            ...co,
                            requestedMetrics: [...co.requestedMetrics,
                                co.requestedMetrics.find(r => r.code === action.metric.code) ||
                                co.metrics.find(r => r.code === action.metric.code) ? null : action.metric]
                                .filter(v => v)
                        }
                        : {
                            ...co, requestedMetrics: co.metrics
                            && co.metrics.find(m => m.code === action.code) ? [] : [action.metric]
                        })
            }));
            return {...state, selected: {...state.selected, [groupBy]: selectedTypes}};
        }

        case actionTypes.CLOSE_ENTRY_ERROR: {
            return {...state, error: ''}
        }

        case actionTypes.CONFIRM_METRIC_FAIL: {
            const groupBy = state.groupBy.value;
            const children = groupBy === 'companies' ? 'selectedSegments' : 'selectedCompanies';
            const selectedTypes = state.selected[groupBy].map(par => ({
                ...par, [children]: par[children].map(chi => ({...chi, requestedMetrics: []}))
            }));
            return {...state,
                selected: {...state.selected, [groupBy]: selectedTypes},
                error: 'Your selection has exceeded the maximum allowable items. Please remove some segments and/or companies and try again'
            }
        }

        case actionTypes.CONFIRM_METRIC_SUCCESS: {
            const groupBy = state.groupBy.value;
            const children = groupBy === 'companies' ? 'selectedSegments' : 'selectedCompanies';
            const codes = {
                parent: groupBy === 'companies' ? 'companyCode' : 'segmentCode',
                child: groupBy === 'companies' ? 'segmentCode' : 'companyCode'
            };

            const results = action.results.results || action.results;

            const matchMetrics = (par, chi) => m => {
                return !chi.metrics.find(e => e.code === action.code) && m.code === action.code
                && inActionResults(results, par.code, chi.code);
            };

            const inActionResults = (arr, parentCode, childCode) => {
                return arr.find(ar => ar[codes.parent] === parentCode && ar[codes.child] === childCode && ar.available);
            };

            const newMetricArray = (arr, parentCode, childCode) => {
                return arr.filter(r => r.code === action.code && inActionResults(results, parentCode, childCode));
            };

            const remainingRequested = (arr, par, chi) => {
                return arr.filter(a => a.code !== action.code || (a.code === action.code && !inActionResults(results, par.code, chi.code)));
            };

            const selectedTypes = state.selected[groupBy].map(par => ({
                ...par, [children]: par[children].map(chi => {
                    return {...chi,
                    metrics: chi.metrics ? [
                        ...chi.metrics,
                        ...chi.requestedMetrics.filter(matchMetrics(par, chi))
                    ] : newMetricArray(chi.requestedMetrics, par.code, chi.code),
                    requestedMetrics: remainingRequested(chi.requestedMetrics, par, chi)
                        .map(m => ({...m, noData: true}))
                }})
            }));

            return {...state,
                selected: {...state.selected, [groupBy]: selectedTypes},
                error: ''
            };
        }

        case actionTypes.REMOVE_METRIC_FROM_DATA_PAIR: {
            const groupBy = state.groupBy.value;
            const children = groupBy === 'companies' ? 'selectedSegments' : 'selectedCompanies';

            const segments = state.selected[groupBy].map(seg => seg.code !== action.parentCode
                ? seg
                : ({
                    ...seg, [children]: seg[children].map(child => child.code !== action.childCode
                        ? child
                        : {
                            ...child, metrics: child.metrics.filter(m => m.code !== action.metricCode
                            )
                        }
                    )
                }));
            return {...state,
                selected: {...state.selected, [groupBy]: segments},
                error: ''
            };
        }

        case actionTypes.OPEN_COMPANY_PANEL:
            return {...state, display: {...state.display, companyPanel: action.code}};

        case actionTypes.CLOSE_COMPANY_PANEL:
            return {...state, display: {...state.display, companyPanel: null}};

        case actionTypes.LOAD_ANALYTICS_START: {
            return {...state, loading: 'analytics-data'};
        }

        case actionTypes.LOAD_ANALYTICS_FAIL: {
            return {...state, loading: false, error: action.error};
        }

        case actionTypes.LOAD_ANALYTICS_SUCCESS: {
            const {values, minDate, maxDate, requestedPairs} = action.results;
            const companyCodes = action.results.values
                .map(v => v.companyCode)
                .filter((v, i, s) => s.indexOf(v) === i);

            const today = new Date();

            const mapped = values
                .sort((a, b) => new Date(a.date) - new Date(b.date))
                .reduce((acc, cur) => {
                    const k = cur.companyCode + '_' + cur.segmentCode + '_' + cur.metricCode;
                    if (!acc[k]) acc[k] = {values: []};
                    return {
                        ...acc, [k]: {
                            ...acc[k],
                            name: cur.metricName,
                            companyName: cur.companyName,
                            segmentName: cur.segmentName,
                            companyCode: cur.companyCode,
                            isProjection: new Date(cur.date) > today,
                            metric: cur.metricCode,
                            type: cur.valueType, // Duplicate
                            valueType: cur.valueType,
                            values: [...acc[k].values, {
                                name: cur['metricName'],
                                id: k,
                                date: new Date(cur.date),
                                value: cur.amount,
                                periodType: cur.periodType
                            }]
                        }
                    }
                }, {});

            const orderByCompany = Object.entries(mapped)
                .map(a => a[1]['companyCode'])
                .reduce((acc, curr, i) => acc[curr]
                    ? {...acc, [curr]: [...acc[curr], i]}
                    : {...acc, [curr]: [i]}, {});

            // @todo these next 3 blocks can be WAY simplified and reduced

            const asArrays = Object.entries(mapped).map((e, i) => ({
                id: e[0],
                name: e[1].name,
                color: colors[companyCodes.findIndex(c => c === e[1].companyCode)],
                lineStyle: orderByCompany[e[1].companyCode].findIndex(c => c === i),
                companyName: e[1].companyName,
                segmentName: e[1].segmentName,
                valueType: e[1].valueType,
                type: e[1].type,
                metric: e[1].metric,
                values: e[1].values.map(v => ({
                    ...v,
                    isProjection: new Date(v.date) > today,
                    date: startOfQuarter(new Date(v.date)),
                    companyName: e[1].companyName,
                    segmentName: e[1].segmentName,
                    metricName: e[1].name,
                    valueType: e[1].valueType,
                    type: e[1].valueType,
                    metric: e[1].metric,
                    periodType: v.periodType
                }))
            }));

            const mappedRequestedPairs = requestedPairs
                .map(r => ({...r, id: `${r.companyCode}_${r.segmentCode}_${r.metricCode}`, name: r.metricName}))
                .map(r => {
                    const inSeries = asArrays.find(a => r.id === a.id);
                    return inSeries ? inSeries : r;
                })
            ;

            return {
                ...state,
                series: asArrays,
                requestedPairs: mappedRequestedPairs,
                loading: false,
                dateRange: {
                    minDate: new Date(minDate),
                    maxDate: new Date(maxDate),
                    minDisplay: defaultMin,
                    maxDisplay: defaultMax,
                    displayType: 'oneYear'
                },
            };
        }

        case actionTypes.LOAD_BUBBLE_START: {
            return {...state, loading: true};
        }

        case actionTypes.LOAD_BUBBLE_FAIL: {
            return {...state, loading: false, error: action.error};
        }

        case actionTypes.LOAD_BUBBLE_SUCCESS: {

            const {minDate, maxDate, values} = action.results;
            const sanitizedValues = values.map(v => ({...v, companyCode: v.companyCode.replace('.', '')}));

            const arrayAxes = Object.entries(action.axes).reduce((acc, curr) => {
                return [...acc, {
                    axis: curr[0],
                    metric: curr[1]
                }];
            }, []);

            const companyGrouped = sanitizedValues.reduce((acc, curr) => {
                const {companyCode, segmentCode, companyName, date, metricCode, amount, valueType, periodType} = curr;
                const companyArray = acc[companyCode] ? acc[companyCode].values : [];
                const mappedValues = arrayAxes
                    .filter(m => m.metric === `${segmentCode}:${metricCode}:${valueType}`)
                    .map(m => ({date, [m.axis]: amount, periodType}));

                return {
                    ...acc, [companyCode]: {
                        // New entries can have multiple axes applied
                        values: [...companyArray, ...mappedValues],
                        companyCode,
                        companyName
                    }
                };
            }, {});

            const companyCodes = Object.keys(companyGrouped);

            const groupByDate = (acc, curr) => {
                return {...acc, [curr.date]: {...acc[curr.date], ...curr}}
            };

            const dateGrouped = companyCodes.map(c => ({
                ...companyGrouped[c],
                values: Object.entries(companyGrouped[c].values
                    .reduce(groupByDate, {})
                ).map(o => o[1])
            }));

            // Sort out values where all values are not available

            const dateSorted = dateGrouped.map(c => ({
                ...c, values: c.values.sort((a, b) => {
                    if (a.date > b.date) return 1;
                    if (a.date < b.date) return -1;
                    return 0;
                })
            }));

            // fill in blank values for date range to avoid gaps
            const allDates = dateGrouped
                .map(g => g.values.map(v => v.date))
                .flat()
                .filter((v, i, s) => s.indexOf(v) === i)
                .sort();

            // Grab just the last value in the list
            const getClosestValues = (values, date) => {
                const lastValue = [...values].reverse().find(val => val.size && val.xAxis && val.yAxis);
                return {...lastValue, date: date, noValue: true, isFuture: date > new Date()};
            };
            const withColors = dateSorted.map((v, i) => ({...v, color: colors[i]}));

            const datesPostFilled = withColors.map(a => ({
                ...a, values: allDates.map(d => {
                    const match = a.values.find(v => v.date === d);
                    return (match && match.xAxis && match.yAxis && match.size) ? match : getClosestValues(a.values, d)
                })
            }));

            // Diagnostics to show TBR
            // console.log('withColors: ', withColors);

            // Include company segment data for axes display
            const axesArray = Object.entries(action.axes).map(e => {
                const axisString = e[1].split(':');
                const match = action.results.values.find(v => v.segmentCode === axisString[0]
                    && v.metricCode === axisString[1] && v.valueType === axisString[2]);

                return {
                    name: match.metricName,
                    segmentName: match.segmentName,
                    segment: axisString[0],
                    metric: axisString[1],
                    type: axisString[2] || 'amount',
                    axis: e[0]
                }
            });

            const compareAxes = axesArray.reduce((acc, curr) => {
                const {axis, name, segmentName, segment, metric, type} = curr;
                return {...acc, [axis]: {name, segment, segmentName, metric, type}}
            }, {});

            return {
                ...state,
                bubbleSeries: datesPostFilled,
                series: withColors,
                compareAxes,
                loading: false,
                dateRange: {
                    minDate: new Date(minDate),
                    maxDate: new Date(maxDate),
                    minDisplay: defaultMin,
                    maxDisplay: defaultMax
                },
            };
        }

        case actionTypes.SET_DISPLAY_DATE:
            const {minDisplay, maxDisplay} = action;
            return {
                ...state, dateRange: {
                    ...state.dateRange,
                    minDisplay,
                    maxDisplay,
                    displayType: null
                }
            };

        case actionTypes.SET_DISPLAY_PRESET: {
            const nowQuarter = startOfQuarter(new Date());
            const ranges = {
                all: {minDisplay: state.dateRange.minDate, maxDisplay: state.dateRange.maxDate},
                oneYear: {minDisplay: subMonths(nowQuarter, 18), maxDisplay: nowQuarter},
                threeYear: {minDisplay: subMonths(nowQuarter, 42), maxDisplay: nowQuarter},
                fiveYear: {minDisplay: subMonths(nowQuarter, 66), maxDisplay: nowQuarter},
                forecasts: {minDisplay: subMonths(nowQuarter, 18), maxDisplay: state.dateRange.maxDate}
            };

            return {
                ...state, dateRange: {
                    ...state.dateRange,
                    minDisplay: ranges[action.preset].minDisplay,
                    maxDisplay: ranges[action.preset].maxDisplay,
                    displayType: action.preset
                }
            }
        }

        case actionTypes.LOAD_BUBBLE_TEMPLATES_START:
            return {...state, loading: 'bubble templates'};

        case actionTypes.LOAD_BUBBLE_TEMPLATES_SUCCESS: {
            const {results} = action;

            // console.log('without something: ',
            // results.filter(res => !res.sizeSegment || !res.sizeMetric ||
            // !res.xSegment || !res.xMetric ||
            // !res.ySegment || !res.yMetric ));

            const grantedResults = results
                .filter(res => res.sizeSegment && res.xSegment && res.ySegment && res.sizeMetric && res.xMetric && res.yMetric)
                .map(res => ({
                    ...res,
                    isGranted: res.sizeSegment.isGranted && res.xSegment.isGranted && res.ySegment.isGranted
                }));

            const mapped = results.map(r => r.lob.code);
            const bubbleLobs = mapped
                .filter((v, i, s) => s.indexOf(v) === i)
                .map(code => results.find(r => r.lob.code === code)).map(r => r.lob);

            return {
                ...state,
                loading: false,
                bubbleTemplates: grantedResults,
                bubbleLobs,
                selectedBubble: {lob: {}}
            };
        }

        case actionTypes.LOAD_BUBBLE_TEMPLATES_FAIL:
            return {...state, loading: false, error: action.error};

        case actionTypes.SET_BUBBLE_LOB: {
            const bubbleSegments = state.bubbleTemplates
                .filter(s => s.lob.code === action.lob.code)
                .map(s => s.segment.code)
                .filter((v, i, s) => s.indexOf(v) === i)
                .map(c => state.bubbleTemplates.find(t => t.segment.code === c))
                .map(t => t.segment)
            ;

            return {
                ...state,
                selectedBubble: {lob: action.lob},
                bubbleChoices: [],
                bubbleCompanies: [],
                bubbleSegments
            };
        }

        case actionTypes.SET_BUBBLE_SEGMENT: {
            return {
                ...state,
                selectedBubble: {
                    ...state.selectedBubble,
                    segment: action.segment,
                    template: null,
                    companies: []
                },
                bubbleChoices: state.bubbleTemplates.filter(s => s.segment.code === action.segment.code),
                bubbleCompanies: []
            };
        }

        case actionTypes.SET_BUBBLE_TEMPLATE: {
            return {
                ...state,
                selectedBubble: {
                    ...state.selectedBubble, template: action.template, companies: []
                }
            }
        }

        case actionTypes.LOAD_COMPANIES_FOR_TEMPLATE_START: {
            return {...state, loading: 'bubble-companies', bubbleCompanies: []};
        }

        case actionTypes.LOAD_COMPANIES_FOR_TEMPLATE_SUCCESS: {
            return {
                ...state,
                loading: false,
                bubbleCompanies: action.results
            };
        }

        case actionTypes.ADD_BUBBLE_COMPANY: {
            return {
                ...state, selectedBubble: {
                    ...state.selectedBubble, companies: [...state.selectedBubble.companies, action.company]
                }
            }
        }

        case actionTypes.ADD_BUBBLE_COMPANIES: {
            const companies = action.companyCodes.map(c => state.bubbleCompanies.find(b => b.code === c));

            return {
                ...state, selectedBubble: {
                    ...state.selectedBubble, companies: companies
                }
            }
        }

        case actionTypes.REMOVE_BUBBLE_COMPANY: {
            return {
                ...state, selectedBubble: {
                    ...state.selectedBubble,
                    companies: state.selectedBubble.companies.filter(c => c.code !== action.company.code)
                }
            }
        }

        case actionTypes.EXPORT_EXCEL_START: {
            return {...state, loading: 'export'};
        }

        case actionTypes.EXPORT_EXCEL_SUCCESS: {
            return {...state, loading: false};
        }

        case actionTypes.EXPORT_EXCEL_FAIL: {
            return {...state, loading: false, error: action.error};
        }

        default:
            return state;
    }
};

export default reducer;

function getMapped(s, memo = {}) {
    s.segments.forEach(seg => {
        if (!seg) return;
        memo[seg.code] = {code: seg.code, name: seg.name};
        getMapped(seg, memo);
    });
    return memo;
}
