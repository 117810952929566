import React, {Fragment} from 'react';
import SelectorColumn from "../../components/SelectorColumn/SelectorColumn";
import FancyScroll from "../../components/FancyScroll/FancyScroll";
import ClickList from "../../components/ClickList/ClickList";
import * as actions from "../../store/actions";
import styled from "@emotion/styled";
import ColumnTitle from "../../components/ColumnTitle/ColumnTitle";
import PropTypes from 'prop-types';

const MetricsContainer = styled.div`
    height: calc(100vh - 260px);
`;

const MetricColumn = (props) => {
    const {selection, dispatch, groupBy} = props;
    const isActive = hasAllSets(selection, groupBy);

    const headers = selection.metrics
        .map(m => m.metricType)
        .filter((v, i, s) => s.indexOf(v) === i)
        .reverse();

    return (
        <SelectorColumn lastColumn>
            <div style={{
                opacity: isActive ? 1 : .3,
                pointerEvents: isActive ? 'auto' : 'none'
            }}>
                <ColumnTitle>Select metrics</ColumnTitle>
                <div style={{marginTop: 24}}>
                    <FancyScroll color="gray300">
                        <MetricsContainer>
                            <div style={{paddingRight: 6, paddingLeft: 12}}>
                                {headers.map((h, i) =>
                                <Fragment key={h}>
                                    <div style={{fontWeight: 700, marginBottom: 14, marginTop: !i ? 0 : 22}}>
                                        {h}
                                    </div>
                                    <ClickList
                                        items={selection.metrics.filter(m => m.metricType === h)}
                                        onClick={m => dispatch(actions.addMetricToAllPairs(m))}
                                        cy="metrics-selector"
                                        tight
                                    />
                                </Fragment>
                                )}
                            </div>
                        </MetricsContainer>
                    </FancyScroll>
                </div>
            </div>
        </SelectorColumn>
    )
};

export default MetricColumn;

MetricColumn.propTypes = {
    groupBy: PropTypes.string,
    comparison: PropTypes.bool,
    selection: PropTypes.object,
    dispatch: PropTypes.func
};

const opposite = {
    companies: 'selectedSegments',
    segments: 'selectedCompanies'
};

function hasAllSets(selection, groupBy) {
    if (!selection.selected[groupBy] || !selection.selected[groupBy].length) return false;
    return !selection.selected[groupBy].find(p => !p[opposite[groupBy]] || !p[opposite[groupBy]].length);
}
