export default store => next => action => {
    next(action);
    const isSuccess = action.type.includes('_SUCCESS');
    const hasAfter = action.meta && action.meta.api && action.meta.api.dispatchAfter;

    if (isSuccess && hasAfter) {
        const params = action.meta.api.dispatchAfterParams;
        store.dispatch(action.meta.api.dispatchAfter(params));
    }
}
