import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from "react-redux";
import * as actions from '../store/actions/index';

import styled from '@emotion/styled';
import FancyScroll from "../components/FancyScroll/FancyScroll";
import SelectorColumn from "../components/SelectorColumn/SelectorColumn";
import SegmentSelectBlock from "../components/SegmentSelectBlock/SegmentSelectBlock";
import SegmentCompanySelectBox from "../components/SegmentCompanySelectBox/SegmentCompanySelectBox";
import MetricColumn from "./Comparison/MetricColumn";
import ColumnTitle from "../components/ColumnTitle/ColumnTitle";
import TopSelection from "./Comparison/TopSelection";
import TypeElementsColumn from "./Comparison/TypeElementsColumn";
import ComparisonRedirect from "./Comparison/ComparisonRedirect";
import ArrowColumn from "../components/SelectorColumn/ArrowColumn";
import ColumnLoader from "./Comparison/ColumnLoader/ColumnLoader";
import ClickList from "../components/ClickList/ClickList";
import BubbleSelectorItem from "../components/BubbleSelectorItem/BubbleSelectorItem";
import ComparisonError from "./Comparison/ComparisonError";

const ListContainer = styled.div`
    height: calc(100vh - 240px);
`;

const ColumnsContainer = styled.div`
    display: flex; 
    width: 100%; 
    align-items: center;
`;

const selectOptions = [
    {value: 'segments', name: 'Segments'},
    {value: 'companies', name: 'Companies'}
];
const chartViewOptions = [
    {value: 'line', name: 'Line'},
    {value: 'bubble', name: 'Bubble'}
];

const Comparison = (props) => {

    const [drawing, setDrawing] = useState(false);

    const selection = useSelector(state => state.selection);
    const activeSelected = useSelector(state => state.selection.selected);

    // Array of all selections with depth flattened
    const activeSelectedType = selection.groupBy ? activeSelected[selection.groupBy.value] : [];

    // Top level only, not too important for companies -- what is this for?
    // @todo answer this
    const activeTopCodes = selection.groupBy ? activeSelected[selection.groupBy.value]
        .filter(s => s.code)
        .map(s => Array.isArray(s.code.split('_')) ? s.code.split('_')[0] : s.code).filter((v, i, s) => s.indexOf(v) === i) : [];

    const dispatch = useDispatch();

    const params = props.location.search.replace('?', '');

    const setChartView = val => dispatch(actions.setChartView(val));

    useEffect(() => {
        if (params) {
            dispatch(actions.initSetup(params));
        } else {
            // Necessary for when coming from chart, metrics have not yet been loaded
            if (!selection.metrics.length) {
                dispatch(actions.loadMetrics(params));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, params]);

    const handleAddLob = types => segment => {
        dispatch(actions.toggleLobs(types, segment));
    };

    const handleAddCompany = types => company => {
        const func = activeTopCodes.find(c => c === company.code) ? actions.removeParent : actions.addParent;
        dispatch(func(selection.groupBy.value, company.code));
    };

    const handleBubbleLobAdd = () => lob => {
        dispatch(actions.setBubbleLob(lob));
    };

    return (
        <div style={{width: '100%', position: 'relative'}}>
            {selection.error ? <ComparisonError message={selection.error} dispatch={dispatch}/> : null}
            {drawing ? <ComparisonRedirect
                selected={selection.chartView.value === 'bubble' ? selection.selectedBubble : selection.selected}
                selectedBubble={selection.selectedBubble}
                type={selection.chartView.value === 'bubble' ? 'bubble' : 'line-' + selection.groupBy.value}/> : null}

            {/*@todo this can be streamlined*/}
            <TopSelection
                setType={groupBy => dispatch(actions.setType(groupBy))}
                selectOptions={selectOptions}
                type={selection.groupBy}
                onDrawChart={setDrawing}
                chartViewOptions={chartViewOptions}
                chartView={selection.chartView}
                setChartView={setChartView}
                selected={selection.selected}
                selectedBubble={selection.selectedBubble}
            />

            <ColumnsContainer>
                {/*Column 1*/}
                {selection.chartView.value === 'bubble'
                    ? <TypeElementsColumn
                        items={selection.bubbleLobs}
                        active={[selection.selectedBubble.lob] || []}
                        hasLocked
                        handleSegmentAdd={handleBubbleLobAdd}
                        type={null}
                        title="Select LOB"
                        loading={selection.loading === 'bubble templates'}
                    />
                    :
                    <TypeElementsColumn
                        items={selection.groupBy && selection.groupBy.value && selection[selection.groupBy.value]}
                        active={selection.selectedLobs}
                        selectedCodes={activeTopCodes}
                        handleSegmentAdd={selection.groupBy && selection.groupBy.value
                        && selection.groupBy.value === 'segments' ? handleAddLob : handleAddCompany}
                        type={selection.groupBy && selection.groupBy.value}
                        loading={!!['segments', 'companies'].find(l => l === selection.loading)}
                    />
                }

                <ArrowColumn/>

                {/*Column 2*/}
                <SelectorColumn>
                    <ColumnLoader loading={selection.loading === 'company-segments'}/>
                    <ColumnTitle>
                        {selection.chartView.value === 'bubble' ? 'Select Segment' : selection.groupBy ? selection.groupBy.value === 'companies'
                            ? 'Select segments'
                            : 'Configure segments' : 'Configure chart elements'
                        }
                    </ColumnTitle>

                    <FancyScroll color="gray400">
                        {selection.chartView.value === 'bubble'
                            ? selection.bubbleSegments ? <ListContainer>
                                <div style={{paddingRight: 4}}>
                                    <ClickList
                                        items={selection.bubbleSegments}
                                        onClick={segment => dispatch(actions.setBubbleSegment(segment))}
                                        title="Select segment"
                                        active={selection.selectedBubble.segment ? [selection.selectedBubble.segment] : []}
                                        loading={false}
                                        icons
                                        cy="bubble-select-segment"
                                    />
                                </div>
                            </ListContainer> : null
                            : <ListContainer>
                                {selection.selectedLobs.map(a => <SegmentSelectBlock
                                    key={a.code}
                                    {...a}
                                    selected={activeSelectedType}
                                    handleAdd={segment => selection.groupBy.value === 'segments'
                                        ? dispatch(actions.addSegment(segment.code))
                                        : dispatch(actions.addSegmentToCompany(a.code, segment))}
                                    handleRemove={obj => selection.groupBy.value === 'segments'
                                        ? dispatch(actions.removeSegment(obj.code))
                                        : dispatch(actions.removeChild(selection.groupBy.value, a.code, obj))}
                                    handleRemoveParent={code => dispatch(actions.removeParent('companies', code))}
                                    type={selection.groupBy.value}
                                />)}
                            </ListContainer>
                        }
                    </FancyScroll>

                </SelectorColumn>

                <ArrowColumn/>

                {/*Column 3*/}
                <SelectorColumn>
                    <ColumnTitle>Your chart elements</ColumnTitle>
                    <FancyScroll color="gray400">
                        <ListContainer>

                    {selection.chartView.value === 'bubble'
                        ? <div style={{paddingRight: 4}}>
                                    {!selection.bubbleChoices ? null : selection.bubbleChoices.map(bc =>
                                        <BubbleSelectorItem
                                            onClick={() => dispatch(actions.setBubbleTemplate(bc))}
                                            active={selection.selectedBubble.template && selection.selectedBubble.template.id === bc.id}
                                            key={bc.id} {...bc}/>)}
                                </div>
                        : activeSelectedType.map((sel, i) => (
                            <SegmentCompanySelectBox
                                groupBy={selection.groupBy.value}
                                isLoading={selection.loading === sel.code}
                                key={sel.code}
                                selected={sel}
                                onAddChild={company => dispatch(actions.addChild(selection.groupBy.value, sel.code, company))}
                                onRemoveParent={code => dispatch(actions.removeParent(selection.groupBy.value, code))} // pass with group by
                                onRemoveChild={(p, c) => dispatch(actions.removeChild(selection.groupBy.value, p, c))} // pass with group by
                                position={i}
                                openCompanyPanel={selection.display.companyPanel}
                                onOpenCompanyPanel={() => dispatch(actions.openCompanyPanel(sel.code))}
                                onCloseCompanyPanel={() => dispatch(actions.closeCompanyPanel())}
                                onRemoveMetric={(s, c, m) => dispatch(actions.removeMetricFromPair(s, c, m))}
                            />))}
                        </ListContainer>
                        </FancyScroll>
                </SelectorColumn>

                {/*Column 4*/}
                <ArrowColumn left/>
                {selection.chartView.value === 'bubble'
                    ? <SelectorColumn>
                        <ColumnLoader loading={selection.loading === 'bubble-companies'}/>
                        <ColumnTitle>Select Companies</ColumnTitle>
                        <FancyScroll color="gray400">
                            <ListContainer>
                                <div style={{paddingRight: 4}}>
                                    <ClickList
                                        icons
                                        selectedCodes={selection.selectedBubble && selection.selectedBubble.companies ? selection.selectedBubble.companies.map(c => c.code) : []}
                                        items={selection.bubbleCompanies || []}
                                        active={selection.selectedBubble.companies || []}
                                        onClick={item => selection.selectedBubble &&
                                        selection.selectedBubble.companies.find(c => c.code === item.code)
                                            ? dispatch(actions.removeBubbleCompany(item))
                                            : dispatch(actions.addBubbleCompany(item))}
                                        cy="bubble-select-company"
                                    />
                                </div>
                            </ListContainer>
                        </FancyScroll>
                    </SelectorColumn>
                    : <MetricColumn
                        groupBy={selection.groupBy && selection.groupBy.value}
                        selection={selection}
                        dispatch={dispatch}
                    />}

            </ColumnsContainer>
        </div>
    )
};

export default Comparison;
