import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const Container = styled.div`
    display: flex;
    align-items: center;
    margin-right: 60px;
    div {
        padding: 0 6px;
        cursor: pointer;
    }
    path {
        fill: ${props => props.theme.primary};
    }
`;

const QuadrantVideoControls = ({playing, setPlaying, setTimeMarker}) => {
    return (
        <Container>
            <div onClick={() => setTimeMarker('begin')}>
                <FontAwesomeIcon icon={['fas', 'fast-backward']} />
            </div>

            <div onClick={() => setTimeMarker('back')}>
                <FontAwesomeIcon icon={['fas', 'step-backward']} />
            </div>

            <div onClick={() => setPlaying(!playing)}>
                <FontAwesomeIcon icon={['fas', playing ? 'pause-circle' : 'play-circle']} size="2x" />
            </div>

            <div onClick={() => setTimeMarker('forward')}>
                <FontAwesomeIcon icon={['fas', 'step-forward']} />
            </div>

            <div onClick={() => setTimeMarker('end')}>
                <FontAwesomeIcon icon={['fas', 'fast-forward']} />
            </div>
        </Container>
    )
};

QuadrantVideoControls.propTypes = {
    playing: PropTypes.bool.isRequired,
    setPlaying: PropTypes.func.isRequired
};

export default QuadrantVideoControls;
