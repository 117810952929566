import React from 'react';
import styled from '@emotion/styled';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Wrapper = styled.div`
    position: relative;
    z-index: 10;
`;

const Container = styled.div(({theme, active}) =>`
    position: absolute;
    cursor: pointer;
    top: 462px;
    left: 98px;
    z-index: 20;
    font-size: 14px;
    color: ${active ? theme.primary : theme.gray300};
    path {
        fill: ${active ? theme.primary : theme.gray300};
    }
    span {
        padding-left: 6px;
    }
    &:hover {
        color: ${active ? theme.highlight : theme.gray400};
        path {
            fill: ${active ? theme.highlight : theme.gray400};
        }
    }
`);


const GhostingToggle = (props) => {
    const {ghosted, setGhosted} = props;

    const handleClick = () => {
        setGhosted(!ghosted)
    };

    return (
        <Wrapper>
            <Container onClick={handleClick} active={ghosted}>
                <FontAwesomeIcon icon={['fas', 'layer-group']} size="lg"/>
                <span>{ghosted ? 'Hide' : 'Show'} ghosted layers</span>
            </Container>
        </Wrapper>
    )
};

export default GhostingToggle;
