import React, {useState} from 'react';
import Dropdownable from "../../components/Dropdownable/Dropdownable";
import Button from "../../components/Button/Button";
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import TemplateMenu from "../../components/TemplateMenu/TemplateMenu";
import TemplateFormConnected from "../../components/TemplateForm/TemplateFormConnected";
import ChartTypeToggle from "./ChartTypeToggle";
import InstructTip from "../../components/InstructTip/InstructTip";

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
    margin-top: 0;
`;

const TopSelection = (props) => {
    const {setType, selectOptions, chartViewOptions, type, chartView, onDrawChart, setChartView, selected, selectedBubble} = props;


    const [saving, setSaving] = useState(false);

    const childrenKey = type && type.value === 'segments'
        ? 'selectedCompanies' : 'selectedSegments';

    // @todo Turn this into a hook so we can more easily test
    const isDisabled = !(type && selected[type.value]
        && selected[type.value].length
        && !selected[type.value].find(s => !s[childrenKey] || !s[childrenKey].length || s[childrenKey].find(c => !c.metrics || c.metrics.length === 0)));

    const bubbleDisabled = chartView.value === 'bubble'
        ? !selectedBubble.template || !selectedBubble.companies.length : false;

    const showTips = chartView.value === 'line' && !type;

    return (
        <Wrapper>
            {saving ? <TemplateFormConnected
                    onComplete={() => setSaving(false)}
                    type={chartView.value === 'bubble' ? 'bubble' : 'line-' + type.value}
                /> :
                <>
                    <div style={{display: 'flex', alignItems: 'center', minHeight: 58}}>
                        <ChartTypeToggle
                            chartView={chartView}
                            setChartView={setChartView}
                            chartViewOptions={chartViewOptions}
                            showTips={showTips}
                        />

                        {chartView.value === 'line'
                            ? <div style={{position: 'relative'}}>
                                <InstructTip show={showTips} text="To create a line chart, select an option" />
                                <Dropdownable
                                    placeholder="Choose chart type..."
                                    onSelect={(val) => setType(val.option)}
                                    handleClear={() => console.log('clear')}
                                    options={selectOptions}
                                    value={type}
                                    cy={`drop-type`}
                                />
                            </div>
                            : null
                        }

                        <div style={{marginLeft: 24, marginTop: -12}}>
                            <Button
                                disabled={chartView.value === 'bubble' ? bubbleDisabled : isDisabled}
                                cy="button-draw-chart"
                                onClick={() => onDrawChart(true)}>Draw Chart</Button>
                        </div>
                    </div>
                    <TemplateMenu
                        saveDisabled={chartView.value === 'bubble' ? bubbleDisabled : isDisabled}
                        onSave={() =>setSaving(true)}
                    />
                </>
            }
        </Wrapper>
    )
};

TopSelection.propTypes = {
    setType: PropTypes.func,
    selectOptions: PropTypes.array,
    chartViewOptions: PropTypes.array,
    type: PropTypes.object,
    chartView: PropTypes.object,
    selection: PropTypes.object

};

export default TopSelection;
