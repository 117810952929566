import React from 'react';
// import Tooltip from "rc-tooltip";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import styled from "@emotion/styled";

const ShowContainer = styled.div`
    cursor: pointer;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    border: 1px solid white;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    path {
        stroke: ${({pendingRemove, theme}) => pendingRemove ? theme.errorDark : theme.primaryDark};
        stroke-width: 2.4pc;
    }
`;

const ShowHideCompanyListIcon = (props) => {
    const {showList, setShowList, pendingRemove} = props;

    return (
            <ShowContainer onClick={setShowList} pendingRemove={pendingRemove} data-cy="toggle-company-list">
                <FontAwesomeIcon icon={['fas', `${showList ? 'minus' : 'plus'}`]} color="white" size="lg"/>
            </ShowContainer>
    )
};

export default ShowHideCompanyListIcon;
