import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const Wrapper = styled.div`
    position: absolute;
    ${props => props.bottom 
        ? `bottom: 0;` 
        : `top: 0;`
    }
    width: 500px;
    z-index: 1;
    left: -18px;
`;

const Container = styled.div`
    position: absolute;
    ${props => props.bottom 
        ? `top: ` 
        : `bottom: `
    }12px;
`;

const InnerContainer = styled.div`
    position: relative;
    padding: 18px 48px 18px 24px;
    background: ${props => props.theme.primary};
    display: inline-block;
    color: white;
    font-size: 16px;
    border-radius: 4px;
    box-shadow: ${props => props.theme.shadow2};
    &:after {
        border-left: solid transparent 10px;
        border-right: solid transparent 10px;
        border-${props => props.bottom ? 'bottom' : 'top'}: solid ${props => props.theme.primary} 10px;
        ${props => props.bottom ? 'top' : 'bottom'}: -8px;
        content: " ";
        height: 0;
        left: 36px;
        margin-left: -8px;
        position: absolute;
        width: 0;
    }
}
    
    
`;

const InstructTip = (props) => {

    return props.show ?
        <Wrapper bottom={props.bottom}>
            <Container bottom={props.bottom}>
                <InnerContainer bottom={props.bottom}>
                    {props.text}
                </InnerContainer>
            </Container>
        </Wrapper>
    : null;
};

InstructTip.propTypes = {
    text: PropTypes.string,
    show: PropTypes.bool,
    bottom: PropTypes.bool
};

export default InstructTip;
