import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import {label} from '../../utilities/formatter';

const Container = styled.div`
    display: flex;
    color: ${props => props.theme.primary};
    font-size: 12px;
    font-weight: 700;
    position: absolute;
    top: 12px;
    left: 12px;
    svg {
        margin: 0 6px;
    }
    path {
        fill: ${props => props.theme.primary};
    }
`;

const CircleSizeLabel = (props) => {
    return (
        <Container>
            <div>Size of circle</div>
            <FontAwesomeIcon size="lg" icon={['fas', 'circle']} />
            <div>{props.unit.segmentName} {props.unit.name} <span style={{fontSize: 10}}> ( {label(props.unit).label} ) </span></div>
        </Container>
    )
};

CircleSizeLabel.propTypes = {
    unit: PropTypes.object.isRequired
};

export default CircleSizeLabel;
