import React, {useEffect} from 'react';
import {scaleLinear} from "d3-scale";
import QuadrantChart from "./QuadrantChart";
import QuadrantAxes from "./QuadrantAxes";

const margin = {top: 35, right: 10, bottom: 10, left: 30};

const QuadrantChartView = ({yExtent, rExtent, xExtent, width, height, chartVis, timeline,
                               idKey, ghosted, showTip, data, countClamper, axes}) => {

    const scales = {
        x: scaleLinear().domain(xExtent).range([margin.left, width - margin.right]),
        y: scaleLinear().domain(yExtent).range([height - margin.bottom - margin.top, margin.top]).nice(),
        r: scaleLinear().domain(rExtent).range([10, 50])
    };

    useEffect(() => {
        scales.x = scaleLinear().domain(xExtent).range([margin.left, width - margin.right]);
        scales.y = scaleLinear().domain(yExtent).range([height - margin.bottom - margin.top, margin.top]).nice();
        scales.z = scaleLinear().domain(rExtent).range([10, 50]);
    }, [width, height, rExtent, yExtent, xExtent, scales.x, scales.y, scales.z]);

    return (
                <svg width={width} height={height} style={{position: 'absolute', background: 'white', fontFamily: 'Helvetica Neue, sans-serif'}} id="chart">
                    {countClamper.displayed.filter(d => !Object.keys(chartVis.muted)
                        .find(mute => mute === d[idKey])).map((d, i) => (
                        <QuadrantChart
                            key={d.companyCode}
                            data={d.values}
                            scales={scales}
                            playing={timeline.playing}
                            color={d.color}
                            ghosted={ghosted}
                            id={d[idKey]}
                            time={timeline.time}
                            hasTip={chartVis.tip && chartVis.tip.id === d[idKey]}
                            showTip={showTip}
                            tip={chartVis.tip}
                            // timeMarker={timeline.timeMarker}
                            setTipPosition={chartVis.showTip}
                            width={width}
                        />
                    ))}
                    <QuadrantAxes
                        axes={axes}
                        data={data}
                        width={width}
                        height={height}
                        margin={margin}
                        xExtent={xExtent}
                        yExtent={yExtent}
                    />
                </svg>
    )
};

QuadrantChartView.defaultProps = {
    width: 500, height: 500
};

export default QuadrantChartView;
