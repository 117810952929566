import * as actionTypes from './actionTypes';

export const loadTemplates = (type='line-all') => {
    return {
        type: actionTypes.LOAD_TEMPLATES_START,
        meta: {
            api: {
                method: 'GET',
                endpoint: `api/savedAnalytics/${type}`
            }
        }
    }
};

export const saveTemplate = (name, params, type='line-all') => {
    return {
        type: actionTypes.SAVE_TEMPLATE_START,
        meta: {
            api: {
                method: 'POST',
                endpoint: 'api/savedAnalytics',
                payload: {name, type, parameters: params}
            }
        }
    }
};

export const updateTemplate = (id, payload) => {
    return {
        type: actionTypes.DELETE_TEMPLATE_START,
        meta: {
            api: {
                method: 'DELETE',
                endpoint: `api/savedAnalytics/${id}`,
                payload
            }
        }
    }
};

export const deleteTemplate = id => {
    return {
        type: actionTypes.DELETE_TEMPLATE_START,
        meta: {
            api: {
                method: 'DELETE',
                endpoint: `api/savedAnalytics/${id}`
            }
        },
        id
    }
};
