import React from 'react';
import colors from "../../../styles/theme";
import PropTypes from 'prop-types';
import {timeFormat} from 'd3-time-format';

const labelStyle = {
    // height: 32,
    top: 0,
    color: colors.primary,
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 10,
    textTransform: 'uppercase',
    userSelect: 'none'
};


const SliderLabel = ({position, value, type, width}) => {

    const getDateText = () => {
        const dateFormat = timeFormat('%b %Y');
        return dateFormat(value);
    };

    const borderStyle = {
        borderLeft: type === 'from' ? 'none' : `1px solid ${colors.primary}`,
        borderRight: type === 'from' ? `1px solid ${colors.primary}` : 'none'
    };

    return (
        <div style={{
            ...labelStyle,
            ...borderStyle,
            left: position,
            width: width
        }}>{getDateText()}</div>
    )
};

SliderLabel.defaultProps = {
   position: 0
};

SliderLabel.propTypes = {
    position: PropTypes.number.isRequired,
    value: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
    startDate: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    width: PropTypes.number
};

export default SliderLabel;
