import React from 'react';
import styled from '@emotion/styled';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PropTypes from 'prop-types';

const Container = styled.div`
    font-size: ${({circle, locked}) => circle && locked ? 13 : circle ? 12 : locked ? 12 : 9}px;
    margin-right: 6px;
    height: ${props => props.circle ? 16 : 14}px;
    width: ${props => props.circle ? 16 : 14}px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    flex-grow: 0;
    cursor: ${props => props.locked ? 'default' : 'pointer'};
    border-radius: ${props => props.circle ? '50%' : '2px'};
    border: 1px solid ${({locked, theme, selected, removing, remove}) => remove ? 'white' 
        : removing ? 'white' 
        : locked ? 'transparent' 
        : selected ? 'white' 
        : theme.primary
    };
    path {
        opacity: ${({empty, locked}) => empty && !locked ? 0 : 1 };
        fill: ${({theme, locked, circle, selected, remove}) => remove ? 'white' 
            : locked ? theme.gray600 
            : selected ? 'white'
            : theme.primary
        };
        stroke: ${({selected, theme, locked, circle, remove, removing}) => removing ? theme.errorDark 
                : remove ? theme.primaryDark 
                : selected && !locked ? theme.primary 
                : locked ? theme.gray400 
                : 'white'};
        stroke-width: ${props => props.remove ? '2.4pc' : props.locked ? '0' : '34.4'};
    }
    ${props => !props.locked && !props.remove && `
        &:hover path {
            fill: props.theme.highlight; // this is wrong
            stroke: ${props.theme.highlight};
        }
    `};
`;

const CircleIcon = (props) => {
    return (
        <Container
            {...props}
            onMouseEnter={() => props.handleOver ? props.handleOver(true) : null}
            onMouseLeave={() => props.handleOver ? props.handleOver(false) : null}
        >
            <FontAwesomeIcon icon={['fas', props.remove ? 'times' : props.locked ? 'lock' : props.checked ? 'check' : 'chevron-right']} />
        </Container>
    )
};

CircleIcon.propTypes = {
    locked: PropTypes.bool,
    checked: PropTypes.bool,
    empty: PropTypes.bool,
    selected: PropTypes.bool,
    circle: PropTypes.bool,
    remove: PropTypes.bool,
    removing: PropTypes.bool,
    handleOver: PropTypes.func,
    onClick: PropTypes.func
};

export default CircleIcon;
