import React from 'react';
import ContentRoutes from "../ContentRoutes";

const Content = (props) => {
    return (
        <div style={{padding: 24}}>
            <ContentRoutes/>
        </div>
    )
};

export default Content;
