import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const hoverColor = ({error, theme}) => error ? theme.error : theme.highlight;

const Container = styled.input`
    outline: none;
    width: 100%;
    padding: 8px 24px;
    box-sizing: border-box;
    border-radius: 2px;
    &::placeholder {
        color: ${props => props.theme.gray400};
    }
    border: ${({noBorder, theme, error}) => noBorder ? 0 : `${theme.inputBorderWidth}px solid ${error ? theme.error : theme.inputBorderColor}`};
    &:focus {
        border-color: ${hoverColor};
        box-shadow: 0 0 0 .1pt ${hoverColor};
        &::placeholder {
            color: ${props => props.theme.highlight};
        }
    }
`;

const Input = (props) => {
    return (
        <Container data-cy={props.cy} {...props} />
    )
};

Input.propTypes = {
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    noBorder: PropTypes.bool,
    error: PropTypes.bool,
    cy: PropTypes.string
};

export default Input;
