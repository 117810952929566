import React from 'react';
import ResizeDetector from "react-resize-detector";

const ChartBase = (props) => {
    return (
        <div style={{position: 'relative'}}>

            <div style={{width: '100%', height: 500, fontFamily: 'Helvetica Neue, sans-serif'}}>
                <ResizeDetector handleWidth>
                    {React.Children.map(props.children, (child, i) => {
                        return i === 0 ? child : null;
                    })}
                </ResizeDetector>
            </div>
            {React.Children.map(props.children, (child, i) => {
                return i > 0 ? child : null;
            })}
        </div>
    )
};

export default ChartBase;
