import {useState, useEffect} from 'react';

const useTimelineControls = (dateSteps) => {
    const [steps, setSteps] = useState(dateSteps);
    const [playing, setPlaying] = useState(true);
    const [time, setTime] = useState(0);
    const [timeMarker, setTimeMarker] = useState(0);
    const [markerIndex, setMarkerIndex] = useState(0);

    useEffect(() => {
        setSteps(dateSteps);
    }, [dateSteps]);

    const setMarker = (direction) => {
        const stepPercent = 1 / steps;
        const lastStep = Math.floor(time * steps) / steps;

        setPlaying(false);

        switch(direction) {
            case 'begin':
                setTime(0);
                setIndex(0);
                return setTimeMarker(0);
            case 'end':
                setTime(1);
                setIndex(steps);
                return setTimeMarker(1);
            case 'forward':
                if (time === 1) {
                    setTime(0);
                    setIndex(0);
                    return setTimeMarker(0);
                } else {
                    const newTime = lastStep + stepPercent;
                    setTime(newTime);
                    setIndex((lastStep + stepPercent) * steps);
                    return setTimeMarker(lastStep + stepPercent);
                }
            case 'back':
                return handleBack();
            default:
                setTime(0);
                setIndex(0);
                return setTimeMarker(0);
        }

        function handleBack() {
            if (time === 0) {
                setTime(1);
                setIndex(steps);
                return setTimeMarker(1);
            } else {
                let nextTime = time * steps % 1
                    ? lastStep
                    : lastStep - stepPercent;
                setTime(nextTime);
                setIndex(nextTime * steps);
                return setTimeMarker(nextTime);
            }
        }

    };

    function setPlayingValues(val) {
        setPlaying(val);
        setTimeMarker(time);
    }

    function getNearestValues() {
        setPlaying(false);

        const stepPercent = 1 / steps;
        let goStep = Math.floor(time * steps) / steps;
        if (time * steps % 1) {
           goStep = goStep + stepPercent;
        }

        const rounded = Math.floor(goStep * 100) / 100;
        setIndex(rounded * steps);
        setTimeMarker(rounded);
    }

    function setIndex(val) {
        setMarkerIndex(Math.round(val));
    }

    return {
        timeMarker: timeMarker,
        setTimeMarker: setMarker,
        markerIndex: markerIndex,
        playing: playing,
        setPlaying: setPlayingValues,
        time: time,
        setTime: setTime,
        getNearestMarker: getNearestValues
    };
};

export default useTimelineControls;
