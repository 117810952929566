import React, {useState} from 'react';
import DataTable from "../../components/DataTable/DataTable";
import sort from "../../components/DataTable/sorting";

const LineDataTable = (props) => {
    const [sorting, setSorting] = useState({sortBy: 'date', sortOrder: 'desc'});

    const {series, dateRange, period} = props;

    const dateFilteredSeries = series.map(s => ({...s, values: s.values.filter(v => v.date >= dateRange[0] && v.date <= dateRange[1])}));

    const handleSetSorting = (sortBy, sortOrder) => {
        setSorting({sortBy, sortOrder});
    };

    const columns = [
        {key: 'date', format: 'quarter', label: 'Period'},
        {key: 'name', format: 'string', label: 'Company'},
        {key: 'segmentName', format: 'string', label: 'Segment'},
        {key: 'metricName', format: 'string', label: 'Metric'},
        {key: 'value', format: 'detect', label: 'Value'} // This grabs the value from the row, which must be included
    ];

    return (
        <div style={{marginTop: 24}}>
            <DataTable
                period={period}
                sorting={sorting}
                setSort={handleSetSorting}
                sortableKeys={['date', 'name']}
                data={sort(dateFilteredSeries, sorting)}
                columns={columns}
            />
        </div>
    )
};

export default LineDataTable;
