import React from 'react';
import format from 'date-fns/format';
import styled from '@emotion/styled';
import TextFormat from "../../components/TextFormat";
// import TextFormat from "../../components/TextFormat/TextFormat";

const Wrapper = styled.div`
    line-height: 1.6em;
    font-weight: 400;
`;

const TitleContainer = styled.div`
    font-size: 14px;
    font-weight: 700;
`;

const DateContainer = styled.div`
    // font-weight: 400;
`;

const DetailContainer = styled.div`
    margin-top: 14px;
`;
const Flex = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    span {
        font-size: 10px;
    }
`;

const Right = styled.div`
    text-align: right;
`;

const sliceFormat = (val, type) => {
    return TextFormat(val, type === 'amount' || type === 'absGrowth' ? 'dollars' : 'percent-whole');
};

const QuadrantChartTipDetail = (props) => {
    const {data, tip, valueIndex, idKey, axes, titleKey} = props;
    const thisCo = data.find(d => d[idKey] === tip.id);
    const slice = thisCo.values[valueIndex];

    return (
        <Wrapper>
            <TitleContainer>{ thisCo[titleKey] }</TitleContainer>
            {/*<SubtitleContainer>{ thisCo[subtitleKey] }</SubtitleContainer>*/}
            <DateContainer>{ format(new Date(slice.date), 'yyyy-QQQ') }</DateContainer>
            <DetailContainer>
                <Flex>
                    <div>{axes.xAxis.segmentName}<br /> {axes.xAxis.name} <span>({axes.xAxis.type.toUpperCase()})</span></div>
                    <Right><b>{sliceFormat(slice.xAxis, axes.xAxis.type)}</b></Right>
                </Flex>
                <Flex>
                    <div>{axes.yAxis.segmentName}<br /> {axes.yAxis.name} <span>({axes.yAxis.type.toUpperCase()})</span></div>
                    <Right><b>{sliceFormat(slice.yAxis, axes.yAxis.type)}</b></Right>
                </Flex>
                <Flex>
                    <div>{axes.size.segmentName}<br /> {axes.size.name} <span>({axes.size.type.toUpperCase()})</span></div>
                    <Right><b>{sliceFormat(slice.size, axes.size.type)}</b></Right>
                </Flex>
            </DetailContainer>
        </Wrapper>
    )
};

export default QuadrantChartTipDetail;
