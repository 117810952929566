import mixpanel from 'mixpanel-browser';

const trackMixpanel = store => next => action => {

    const env = process.env.NODE_ENV;
    if (env !== 'production') {
        return next(action);
    }

    if (action.meta && action.meta.track && action.meta.track.ignore) {
        return next(action);
    } else {
        const {auth} = store.getState();
        if (auth.checked && auth.isAuth) {
            mixpanel.identify(auth.email);
            mixpanel.people.set({
                '$name': auth.name,
                '$email': auth.email
            });
            const options = action.meta && action.meta.track && action.meta.track.params
                ? action.meta.track.params
                : {}
            ;
            mixpanel.track(action.type, options);
        }
        return next(action);
    }
};

export default trackMixpanel;
