import React from 'react';
import PropTypes from 'prop-types';
import format from 'date-fns/format';
import TextFormat from "../TextFormat";
import styled from '@emotion/styled';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {label} from "../../utilities/formatter";

const Table = styled.table`
    width: 100%;
    border: 0;
    margin: 0;
    border-spacing: 0;
    td {
        text-align: center;
        padding: 10px 6px;
    }
    th {
        font-size: 12px;
        padding: 10px 6px;
        color: ${props => props.gray600};
    }
`;

const Tr = styled.tr`
    background: ${({odd, theme}) => odd ? theme.gray100 : 'white'};
`;

const Circle = styled.div`
    float: left;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background: ${props => props.background};
`;

const Th = styled.th`
    cursor: ${({sortable}) => sortable ? 'pointer' : 'default'};
    vertical-align: bottom;
    &:hover {
        background: ${({sortable, theme}) => sortable ? theme.gray100 : 'white'};
    }
    path {
        margin-right: 12px;
        fill: ${props => props.theme.primary};
    }
    span {
        margin-left: 6px;
    }
`;

const DataTable = (props) => {
    const {data, columns, sortableKeys, setSort, sorting, period} = props;

    const isSortable = c => sortableKeys.find(k => k === c.key);

    const headerClick = c => isSortable(c)
        ? setSort(c.key, sorting.sortOrder === 'asc' ? 'desc' : 'asc')
        : null;

    const dataCell = (row, column) => {
        return column.format === 'detect'
            ? TextFormat(row[column.key], label(row).format)
            : column.format === 'quarter'
            ? format(new Date(row[column.key]), period === 'annual' ? 'yyyy' : 'yyyy-QQQ')
            : TextFormat(row[column.key], column.format);
    };

    const icons = {
        date: {asc: 'up', desc: 'down'},
        name: {asc: 'down', desc: 'up'}
    };

    return (
        <Table>
            <thead>
            <tr>
                <th/>
                {columns.map(c => (
                    <Th sortable={isSortable(c)}
                        onClick={() => headerClick(c)}
                        key={`${c.key}${c.label}${c.labelMetric}`}>
                        {c.label}
                        {sorting.sortBy === c.key
                            ? <span><FontAwesomeIcon
                                icon={['fas', `sort-amount-${icons[sorting.sortBy][sorting.sortOrder]}`]}/></span>
                            : null}
                        <br/>{c.labelMetric}
                    </Th>
                ))}
            </tr>
            </thead>
            <tbody>
            {data.map((row, i) => (
                <Tr
                    odd={i % 2 === 0}
                    data-cy="data-table-row"
                    key={i}>
                    <td><Circle background={row.color}/></td>
                    {columns.map((column) => <td
                        key={column.key}
                        data-cy="data-table-column">
                        {dataCell(row, column)}
                    </td>)}
                </Tr>
            ))}
            </tbody>
        </Table>
    )
};

DataTable.defaultProps = {
    sortableKeys: ['date'],
    sorting: {sortBy: 'date', sortOrder: 'desc'}
};

DataTable.propTypes = {
    data: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,
    sorting: PropTypes.object
};

export default DataTable;
