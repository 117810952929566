import React, {useRef, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const Container = styled.div`
    background: ${props => props.theme.gray900};
    padding: 12px 20px;
    position: absolute;
    width: 290px;
    left: ${props => props.x - 115}px;
    // top: ${props => props.playing ? -1000 : props.y - 126}px;
    bottom: ${props => 530 - props.y}px;
    color: white;
    font-size: 12px;
    border-radius: 4px;
    box-shadow: 2px 2px 2px rgba(0,0,0,0.4);
    line-height: 1em;
    z-index:4;
    box-sizing: border-box;
    opacity: ${({doneDelay}) => doneDelay ? 1 : 0};
    transition: .3s linear opacity;
    &:after {
        border-left: solid transparent 10px;
        border-right: solid transparent 10px;
        border-top: solid ${props => props.theme.gray900} 10px;
        bottom: -10px;
        content: " ";
        height: 0;
        left: 50%;
        margin-left: -10px;
        position: absolute;
        width: 0;
    }
`;

const PositionChartTip = (props) => {
    const containerRef = useRef(null);
    const [height, setHeight] = useState(-2000); // Offset to avoid flicker
    const [doneDelay, setDoneDelay] = useState(false);

    useEffect(() => {
        if (containerRef.current) {
            setHeight(containerRef.current.getBoundingClientRect().height);
        }
    }, [setHeight]);

    useEffect(() => {
        setTimeout(() => {
            setDoneDelay(!!props.x);
        }, 100)
    }, [props.x]);

    return props.x ?
        <Container
            ref={containerRef}
            x={parseInt(props.x) + props.offset}
            y={props.y + props.yOffset}
            doneDelay={doneDelay}
            height={height}>
            {props.children}
        </Container>
        : null
};

PositionChartTip.defaultProps = {
    height: 500,
    offset: 0,
    yOffset: 0
};

PositionChartTip.propTypes = {
    x: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    y: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    height: PropTypes.number
};

export default PositionChartTip;
