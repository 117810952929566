import React, {useState} from 'react';
import PropTypes from 'prop-types';
import DateRangeSlider from "./DateRangeSlider/DateRangeSlider";
import DateRangeSliderContainer from "./DateRangeSlider/DateRangeSliderContainer";
import DateFilterContainer from "./DateRangeSlider/DateFilterContainer";
import DateFilterOkButton from "./DateRangeSlider/DateFilterOkButton";
import DateRangeButtonsGroup from "./DateRangeButtonsGroup/DateRangeButtonsGroup";
import DateFilterDisplay from "./DateFilterDisplay";
import styled from '@emotion/styled';

const Container = styled.div`
    display: flex;
    margin-bottom: 24px;
    margin-left: 28px;
    user-select: none;
`;

const DateFilters = props => {
    const {dateRange, setDates, setPreset, period} = props;
    const [open, setOpen] = useState(false);

    return (
        <Container>
            {open ?
                <DateFilterContainer>
                    <DateRangeSliderContainer>
                        <DateRangeSlider
                            labelWidth={62}
                            onChange={(settings) => setDates(settings)}
                            {...dateRange}
                        />
                    </DateRangeSliderContainer>
                    <DateFilterOkButton set={setOpen}/>
                </DateFilterContainer>
                : <DateFilterDisplay
                    set={setOpen}
                    {...dateRange}
                />
            }
            { open ? null : <DateRangeButtonsGroup period={period} setDates={setPreset} active={props.dateRange.displayType}/> }

        </Container>
    )
};

DateFilters.propTypes = {
    setDates: PropTypes.func.isRequired,
    active: PropTypes.string,
    dateRange: PropTypes.exact({
        minDisplay: PropTypes.instanceOf(Date),
        maxDisplay: PropTypes.instanceOf(Date),
        minDate: PropTypes.instanceOf(Date),
        maxDate: PropTypes.instanceOf(Date),
        displayType: PropTypes.string
    })
};

export default DateFilters;
