import React from 'react';
import styled from "@emotion/styled";

const Container = styled.div`
    font-weight: 700;
    font-style: italic;
    padding-left: 12px;
    margin-bottom: 18px;
`;

const ColumnTitle = (props) => {
    return (
        <Container>{props.children}</Container>
    )
};

export default ColumnTitle;
