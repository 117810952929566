import React from 'react';
import styled from '@emotion/styled';
import BarLoader from "../../../components/BarLoader/BarLoader";
import PropTypes from 'prop-types';

const Container = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    opacity: .5;
`;

const ColumnLoader = (props) => {
    return <Container>
        <BarLoader show={props.loading} height={4}/>
    </Container>
};

ColumnLoader.propTypes = {
    loading: PropTypes.bool
};

export default ColumnLoader;
