import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import * as actions from '../store/actions/index';

const Template = (props) => {
    const dispatch = useDispatch();
    const templates = useSelector(state => state.templates);

    useEffect(() => {
        dispatch(actions.loadTemplates());
    }, [dispatch]);
    // Now we do some stuff...

    if (!templates) return <div data-cy="Loader">waiting</div>;

    return (
        <div>
            {templates.loading ? <div>Loading</div> : null}
            <h1>Your templates</h1>
            {templates.list.map(item => <div>yo</div>)}
        </div>
    )
};

export default Template;
