export default (data, sortObj = {sortBy: 'date', sortOrder: 'desc'}) => {
    const flattened = data.reduce((acc, curr) => {
        return [...acc, ...curr.values.map(v => ({...v,
            id: curr.pairCode || curr.companyName + '_' + curr.segmentName,
            name: curr.companyName,
            segment: curr.segmentName,
            color: curr.color
        }))]
    }, []);

    const orderFn = sortObj.sortOrder === 'asc'
        ? sortOrderAscending
        : sortOrderDescending;

    return flattened.sort(orderFn(sortObj.sortBy));
}

const sortOrderDescending = key => (a,b) => {
    if (a[key] < b[key]) return 1;
    if (a[key] > b[key]) return -1;
    return 0;
};

const sortOrderAscending = key => (a,b) => {
    if (a[key] > b[key]) return 1;
    if (a[key] < b[key]) return -1;
    return 0;
};
