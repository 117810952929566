import React from 'react';

const LineIcon = (props) => {
    return (
        <svg fill="none" height={25} width={26} viewBox="0 0 25.63 24.31">
            <polyline points="0.63 0.63 0.63 21.35 25 21.35"/>
            <line x1="22.55" y1="23.68" x2="25" y2="23.68"/>
            <line y1="23.68" x2="20.02" y2="23.68"/>
            <line x1="7.28" y1="23.68" x2="13.3" y2="23.68"/>
            <line x1="5.15" y1="23.68" x2="5.92" y2="23.68"/>
            <line x1="0.63" y1="23.68" x2="2.88" y2="23.68"/>
            <polyline points="0.63 13.92 5.15 13.92 7.28 16.05 14.87 8.47 17.44 11.04 23.39 5.1"/>
        </svg>
    )
};

export default LineIcon;

