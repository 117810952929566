import React, {useState} from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import EmptyContainer from "../EmptyContainer/EmptyContainer";
import SelectedMetricItem from "./SelectedMetricItem";

const Container = styled.div`
    color: white;
    padding: ${props => props.theme.pad1};
    border-radius: 2px;
    ${({pendingRemove, theme}) => pendingRemove && `
        background: ${theme.errorDark};
        color: white;
    `
    }
`;

const TitleContainer = styled.div`
    display: flex;
`;

const RemoveContainer = styled.div`
    height: 14px;
    width: 14px;
    border-radius: 2px;
    border: 1px solid white;
    font-size: 10px;
    margin-right: 10px;
    cursor: pointer;
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    path {
        fill: white;
        stroke: ${props => props.theme.primaryDark};
        stroke-width: 2.4pc;
    }
    &:hover {
        path {
            fill: white;
            stroke: ${props => props.theme.errorDark};
        }
    }
`;

const BodyContainer = styled.div`
    margin-top: 6px;
    padding: 4px 10px;
`;

const SegmentCompanyContainer = (props) => {
    const [pendingRemove, setPendingRemove] = useState(false);

    return (
        <Container pendingRemove={pendingRemove} data-cy="segment-company-container">
            <TitleContainer data-cy="segment-company-child-title">
                <RemoveContainer
                    onClick={props.onRemove}
                    onMouseEnter={() => setPendingRemove(true)}
                    onMouseLeave={() => setPendingRemove(false)}
                >
                    <FontAwesomeIcon icon={['fas', 'times']} />
                </RemoveContainer>
                <div data-cy="selected-company-title" style={{fontWeight: 700, fontSize: 14}}>{props.company.name}</div>
            </TitleContainer>
                {props.company.metrics && props.company.metrics.length
                    ? props.company.metrics.map(met => <SelectedMetricItem key={met.code} onRemove={props.onRemoveMetric} {...met}/>)
                : props.comparison ? null :
            <BodyContainer>
                <EmptyContainer>
                    No metrics selected
                </EmptyContainer>
            </BodyContainer>
                }
            {props.company.requestedMetrics && props.company.requestedMetrics
                .map(m => <SelectedMetricItem requested key={m.code} {...m} />)}
        </Container>
    )
};

SegmentCompanyContainer.propTypes = {
    comparison: PropTypes.bool,
    company: PropTypes.object.isRequired,
    onRemove: PropTypes.func.isRequired,
    onRemoveMetric: PropTypes.func.isRequired
};

export default SegmentCompanyContainer;
