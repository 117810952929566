import React from 'react';
import BarLoader from "../components/BarLoader/BarLoader";
import styled from '@emotion/styled';

const Wrapper = styled.div`
    margin-top: -8px;
`;

const Container = styled.div`
    margin-bottom: 12px;
    color: ${props => props.theme.primary};
    font-weight: 700;
`;

const ProcessingDataLoader = (props) => {
    return (
        <Wrapper>
            <Container>Processing your data...</Container>
            <BarLoader show />
        </Wrapper>
    )
};

export default ProcessingDataLoader;
