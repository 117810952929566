import React from 'react';

const DateFilterContainer = (props) => {
    return (
        <div style={{display: 'flex', position: 'relative', top: -19, width: 360, marginRight: 36}}>
            {props.children}
        </div>
    )
};

export default DateFilterContainer;
